import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { Box3, Vector3, OrthographicCamera } from 'three';
import type CameraControlsImpl from 'camera-controls';
import { Vec6 } from 'shared/map-container/MapContainer.model';

const DEFAULT_FIT_PADDING = 10;
const MAX_ZOOM_IN = 50;

const calculationBox = new Box3();

export function useTopZoomClamp(
  domainSize: Vec6,
  options: {
    padding: number;
    selectedFlightDomain?: string;
  } = {
    selectedFlightDomain: '',
    padding: DEFAULT_FIT_PADDING,
  },
): void {
  const controls = useThree((state) => state.controls);
  const camera = useThree((state) => state.camera);
  useEffect(() => {
    if (!controls || !(camera instanceof OrthographicCamera)) {
      return;
    }

    const cameraControls = controls as unknown as CameraControlsImpl;

    const box = calculationBox.setFromArray(domainSize);
    const boundary = new Box3().copy(calculationBox);
    box.expandByScalar(options.padding);
    const size = box.getSize(new Vector3());
    const width = camera.right - camera.left;

    const objectMaxDimension = Math.max(size.x, size.y);
    const minZoom = Math.floor(width / objectMaxDimension);

    cameraControls.setBoundary(boundary);

    cameraControls.minZoom = minZoom;
    cameraControls.maxZoom = MAX_ZOOM_IN;
  }, [camera, controls, domainSize, options.padding, options.selectedFlightDomain]);
}
