import React, { useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import isEmpty from 'lodash/isEmpty';
import { singleRequestHandler } from 'common/requestHelpers';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { Box } from 'components/common/Box';
import { IImageUploadPostResponseST, ITaskExecutorPostResponseST } from 'codegen/dev_tools';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { developerServices } from '../../services/DeveloperServices';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { GroundControlActionNames } from '../../store/GroundControl/groundControlLevelStore.model';
import { DeveloperDrawerButton } from './DeveloperDrawerButton';
import {
  initialFleetOverview,
  initialFlightDomain,
} from '../../store/GroundControl/groundControlInitialState';
import { WebSocketNotifications } from './WebSocketNotifications';
import { ScriptedFrontendSimulator } from './ScriptedFrontendSimulator';
import { OperationsSimulator } from './OperationsSimulator';
import { useClientModalsStore } from '../../store/Modals';
import { ClientModalsActionTypes } from '../../store/Modals/types';
import { WmsDataSimulator } from './features/wms-data-simulator/WmsDataSimulator';
import { useStyles } from './DeveloperDrawer.styles';
import { IssueRateInput } from './IssueRateInput';
import { FleetSimulationForm } from './FleetSimulationFrom';

const logPrefix = getLogPrefixForType('COMPONENT', 'DeveloperDrawer');

interface IDeveloperDrawerProps {
  visible: boolean;
  close: () => void;
}
export const DeveloperDrawer = (props: IDeveloperDrawerProps) => {
  const stepRef = useRef();
  const { facilityData } = useFacilityLevelStore().stateFacilityLevel;

  const [output, setOutput] = useState([':: Well, hello you...']);
  const [issueRate, setIssueRate] = useState(0.2);
  const [nrLocationsToSimulate, setNrLocationsToSimulate] = useState('');
  const [value, setValue] = useState('0');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { dispatchGroundControlLevel } = useGroundControlStore();

  const navigate = useNavigate();

  const { classes } = useStyles();

  const systemId = useLocation().pathname.split('/')[1] ?? '';

  const closeDrawer = useCallback(() => {
    props.close();
  }, [props]);

  const clearOutput = () => {
    setOutput(() => []);
  };

  const addToOutput = (messages: string[]) => {
    setOutput((prevState) => [...prevState, ...messages]);
  };

  const genericRequestSentCallback = (requestName: string) => {
    const messages = [`:: Request sent to ${requestName}`];
    addToOutput(messages);
  };

  const genericErrorCallback = (errorMsg: string) => {
    const messages = [errorMsg];
    addToOutput(messages);
  };

  const isFacilityDataAvailable = useCallback(() => {
    console.debug(
      logPrefix,
      `check state.facilityData, empty: ${isEmpty(stateFacilityLevel.facilityData)}`,
    );
    return !isEmpty(stateFacilityLevel.facilityData);
  }, [stateFacilityLevel.facilityData]);

  const postTaskExecutorSuccessCallback = (reply: { data: ITaskExecutorPostResponseST }) => {
    const messages = [
      ':: :: Note: you have to wait at most 1 min. for the task executor to be able to poll pending requests.',
      `:: :: ${JSON.stringify(reply.data, null, ' ')}`,
    ];
    addToOutput(messages);
  };

  const handleRunTaskExecutorClick = () => {
    console.debug(logPrefix, 'handleRunTaskExecutorClick');
    singleRequestHandler({
      request: developerServices.postTaskExecutor,
      requestParams: [systemId, issueRate, nrLocationsToSimulate],
      callbackBeforeSend: () => genericRequestSentCallback('Task executor'),
      callbackSuccess: postTaskExecutorSuccessCallback,
      messageSuccess: 'Task executor invoked successfully',
      callbackError: () => {
        genericErrorCallback(':: :: Error invoking task executor');
      },
    });
  };

  const postCSUploadImagesSuccessCallback = (reply: { data: IImageUploadPostResponseST }) => {
    const messages = [`:: :: ${JSON.stringify(reply.data, null, ' ')}`];
    addToOutput(messages);
  };

  const handleCSUploadImagesClick = () => {
    console.debug(logPrefix, 'handleCSUploadImagesClick');
    singleRequestHandler({
      request: developerServices.postCSUploadImages,
      requestParams: [systemId],
      callbackBeforeSend: () => genericRequestSentCallback('CS upload images'),
      callbackSuccess: postCSUploadImagesSuccessCallback,
      messageSuccess: 'Simulated image uploader invoked successfully',
      callbackError: () => {
        genericErrorCallback(':: :: Error invoking simulated image uploader');
      },
    });
  };

  /**
   * Get duration of the tokens
   * @returns accessTokenDuration and refreshTokenDuration in seconds
   */
  const getTokensDuration = () => {
    const authData = LocalStore.getAuthData();
    const accessTokenDurationInMinutes = authData?.user_pool_description.access_token_validity ?? 0;
    const accessTokenDurationInSeconds = accessTokenDurationInMinutes * 60;
    const refreshTokenDurationInMinutes =
      authData?.user_pool_description.refresh_token_validity ?? 0;
    const refreshTokenDurationInSeconds = refreshTokenDurationInMinutes * 60;

    // For sake of safety we reduce original tokens duration by 60 seconds
    const accessTokenDuration = accessTokenDurationInSeconds - 60;

    return { accessTokenDuration, refreshTokenDuration: refreshTokenDurationInSeconds };
  };

  const expireAccessAndIdTokens = (secondsUntilTokenIsInvalid = 0) => {
    console.debug(logPrefix, `expireAccessAndIdTokens, seconds: ${secondsUntilTokenIsInvalid}`);

    const { accessTokenDuration } = getTokensDuration();
    const currentDatetime = moment.utc().format(DATETIME_FORMAT);

    // Set token expiration date reduced by {secondsUntilTokenIsInvalid}
    // amount of seconds until expiration
    const updatedAccessAndIdTokensReceivedDate = moment(currentDatetime)
      .subtract(accessTokenDuration - secondsUntilTokenIsInvalid, 's')
      .format(DATETIME_FORMAT);

    LocalStore.setAccessAndIdTokenReceivedTime(updatedAccessAndIdTokensReceivedDate);
  };

  const expireRefreshToken = (secondsUntilTokenIsInvalid = 0) => {
    console.debug(logPrefix, `expireRefreshToken, seconds: ${secondsUntilTokenIsInvalid}`);

    const { refreshTokenDuration } = getTokensDuration();
    const currentDatetime = moment.utc().format(DATETIME_FORMAT);

    // Set token expiration date reduced by {secondsUntilTokenIsInvalid}
    // amount of seconds until expiration
    const updatedRefreshTokenReceivedDate = moment(currentDatetime)
      .subtract(refreshTokenDuration - secondsUntilTokenIsInvalid, 's')
      .format(DATETIME_FORMAT);

    LocalStore.setRefreshTokenReceivedTime(updatedRefreshTokenReceivedDate);
  };

  const testExpireRefreshToken = () => {
    const lp = getLogPrefixForType('FUNCTION', 'testExpireRefreshToken', logPrefix);
    console.debug(
      lp,
      'Changed local storage refresh token expiration date, refresh the page. The sign in modal will appear within a five seconds.',
    );
    expireRefreshToken(5);
    navigate(0);
    closeDrawer();
  };

  const testExpireAccessAndIdToken = () => {
    const lp = getLogPrefixForType('FUNCTION', 'testExpireAccessAndIdToken', logPrefix);
    console.debug(
      lp,
      'Changed local storage access and id tokens expiration date. The refresh of the token will be intercepted in the next api call.',
    );
    expireAccessAndIdTokens(2);
    closeDrawer();
  };

  const testTokenExpirationWithDataLoaded = () => {
    console.debug(logPrefix, 'testTokenExpirationWithDataLoaded');
    closeDrawer();
    expireAccessAndIdTokens(5);
    navigate(0);
  };

  const testTokenExpirationWithoutDataLoaded = () => {
    console.debug(logPrefix, 'testTokenExpirationWithoutDataLoaded');
    expireAccessAndIdTokens(0);
    expireRefreshToken(0);
    closeDrawer();
    navigate(0);
  };

  const testOpenSignInModal = () => {
    closeDrawer();

    setTimeout(() => {
      dispatchClientModals({
        type: ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL,
        payload: { open: true },
      });
    }, 3000);
  };

  const initiateNoDataAvailable = () => {
    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
      payload: initialFlightDomain,
    });

    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLEET_OVERVIEW,
      payload: initialFleetOverview,
    });
  };

  return (
    <SwipeableDrawer
      data-testid="c-dev-tools"
      anchor="top"
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Escape') {
          props.close();
        }
      }}
      open={Boolean(props.visible)}
      onClose={() => closeDrawer()}
      onOpen={() => closeDrawer()}
    >
      <div className={classes.drawer}>
        <Typography variant="h6">
          Developer box for {facilityData?.name || `System ID: ${systemId}`}
        </Typography>
        <Grid alignItems="stretch" spacing={3} container>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <div className={classes.drawerContent}>
              <TabContext value={value}>
                <div className={classes.drawerMenu}>
                  <TabList
                    orientation="vertical"
                    onChange={handleChange}
                    className={classes.tabList}
                  >
                    <Tab
                      data-testid="c-tab-link-task-executor"
                      label="Task Executor"
                      value="0"
                      id="vertical-tab-0"
                    />
                    <Tab
                      data-testid="c-tab-link-wms-data"
                      label="WMS Data"
                      value="1"
                      id="vertical-tab-1"
                    />
                    <Tab
                      data-testid="c-tab-link-tokens"
                      label="Tokens Expiration"
                      value="2"
                      id="vertical-tab-2"
                    />
                    <Tab
                      data-testid="c-tab-link-ground-control"
                      label="Ground Control"
                      value="3"
                      id="vertical-tab-3"
                    />
                    <Tab
                      data-testid="c-tab-link-other"
                      label="Other"
                      value="4"
                      id="vertical-tab-4"
                    />
                    <Tab
                      data-testid="c-tab-link-web-socket-notifications"
                      label="Web socket notifications"
                      value="5"
                      id="vertical-tab-5"
                    />
                    <Tab
                      data-testid="c-tab-link-scripted-frontend-simulator"
                      label="Scripted frontend simulator"
                      value="6"
                      id="vertical-tab-6"
                    />
                    <Tab
                      data-testid="c-tab-link-operations-simulator"
                      label="Operations simulator"
                      value="7"
                      id="vertical-tab-7"
                    />
                  </TabList>
                </div>
                <div className={classes.tabPanelWrapper}>
                  <TabPanel value="0" className={classes.tabPanel}>
                    {isFacilityDataAvailable() && (
                      <>
                        <DeveloperDrawerButton
                          testId="c-dev-tools-task-executor"
                          buttonLabel="Run task executor"
                          clickHandler={handleRunTaskExecutorClick}
                          content={
                            <>
                              <TextField
                                data-testid="c-dev-tools-nr-locations-to-simulate"
                                type="number"
                                size="small"
                                name="nrLocationsToSimulate"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    step: 10,
                                  },
                                }}
                                sx={{ marginBottom: 2, width: '335px' }}
                                label="# LocationsToSimulate"
                                value={nrLocationsToSimulate}
                                onChange={(e) =>
                                  setNrLocationsToSimulate(
                                    e.target.value === '0' ? '' : e.target.value,
                                  )
                                }
                              />
                              <IssueRateInput
                                stepRef={stepRef}
                                issueRate={issueRate}
                                setIssueRate={setIssueRate}
                              />
                            </>
                          }
                        />
                        <DeveloperDrawerButton
                          testId="c-dev-tools-upload-images"
                          buttonLabel="Run CS upload images"
                          clickHandler={handleCSUploadImagesClick}
                        />
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value="1" className={classes.tabPanel}>
                    <WmsDataSimulator systemId={systemId} addToOutput={addToOutput} />
                  </TabPanel>
                  <TabPanel value="2" className={classes.tabPanel}>
                    <DeveloperDrawerButton
                      testId="c-dev-tools-token-expiration-access"
                      buttonLabel="Expire Access Token"
                      clickHandler={testExpireAccessAndIdToken}
                      content={
                        <TooltipedIcon
                          tooltip="Changed local storage access and id tokens receiving date.The refresh of the token will be triggered at the next api call."
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />
                    <DeveloperDrawerButton
                      testId="c-dev-tools-token-expiration-refresh"
                      buttonLabel="Expire Refresh Token"
                      clickHandler={testExpireRefreshToken}
                      content={
                        <TooltipedIcon
                          tooltip="Changes local storage refresh token receiving date. The sign in modal will appear in 3 seconds"
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />

                    <DeveloperDrawerButton
                      testId="c-dev-tools-token-expiration-auto-refresh"
                      buttonLabel="Test access token auto refresh while data is loaded"
                      clickHandler={testTokenExpirationWithDataLoaded}
                      content={
                        <TooltipedIcon
                          tooltip="Changes access tokens received date and after 5 seconds, refresh token request should be triggered."
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />
                    <DeveloperDrawerButton
                      testId="c-dev-tools-token-expiration-without-data"
                      buttonLabel="Test tokens expiration while data is NOT loaded"
                      clickHandler={testTokenExpirationWithoutDataLoaded}
                      content={
                        <TooltipedIcon
                          tooltip="Changes local storage tokens expiration dates and reloads the page (with no data loaded). Soon after the page is reloaded (~10s) the sign in modal will appear."
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />
                    <DeveloperDrawerButton
                      testId="c-dev-tools-open-sign-in-modal"
                      buttonLabel="Open the sign in modal"
                      clickHandler={testOpenSignInModal}
                      content={
                        <TooltipedIcon
                          tooltip="Makes the Sign in modal appear after a few seconds."
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />
                  </TabPanel>
                  <TabPanel value="3" className={classes.tabPanel}>
                    <FleetSimulationForm
                      systemId={systemId}
                      errors={errors}
                      setErrors={setErrors}
                      flightDomainsList={stateFacilityLevel.flightDomains}
                      addToOutput={addToOutput}
                    />
                  </TabPanel>
                  <TabPanel value="4" className={classes.tabPanel}>
                    <DeveloperDrawerButton
                      testId="c-dev-tools-initiate-no-data"
                      buttonLabel="Initiate No Data Available"
                      clickHandler={initiateNoDataAvailable}
                      content={
                        <TooltipedIcon
                          tooltip="Initiate state with no data"
                          icon={<InfoOutlinedIcon fontSize="small" />}
                        />
                      }
                    />
                  </TabPanel>
                  <TabPanel value="5" className={classes.tabPanel}>
                    <WebSocketNotifications />
                  </TabPanel>
                  <TabPanel value="6" className={classes.tabPanel}>
                    <ScriptedFrontendSimulator addToOutput={addToOutput} systemId={systemId} />
                  </TabPanel>
                  <TabPanel value="7" className={classes.tabPanel}>
                    <OperationsSimulator addToOutput={addToOutput} systemId={systemId} />
                  </TabPanel>
                </div>
              </TabContext>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} className={classes.consoleWrapper}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h5 className={classes.consoleWrapperTitle}> Output: </h5>
              <Button
                size="small"
                variant="outlined"
                className={classes.clearBtn}
                onClick={() => clearOutput()}
              >
                Clear
              </Button>
            </Box>
            <div className={classes.consoleOutput}>
              {output.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p className={classes.consoleOutputEntry} key={index}>
                  {' '}
                  {item}{' '}
                </p>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </SwipeableDrawer>
  );
};
