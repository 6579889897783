import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { NodeST } from 'shared/map-container/MapContainer.model';

const AISLE_ID_KEY = 'aisleId';
const VIEW_KEY = 'view';

const canShowAisle = ({
  searchParamsView,
  selectedAisleId,
  selectedAisle,
}: {
  searchParamsView: string | null;
  selectedAisleId: string | null;
  selectedAisle: NodeST | null;
}) => searchParamsView === 'aisle' && selectedAisleId && selectedAisle;

export function useSearchParamViewState({
  facilityMap,
  tabIndexNumber,
}: {
  facilityMap?: ReturnType<typeof useFacilityMap>['data'];
  tabIndexNumber: number;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAisleId = searchParams.get(AISLE_ID_KEY);

  const selectedAisle =
    useMemo(
      () =>
        facilityMap?.nodes?.[tabIndexNumber]
          ? findNodesByType(facilityMap?.nodes?.[tabIndexNumber], 'AISLE_SIDE').find(
              (aisle) => aisle.id === selectedAisleId,
            )
          : null,
      [facilityMap?.nodes, selectedAisleId, tabIndexNumber],
    ) ?? null;

  const view = canShowAisle({
    searchParamsView: searchParams.get(VIEW_KEY),
    selectedAisleId,
    selectedAisle,
  })
    ? 'aisle'
    : 'top';

  const set3DViewSearchParams = useCallback(
    (newParams: { view?: 'aisle' | 'top'; aisleId?: string }) => {
      setSearchParams((previousParams) => {
        const params = new URLSearchParams(previousParams);
        Object.entries(newParams).forEach(([key, value]) =>
          value ? params.set(key, value) : params.delete(key),
        );
        return params;
      });
    },
    [setSearchParams],
  );

  return { view, selectedAisle, set3DViewSearchParams };
}
