import { Outlet, useParams } from 'react-router-dom';
import { PageLayoutFixedHeight } from 'components/common/page-layout-fixed-height/PageLayoutFixedHeight';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { useWarehouseStatus3DStyle } from './styles/warehouseStatus3D.style';

export const WarehouseStatus3DLayout = () => {
  const { classes } = useWarehouseStatus3DStyle();
  const { systemId = '' } = useParams<{ systemId: string }>();

  return (
    <PageLayoutFixedHeight>
      <PageLayoutFixedHeight.Header>
        <PageHeader
          systemId={systemId}
          title="Warehouse map"
          subtitle="See surrounding issues and navigate to other locations"
        />
      </PageLayoutFixedHeight.Header>

      <PageLayoutFixedHeight.Body fullWidth>
        <div className={classes.pageWrapper}>
          <Outlet />
        </div>
      </PageLayoutFixedHeight.Body>
    </PageLayoutFixedHeight>
  );
};
