import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';
import { BarcodeRow } from '../models/BarcodeRow.model';

export const getCommaSeparatedListOfShouldBeAt = (
  row: BarcodeRow,
  isLoadingBarcodes: boolean,
  barcodeDataMap?: BarcodeDataMap,
) => {
  const expectedAtLocations = [
    ...(barcodeDataMap?.[row.contentFound]?.expected_at || []),
    ...(barcodeDataMap?.[row.expectedContent]?.expected_at || []),
  ];

  return isLoadingBarcodes
    ? 'Loading...'
    : expectedAtLocations.filter((location) => location !== row.locationData.location).join(', ') ||
        '';
};
