import { Fragment } from 'react';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import {
  BarcodeMatchLogic,
  getBarcodeMuiColor,
} from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeFrame } from './BarcodeFrame';
import { BarcodeLabel } from './BarcodeLabel';
import { transformBarcodeRows } from '../barcodes/utils/transformBarcodeRows.util';
import { getIsVerityDataStale } from '../../utils/getIsVerityDataStale';
import { useBarcodeMetadata } from './hooks/useBarcodeMetadata';

export const BarcodeVisualization = ({
  systemId,
  locationData,
  imageURL,
  highlightedBarcode,
  issueLogic,
  barcodeMatchLogic,
  setHighlightedBarcode,
}: {
  systemId: string;
  locationData: ILocationData;
  imageURL: string;
  highlightedBarcode: string;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  setHighlightedBarcode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const transformedBarcodes = transformBarcodeRows(locationData, issueLogic);
  const isStale = getIsVerityDataStale(locationData.rowData);

  const { barcodesMetadata } = useBarcodeMetadata({ locationData, imageURL, systemId });
  if (barcodesMetadata) {
    return (
      <>
        {barcodesMetadata.map((metadata) => {
          const filteredBarcodes = transformedBarcodes.filter(
            (b) => b.contentFound === metadata.udb_value,
          );
          const color = getBarcodeMuiColor(
            filteredBarcodes[0]?.barcodeStatus,
            barcodeMatchLogic,
            issueLogic,
          );
          return (
            <Fragment key={metadata.id}>
              <BarcodeFrame
                barcodeMetadata={metadata}
                color={color}
                isStale={isStale}
                highlightedBarcode={highlightedBarcode}
                setHighlightedBarcode={setHighlightedBarcode}
              />
              <BarcodeLabel barcodeMetadata={metadata} color={color} isStale={isStale} />
            </Fragment>
          );
        })}
      </>
    );
  }
  return null;
};
