import { useMemo } from 'react';
import { matchBarcode } from 'common/functions/barcodes/matchBarcode.util';
import { getItemsFromLocation } from 'common/functions/items/itemsFunctions';
import { useLocationData } from './useLocationData';
import { ItemData } from '../../../models/ItemView.model';

export const useItemData = ({
  systemId,
  skipIfLoadingLocationData = true,
}: {
  systemId: string;
  skipIfLoadingLocationData?: boolean;
}) => {
  const { locationData, isLoadingLocations } = useLocationData(systemId);

  const itemData = useMemo(() => {
    const items: {
      [item: string]: ItemData;
    } = {};

    if (skipIfLoadingLocationData && isLoadingLocations) {
      return items;
    }

    locationData.forEach((l) => {
      const newItems = getItemsFromLocation(l);
      // NOTE: here we could use an object spread instead of inserting a new element,
      // e.g.: items = {...items, ...newItems}
      // this might be easier to read (and/or visually more pleasing),
      // however it brings a major performance issue
      // (see e.g.: https://jonlinnell.co.uk/articles/spread-operator-performance)
      // eno 2025-01-28
      Object.keys(newItems).forEach((i: string) => {
        items[i] = newItems[i];
      });
    });

    Object.keys(items).forEach((key) => {
      const item = items[key];

      item.barcodeStatus = matchBarcode(
        item.expected_at ? item.barcode : '',
        item.found_at ? item.barcode : '',
      );
    });

    return items;
  }, [skipIfLoadingLocationData, isLoadingLocations, locationData]);

  return useMemo(
    () => ({ itemData, isLoading: isLoadingLocations }),
    [itemData, isLoadingLocations],
  );
};
