import { getUserAdminService } from 'services/services';
import { AxiosResponse } from 'axios';

export const deleteUser: ({
  systemId,
  userId,
}: {
  systemId: string;
  userId: string;
}) => Promise<AxiosResponse<void, unknown>> = ({ systemId, userId }) =>
  getUserAdminService().deleteUser(systemId, userId);
