import { toast, TypeOptions } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { IClientLevelStateFacility } from 'store/ClientLevelStore/ClientLevelStore.model';

/**
 * Interface for displayNotification function
 */
interface IDisplayNotification {
  type: TypeOptions;
  text: string;
  linkText: string;
  url: string;
  target: string;
}

/**
 * Display notification
 * @param param0 IDisplayNotification parameters
 */
export const displayNotification = ({
  type,
  text,
  linkText = '',
  url = '',
  target = 'self',
}: IDisplayNotification) => {
  const link = (
    <span
      className="notification-link"
      role="link"
      tabIndex={0}
      onClick={() => window.open(url, target)}
    >
      {linkText}
    </span>
  );

  const message = (
    <div>
      {text} {!isEmpty(url) ? link : ''}.
    </div>
  );

  toast(message, {
    type,
    toastId: 'webSocketError',
  });
};

/**
 *
 * @param wsPayload web socket payload
 * @param facilityList List of facilities
 * @returns facility
 */
export const getFacilityFromNotification = (
  wsPayload: {
    systemId: string;
  },
  facilityList: IClientLevelStateFacility[],
) => {
  if (isEmpty(wsPayload) || isEmpty(facilityList) || !wsPayload.systemId) return undefined;

  const sysId = wsPayload.systemId;
  const facility = facilityList.find((f) => f.id.toString() === sysId.toString());

  return facility;
};
