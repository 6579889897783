import { Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { FormInput } from 'components/FormFields/FormInput';
import { FormInputNumber } from 'components/FormFields/FormInputNumber/FormInputNumber';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import type { DroneZone } from 'shared/map/model/drone-zone.model';
import { toFixed2 } from 'utils/numberFormatting';
import type { BottomLeftDimensions, DroneZoneFormProps } from '../../droneZone.model';
import { useDroneZoneFormStyles } from '../../useDroneZoneFormStyles';
import { getLabel } from '../../utils/getLabel';
import { validateName } from '../../utils/validateName';
import { getHeight } from './utils/getHeight';
import { zoneTypeProps } from '../../defaults/droneZoneForm.defaults';
import { metricFormatter } from './utils/metricFormatter';
import { useValidateDroneZoneForm } from './hooks/useValidateDroneZoneForm';

export const MetricDroneZoneForm = <T extends DroneZone>({
  droneZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: DroneZoneFormProps<T>) => {
  const { classes } = useDroneZoneFormStyles();
  const [height, setHeight] = useState(
    getHeight(droneZone.sizeAndPosition.minZ, droneZone.sizeAndPosition.maxZ),
  );

  const originalZone = useRef(droneZone);

  const metricZone = {
    ...droneZone,
    sizeAndPosition: Object.entries(droneZone.sizeAndPosition).reduce((acc, [key, value]) => {
      acc[key as keyof BottomLeftDimensions] = toFixed2(value);
      return acc;
    }, {} as BottomLeftDimensions),
  };

  const validate = useValidateDroneZoneForm(droneZone.type, onUpdate);

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const inputProps = { step: 0.5, min: 1 };
  const maxHeight = facilityMaxZ - facilityMinZ;

  return (
    <Formik
      enableReinitialize
      initialValues={metricZone}
      onSubmit={onSubmit}
      validate={validate(worldBox)}
    >
      {({ values, handleChange, isValid, isSubmitting }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            {zoneTypeProps[droneZone.type].title}
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label={zoneTypeProps[droneZone.type].nameLabel}
              required
              fullWidth
              validate={(value) => validateName(value, droneZone.type)}
            />

            <FormInput name="description" label="Description 123" multiline fullWidth />

            {!zoneTypeProps[droneZone.type].isHeightEnabled ? (
              <div className={classes.formSection}>
                <FormInputNumber
                  inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                  name="sizeAndPosition.minZ"
                  label={getLabel({
                    baseLabel: 'Bottom (m)',
                    formatter: metricFormatter,
                    max: facilityMaxZ,
                  })}
                  fullWidth
                  onChange={(e) => {
                    setHeight(getHeight(Number(e.target.value), values.sizeAndPosition.maxZ));
                    handleChange(e);
                  }}
                />

                <FormInputNumber
                  inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                  name="sizeAndPosition.maxZ"
                  label={getLabel({
                    baseLabel: 'Top (m)',
                    formatter: metricFormatter,
                    max: facilityMaxZ,
                  })}
                  fullWidth
                  onChange={(e) => {
                    setHeight(getHeight(values.sizeAndPosition.minZ, Number(e.target.value)));
                    handleChange(e);
                  }}
                />
              </div>
            ) : null}

            <FormInputNumber
              inputProps={{ ...inputProps, max: maxHeight }}
              name="sizeAndPosition.h"
              label={getLabel({
                baseLabel: 'Height (m)',
                formatter: metricFormatter,
                max: zoneTypeProps[droneZone.type].isHeightEnabled ? maxHeight : undefined,
              })}
              fullWidth
              disabled={!zoneTypeProps[droneZone.type].isHeightEnabled}
              value={
                zoneTypeProps[droneZone.type].isHeightEnabled ? values.sizeAndPosition.h : height
              }
            />

            <FormInputNumber
              name="sizeAndPosition.w"
              label="Width (m)"
              inputProps={{
                ...inputProps,
                max: facilityMaxX - values.sizeAndPosition.minX,
              }}
              fullWidth
            />

            <FormInputNumber
              name="sizeAndPosition.l"
              label="Length (m)"
              inputProps={{
                ...inputProps,
                min: 0,
                max: facilityMaxY - values.sizeAndPosition.minY,
              }}
              fullWidth
            />

            <div className={classes.formSection}>
              <FormInputNumber
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: facilityMaxX - values.sizeAndPosition.w,
                }}
                label="X-Position (m)"
                fullWidth
              />

              <FormInputNumber
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: facilityMaxY - values.sizeAndPosition.l,
                }}
                fullWidth
                label="Y-Position (m)"
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalZone.current)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{ flex: 1 }}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
