import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { INVENTORY_PAGES_URLS } from 'common/pages';

const useStyles = makeStyles()(() => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const FULL_REPORT_TAB_QUERY_PARAM = 'activeTab=0';

export const LocationLinkCell = ({ locationList }: { locationList: string }) => {
  const { classes } = useStyles();
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const locations = locationList.split(', ');

  return (
    <div className={classes.cell}>
      {locations.map((locationName, i) => (
        <>
          <Link
            href={`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}?${FULL_REPORT_TAB_QUERY_PARAM}&location=${locationName}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            key={locationName}
          >
            {locationName}
          </Link>
          {i !== locations.length - 1 && <span>,&nbsp;</span>}
        </>
      ))}
    </div>
  );
};
