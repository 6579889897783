import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getReportService } from 'services/services';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import pLimit from 'p-limit';
import { useCallback, useMemo } from 'react';
import { IReportDataST, IReportST } from 'codegen/report';
import { getBatchStartSlotName } from 'udb/inventory/features/premium-grid/utils/getBatchStartSlotName';
import { getReportSummaryData } from 'udb/inventory/features/reports/reducer/report-store/report-summary/getReportSummaryData';
import { getReportExecutionData } from 'udb/inventory/features/reports/reducer/report-store/report-execution/getReportExecutionData';
import { QueryNames } from 'ts-types/QueryNames';
import { transformReportData } from '../utils/transformReportData';
import { getRequestParams } from '../utils/getRequestParams';
import { getIssuesSummary } from '../../../../../reducer/report-store/utils/getIssueSummary';
import { IssueSummary } from '../../report-overview/issue-summary-card/IssueSummaryCard';

const requestPageSize = NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB;

const MAX_CONCURRENT_REQUESTS = 10;
const limit = pLimit(MAX_CONCURRENT_REQUESTS);

export const useReportData = ({ systemId, reportId }: { systemId: string; reportId: string }) => {
  const queryClient = useQueryClient();

  const { data: reportSummary, isLoading: isLoadingSummary } = useQuery({
    queryKey: [QueryNames.REPORT_SUMMARY, reportId],
    queryFn: () => getReportService().getReportSummary(systemId, reportId),
    select: (data) => data.data,
  });

  const reportSummaryData = useMemo(
    () => getReportSummaryData(reportSummary?.item as IReportST),
    [reportSummary?.item],
  );

  const requestParams = useMemo(
    () => getRequestParams(reportSummary?.item?.request),
    [reportSummary],
  );

  const reportExecutionData = useMemo(
    () => getReportExecutionData(requestParams, reportSummary?.item as IReportST),
    [requestParams, reportSummary?.item],
  );

  const reportNeedsReview = useMemo(
    () => !!reportSummary?.item?.num_locations_to_review,
    [reportSummary?.item],
  );

  const issuesSummary: IssueSummary | undefined = useMemo(
    () =>
      getIssuesSummary(
        reportSummary?.item?.issue_count.totals,
        reportSummary?.item?.request.report_spec.report_spec_name,
      ),
    [reportSummary?.item],
  );

  const locationNames = useMemo(
    () => reportSummary?.item?.locations ?? [],
    [reportSummary?.item?.locations],
  );
  const requestChunk: { fromLocation: string; toLocation: string }[] = [];

  const getToLocation = (i: number) =>
    i + requestPageSize - 1 > locationNames.length
      ? locationNames[locationNames.length - 1]
      : locationNames[i + requestPageSize - 1];

  for (let i = 0; i < locationNames.length; i += requestPageSize) {
    requestChunk.push({
      fromLocation: locationNames[i],
      toLocation: getToLocation(i),
    });
  }

  const allLocationsQuery = useQueries({
    queries: requestChunk.map((chunk) => ({
      queryKey: [QueryNames.REPORT_LOCATIONS_DATA, reportId, chunk.fromLocation],
      queryFn: () =>
        limit(() =>
          getReportService().getReportData(
            systemId,
            reportId,
            chunk.fromLocation,
            chunk.toLocation,
          ),
        ),

      staleTime: Infinity,
    })),
  });

  const isLoadingLocations = allLocationsQuery.some((result) => result.isFetching);
  // Note: here we subdived the requests in segments of n requests
  // we will use this to update the locationData only when a segement finishes.
  // The reason for this is that updating locationData at each request causes performance
  // issues (especially in weak hardware), however updating it only when all requests are don
  // gives the impression that the dashboard is stuck. (https://verity-ag.atlassian.net/browse/PI-1525)
  // copied from useLocationData
  // sfarkas: 2024-12-18
  const requestsSegment = Math.floor(
    allLocationsQuery.filter((query) => query.isSuccess).length / 8,
  );

  const locationData = useMemo(
    () =>
      allLocationsQuery
        .map((result) =>
          Object.values(result.data?.data ?? {})
            .filter(Boolean)
            .flatMap((data: IReportDataST) => transformReportData(data)),
        )
        .flat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingLocations, requestsSegment],
  );

  const invalidateReportLocationData = useCallback(
    (slotName: string) => {
      const startSlotName = getBatchStartSlotName(slotName, locationNames, requestPageSize);
      queryClient.invalidateQueries({
        queryKey: [QueryNames.REPORT_LOCATIONS_DATA, reportId, startSlotName],
      });
    },
    [queryClient, locationNames, reportId],
  );

  return {
    locationData,
    isLoadingReportLocations: isLoadingLocations || isLoadingSummary,
    invalidateReportLocationData,
    reportSummaryData,
    reportExecutionData,
    issuesSummary,
    isLoadingSummary,
    reportNeedsReview,
  };
};
