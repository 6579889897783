import CameraControlsImpl from 'camera-controls';
import { Box3, Vector3 } from 'three';
import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { getLogPrefixForType } from 'common/functions/logFunctions';

const CAMERA_OFFSET_MULTIPLIER = 10;

function getCameraOffset(normal: Vector3 = new Vector3()) {
  return vectorLike2Vector(normal).clone().multiplyScalar(CAMERA_OFFSET_MULTIPLIER);
}

const logPrefix = getLogPrefixForType('HOOK', 'useZoomFit');

const FIT_PADDING = {
  paddingTop: 1,
  paddingRight: 1,
  paddingBottom: 1,
  paddingLeft: 1,
};

export interface Fit {
  type: 'width' | 'height';
}
export function useZoomFit({
  aisleSize,
  normal,
  targetPosition,
  fit,
}: {
  aisleSize: Vec6;

  normal?: Vector3;
  targetPosition?: Vector3;
  /**
   * `fit` is made to be and object instead of a primitive
   * value on purpose here. This is for fit to work even after zoom
   */
  fit?: Fit;
}) {
  const controls = useThree((state) => state.controls);
  useEffect(() => {
    if (controls instanceof CameraControlsImpl) {
      console.debug(logPrefix, 'fitting to aisle', aisleSize);
      const aisleBox = new Box3().setFromArray(aisleSize);
      let center = aisleBox.getCenter(new Vector3());
      const dimensions = aisleBox.getSize(new Vector3());

      if (targetPosition) {
        center = new Vector3(targetPosition.x, targetPosition.y, center.z);
      }

      const verticalBox = new Box3();
      verticalBox.setFromCenterAndSize(center, new Vector3(1, 1, dimensions.z));

      const cameraOffset = getCameraOffset(normal);

      controls.setLookAt(
        // position
        center.x + cameraOffset.x,
        center.y + cameraOffset.y,
        center.z,
        // target
        center.x,
        center.y,
        center.z,
        // animate
        false,
      );

      const fitBox = fit?.type === 'width' ? aisleBox : verticalBox;

      controls.setBoundary(aisleBox);
      controls.fitToBox(fitBox, false, FIT_PADDING);
    }
  }, [aisleSize, controls, normal, targetPosition, fit]);
}
