import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { IInventoryRequestST, IInventoryRequestPostRequestST } from 'codegen/inventory_request/api';
import { IReportSpecificationST } from 'codegen/report_specification/api';
import { generateRruleFromUIComponents } from 'common/functionsReportSpec';
import { fixNumericInputFields } from 'common/functions/domFunctions';
import { WeekDayShort, getWeekIndexByDay, getWeekDayByIndex } from 'common/datetimeFormats';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { schedulerFormStyles } from './styles';
import './customStyles.css';
import { handleSubmit } from './utils/handleSubmit';
import { getAllowedOccurrenceIntervals } from './utils/getAllowedOccurrenceIntervals';
import { validateAllBeforeSubmit } from './utils/validateAllBeforeSubmit';
import { ScheduleFormParameters } from './features/ScheduleFormParameters';
import { ScheduleFormDateAndRecurrence } from './features/ScheduleFormDateAndRecurrence';
import { ScheduleFormDeadlines } from './features/ScheduleFormDeadlines';
import { ScheduleFormAction, ScheduleFormState } from './reducers/ScheduleForm.model';

// make week start on "Monday"
moment.updateLocale('en', { week: { dow: 1 } });

/**
 * Schedule form ref type
 */
export type ScheduleFormRef = {
  validateAndReschedule: () => void;
};

const logPrefix = getLogPrefixForType('COMPONENT', 'ScheduleForm');

export const ScheduleForm = forwardRef(
  (
    {
      systemId,
      reportSpec,
      timezone,
      originalRequest = undefined,
      state,
      dispatch,
      onSubmit,
    }: {
      systemId: string;
      reportSpec: IReportSpecificationST;
      /**
       * Used only when editing an existing request.
       */
      originalRequest?: IInventoryRequestST;
      timezone: string;
      state: ScheduleFormState;
      dispatch: React.Dispatch<ScheduleFormAction>;
      onSubmit: (request: IInventoryRequestPostRequestST) => void;
    },
    ref: React.ForwardedRef<ScheduleFormRef>,
  ) => {
    const { classes } = schedulerFormStyles();

    const showReportDeadlines =
      useFacilityLevelStore().stateFacilityLevel.facilitySettings.show_report_deadlines;

    moment.tz.setDefault(timezone);

    // allow start date to be on the past when editing an existing request
    const isEditReport = Boolean(originalRequest);
    const allowPastStartDate = isEditReport;

    /**
     * Display snackbar with error
     */
    const displayOnSubmitValidationError = () => {
      console.debug(logPrefix, 'displayOnSubmitValidationError');
      toast('Please ensure that all fields are correct.', {
        type: 'error',
        toastId: 'scheduleFormError',
      });
    };

    /**
     * Clear all input fields
     */
    const clearInputs = () => {
      console.debug(logPrefix, 'clearInputs', 'CLEAR_INPUTS');
      dispatch({ type: 'CLEAR_INPUTS' });
    };

    const setDays: (days: WeekDayShort[]) => void = (days) => {
      console.debug(logPrefix, 'setDays', 'SET_DAYS', days);
      dispatch({ type: 'SET_DAYS', payload: days });
    };
    /**
     * Validate form and Schedule Report
     */
    const validateAndSchedule = () => {
      console.debug(logPrefix, 'validateAndSchedule');
      if (validateAllBeforeSubmit(reportSpec, state, allowPastStartDate, dispatch)) {
        dispatch({ type: 'SET_MODAL_CONFIRM', payload: true });
      } else {
        displayOnSubmitValidationError();
      }
    };

    /**
     * Validate form and RE-Schedule Report
     */
    const validateAndReschedule = () => {
      console.debug(logPrefix, 'validateAndReschedule');
      if (validateAllBeforeSubmit(reportSpec, state, allowPastStartDate, dispatch)) {
        handleSubmit({ timezone, onSubmit, reportSpec, setDays, state });
      } else {
        displayOnSubmitValidationError();
      }
    };

    // useImperativeHandle allows this component to expose methods to its parent components
    useImperativeHandle(ref, () => ({
      clearInputs,
      validateAndSchedule,
      validateAndReschedule,
    }));

    // Update dateFrom and dateUntil state on mount and timezone change.
    // By updating dateTime states this way we make sure that changing facility
    // which may be in different timezone, converts input values in appropriate timezone.
    useEffect(() => {
      if (timezone) {
        dispatch({
          type: 'SET_DATE_FROM_AND_UNTIL',
          payload: {
            dateFrom: moment().add(1, 'minutes'),
            dateUntil: moment(state.dateFrom).add(1, 'month'),
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone]);

    // Invoke form value initialization ONLY upon reportSpec and timezone change.
    useEffect(() => {
      if (!reportSpec) return;
      // If we're editing an existing inventory request
      // we initialize all form fields with the correspondent data
      if (originalRequest) {
        dispatch({
          type: 'INITIALIZE_EXISTING_REQUEST',
          payload: { reportSpec, originalRequest },
        });
      } else {
        // When creating a new inventory request we just set the dateFrom to now
        dispatch({
          type: 'INITIALIZE_NEW_REQUEST',
          payload: { reportSpec },
        });
      }
    }, [dispatch, originalRequest, reportSpec]);

    useEffect(() => {
      getAllowedOccurrenceIntervals(
        state.isRecurring,
        state.dateFrom,
        state.dateUntil,
        state.interval,
        dispatch,
      );
    }, [dispatch, state.dateFrom, state.dateUntil, state.interval, state.isRecurring]);

    useEffect(() => {
      fixNumericInputFields();
    }, [reportSpec]);

    const { rString: rStringDeadline } = generateRruleFromUIComponents({
      days: state.days.map((weekDayShort: WeekDayShort) => {
        const date1 = moment(state.dateFrom).startOf('day');
        const date2 = moment(state.dateFrom)
          .add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes')
          .startOf('day');

        const daysDiff = date2.diff(date1, 'days');
        const weekIndex = getWeekIndexByDay(weekDayShort) + daysDiff;

        return getWeekDayByIndex(weekIndex);
      }),
      setDays,
      isRecurring: state.isRecurring,
      dateFrom: moment(state.dateFrom).add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes'),
      dateUntil: moment(state.dateUntil).add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes'),
      occurrenceInterval: state.occurrenceInterval,
      timezone,
      reportSpecTriggering: '',
      interval: state.interval,
    });

    if (isEmpty(reportSpec)) {
      return null;
    }

    if (!isEditReport && state.reportParamValues) {
      return (
        <Grid direction="column" spacing={3} container>
          <Grid
            data-testid="c-request-details-card"
            className={classes.gridItem}
            xs={12}
            sm={6}
            item
          >
            <ScheduleFormParameters dispatch={dispatch} reportSpec={reportSpec} state={state} />
            <ScheduleFormDateAndRecurrence
              systemId={systemId}
              allowPastStartDate={allowPastStartDate}
              dispatch={dispatch}
              reportSpec={reportSpec}
              setDays={setDays}
              state={state}
              timezone={timezone}
            />
            <Divider className={classes.divider} />
            {!isEmpty(reportSpec) && showReportDeadlines && (
              <ScheduleFormDeadlines
                systemId={systemId}
                state={state}
                rStringDeadline={rStringDeadline}
                dispatch={dispatch}
              />
            )}
          </Grid>
          <Grid
            data-testid="c-request-dates-card"
            className={classes.gridItem}
            xs={12}
            md={12}
            item
          >
            {/* TODO: fix CY - data-testid="c-request-dates-card" */}
            <Button
              data-testid="c-schedule-report-button"
              fullWidth
              style={{ marginTop: '16px' }}
              variant="contained"
              color="primary"
              onClick={validateAndSchedule}
            >
              Schedule report
            </Button>
          </Grid>

          <ModalConfirm
            opened={state.modalConfirm}
            title="Schedule Report"
            message="Are you sure?"
            handleClose={() => dispatch({ type: 'SET_MODAL_CONFIRM', payload: false })}
            onConfirm={() => {
              dispatch({ type: 'SET_MODAL_CONFIRM', payload: false });
              handleSubmit({ onSubmit, reportSpec, setDays, state, timezone });
            }}
          />
        </Grid>
      );
    }

    return (
      <>
        <ScheduleFormParameters dispatch={dispatch} reportSpec={reportSpec} state={state} />
        <Divider className={classes.divider} />
        <ScheduleFormDateAndRecurrence
          systemId={systemId}
          allowPastStartDate={allowPastStartDate}
          dispatch={dispatch}
          reportSpec={reportSpec}
          setDays={setDays}
          state={state}
          timezone={timezone}
        />
        <Divider className={classes.divider} />
        {!isEmpty(reportSpec) && showReportDeadlines && (
          <ScheduleFormDeadlines
            systemId={systemId}
            state={state}
            rStringDeadline={rStringDeadline}
            dispatch={dispatch}
          />
        )}
      </>
    );
  },
);
