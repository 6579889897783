import { Grid } from '@mui/material';
import { IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST } from 'codegen/location_information';
import { Metric } from 'components/Diagnostics/Metric';

export const PictureInfo = ({
  pictureInfo,
}: {
  pictureInfo?: IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST;
}) => {
  if (!pictureInfo) {
    return 'No Picture Info';
  }

  const cameraSettings = pictureInfo.parameters?.cameraSettings;
  const pictureDelay = pictureInfo.parameters?.pictureDelaySec;
  const torchBrightness = pictureInfo.parameters?.torchBrightness;

  return (
    <Grid alignItems="stretch" container spacing={1.8} sx={{ paddingLeft: '5px' }}>
      <Grid item md={2}>
        Exp Time:
      </Grid>
      <Grid item md={2}>
        {cameraSettings?.exposureTime}
      </Grid>
      <Grid item md={2}>
        Contrast:
      </Grid>
      <Grid item md={2}>
        {cameraSettings?.contrast}
      </Grid>
      <Grid item md={2}>
        Gain:
      </Grid>
      <Grid item md={2}>
        {cameraSettings?.gain}
      </Grid>
      <Grid item md={2}>
        FPS:
      </Grid>
      <Grid item md={2}>
        <Metric unitLong="frames per second" value={cameraSettings?.fps || '-'} />
      </Grid>
      <Grid item md={3}>
        Resolution:
      </Grid>
      <Grid item md={4}>
        <Metric
          unit="px"
          unitLong="pixels"
          value={`${cameraSettings?.resolutionWidth} x ${cameraSettings?.resolutionHeight}`}
        />
      </Grid>
      <Grid item md={2}>
        Gamma:
      </Grid>
      <Grid item md={2}>
        <Metric value={cameraSettings?.gamma || '-'} />
      </Grid>
      <Grid item md={2}>
        Sharpness:
      </Grid>
      <Grid item md={2}>
        <Metric value={cameraSettings?.sharpness || '-'} />
      </Grid>
      <Grid item md={2}>
        Saturation:
      </Grid>
      <Grid item md={2}>
        <Metric value={cameraSettings?.saturation || '-'} />
      </Grid>
      <Grid item md={3}>
        Picture Delay:
      </Grid>
      <Grid item md={3}>
        <Metric value={pictureDelay?.value || '-'} unit="s" unitLong="seconds" />
      </Grid>
      <Grid item md={4}>
        Torch Brightness:
      </Grid>
      <Grid item md={2}>
        <Metric value={torchBrightness?.value || '-'} />
      </Grid>
    </Grid>
  );
};
