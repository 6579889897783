import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const fullSizeImageModalStyles = makeStyles()((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    maxWidth: '65%',
    paddingRight: '2em !important',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    overflow: 'visible',
    position: 'relative',
  },

  switchIcon: {
    position: 'absolute',
    right: '0.05em',
    top: '17px',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    zIndex: 1300,
    cursor: 'pointer',
  },

  dotsContainer: {
    position: 'absolute',
    right: '0.6em',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    zIndex: 1300,
  },

  dot: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[400],
  },

  activeDot: {
    backgroundColor: theme.palette.primary.main,
  },
}));
