import { IWMSSlotStatusST } from 'codegen/report';
import { BarcodeData } from 'udb/inventory/models/BarcodeView.model';

export const getBarcodesFromWmsStatus = (
  wmsStatus: IWMSSlotStatusST,
  barcodes: {
    [barcode: string]: BarcodeData;
  } = {},
) => {
  if (wmsStatus.barcodes && wmsStatus.barcodes.length) {
    wmsStatus.barcodes.forEach((id) => {
      if (id !== null) {
        if (barcodes[id]) {
          barcodes[id] = {
            ...barcodes[id],
            expected_at: [...barcodes[id].expected_at, wmsStatus.slot_label],
          };
        } else {
          barcodes[id] = {
            id,
            found_at: [],
            expected_at: [wmsStatus.slot_label],
          };
        }
      }
    });
  }
  return barcodes;
};
