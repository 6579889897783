/* eslint-disable import/no-unused-modules */
import { QueryNames } from 'ts-types/QueryNames';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { getBarcodeListFromMetadataResponse } from '../utils/getBarcodeListFromMetadataResponse';
import { getLocationMetadata } from '../api/getImageMetadata';

export const useBarcodeMetadata = ({
  locationData,
  imageURL,
  systemId,
}: {
  locationData: ILocationData;
  imageURL: string;
  systemId: string;
}) => {
  // Split imageURL on '/' and '?', and take the element that comes after "image" to get image GUID
  const urlParts = imageURL.split(/[/?]/);
  const imageGuid = urlParts[urlParts.indexOf('image') + 1];

  const { location, slotStatusVersion, isReview } = locationData;

  const { data: barcodesMetadata } = useQuery({
    queryKey: [
      QueryNames.LOCATION_MODAL_GET_LOCATION_METADATA,
      `${systemId}_${location}_${slotStatusVersion}_${isReview}`,
    ],
    queryFn: () => getLocationMetadata(systemId, location, slotStatusVersion, isReview),

    select: ({ data: resultMetadataResponse }) =>
      getBarcodeListFromMetadataResponse({ resultMetadataResponse, imageGuid })?.map((barcode) => ({
        ...barcode,
        id: crypto.randomUUID(),
      })),
  });

  return useMemo(() => ({ barcodesMetadata }), [barcodesMetadata]);
};
