/* eslint-disable import/no-unused-modules */
import { QueryNames } from 'ts-types/QueryNames';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { getLocationMetadata } from '../api/getImageMetadata';
import { getActivityGroupIdForImage } from '../utils/getActivityGroupIdForImage';

export const useImageActivityGroup = ({
  locationData,
  imageURL,
  systemId,
}: {
  locationData: ILocationData;
  imageURL: string;
  systemId: string;
}) => {
  // Split imageURL on '/' and '?', and take the element that comes after "image" to get image GUID
  const urlParts = imageURL?.split(/[/?]/);
  const imageGuid = urlParts ? urlParts[urlParts.indexOf('image') + 1] : null;
  const { location, slotStatusVersion, isReview } = locationData;

  const { data: barcodesMetadata } = useQuery({
    queryKey: [
      QueryNames.LOCATION_MODAL_GET_LOCATION_METADATA,
      `${systemId}_${location}_${slotStatusVersion}_${isReview}`,
    ],
    queryFn: () => getLocationMetadata(systemId, location, slotStatusVersion, isReview),

    select: ({ data: resultMetadataResponse }) => {
      const activityGroupId = imageGuid
        ? getActivityGroupIdForImage({
            resultMetadataResponse,
            imageGuid,
          })
        : undefined;
      if (!activityGroupId) {
        return undefined;
      }
      return resultMetadataResponse.activity_group_id_to_input_data[activityGroupId];
    },
  });

  return useMemo(() => ({ barcodesMetadata }), [barcodesMetadata]);
};
