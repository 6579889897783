import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { ItemData } from 'udb/inventory/models/ItemView.model';
import { SegmentSelector } from './SegmentSelector';

export const ReportItemViewCustomToolbar = ({
  barcodes,
  setStartSlot,
}: {
  barcodes: ItemData[];
  setStartSlot: React.Dispatch<React.SetStateAction<number>>;
}) => (
  <GridToolbarContainer>
    <SegmentSelector barcodes={barcodes} setStartSlot={setStartSlot} />
    <GridToolbarExport />
  </GridToolbarContainer>
);
