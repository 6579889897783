import { CameraControls } from '@react-three/drei';
import CameraControlsLib from 'camera-controls';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { useFitInLandscape } from './hooks/useFitInLandscape';
import { useTopZoomClamp } from '../../hooks/useTopZoomClamp';

export const TopViewScene = ({
  selectedFlightDomain,
  domainSize,
}: {
  domainSize: Vec6;
  selectedFlightDomain: string;
}) => {
  useTopZoomClamp(domainSize, { padding: 100, selectedFlightDomain });
  useFitInLandscape({ domainSize, selectedFlightDomain });

  return (
    <CameraControls
      makeDefault
      verticalDragToForward
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.ROTATE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.ZOOM,
      }}
      minPolarAngle={0}
      maxPolarAngle={0}
    />
  );
};
