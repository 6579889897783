import { makeStyles } from 'tss-react/mui';

export const useWmsDataSimulatorStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  applyEditsButton: {
    marginBottom: 6,
  },
  cardsContainer: {
    flex: '1 1 auto',
    minWidth: 0,
    overflowY: 'auto',
    paddingTop: 8,
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: '16px',
  },
  card: {
    border: '1px solid #ccc',
    position: 'relative',
  },
  cardCloseIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
  },
  contentCopyIcon: {
    position: 'absolute',
    top: 8,
    right: 40,
    zIndex: 1,
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
  },
  wmsDataAccordion: {
    display: 'flex',
  },
}));
