import { Card, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridInitialState,
  GridRowParams,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import LZString from 'lz-string';
import { premiumGridStyles } from 'udb/inventory/features/premium-grid/premiumGrid.styles';
import { getGridRowHeight } from 'udb/inventory/features/premium-grid/utils/getGridRowHeight.util';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { ILocationDataST } from 'codegen/report';
import { cleanGridState } from 'udb/inventory/features/premium-grid/utils/cleanGridState.util';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { getFilteredLocationData } from 'udb/inventory/features/premium-grid/utils/getFilteredLocationData.util';
import { decodeGridStateFromUrl } from 'common/Tables/tableFunctions';
import { getLabelOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getLabelOptionsFromData';
import { getIssueValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueValueOptionsFromData';
import { getIssueStateValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueStateValueOptionsFromData';
import { getFilteredLocationDataListFromGrid } from 'udb/inventory/features/premium-grid/utils/getFilteredLocationsFromGridApi.util';
import { LocationModal } from 'components/ModalsAndPopups/LocationModal/LocationModal';
import { getSelectedLocationRows } from 'udb/inventory/features/premium-grid/utils/getSelectedLocationRows';
import { getWarehouseStatusTabs } from '../../models/WarehouseStatusTabs.model';
import { getColumns } from '../../models/WarehouseStatusColumns.model';
import { WarehouseStatusToolbar } from '../toolbar/WarehouseStatusToolbar';
import { SnoozeIssuesModal } from '../snooze-issues-modal/SnoozeIssuesModal';
import { serializedInitialGridState } from '../../defaults/serializedInitialGridState.default';
import { useLocationData } from '../../hooks/useLocationData';
import { useWarehouseStatusStyles } from './WarehouseStatusTable.styles';
import { WarehouseStatusTableHeader } from './features/WarehouseStatusTableHeader';
import { useBarcodeData } from '../../hooks/useBarcodeData';

const STARTING_TAB = 1;
const ACTIVE_TAB_KEY = 'activeTab';
const LOCATION_KEY = 'location';
const DATA_GRID_STATE_KEY = 'dataGridState';

export function WarehouseStatusTable({ gridState }: { gridState?: GridInitialStatePremium }) {
  const { systemId = '' } = useParams();

  const { locationData, loadLocationData, invalidateLocationQuery, isLoadingLocations } =
    useLocationData(systemId);

  const { isLoadingBarcodes, barcodeDataMap } = useBarcodeData(systemId);

  const { classes } = useWarehouseStatusStyles();

  const gridApiRef = useGridApiRef();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;

  const [searchParams, setSearchParams] = useSearchParams();
  const currentLocationName = searchParams.get(LOCATION_KEY);

  const dataGridStateFromURL = searchParams.get(DATA_GRID_STATE_KEY);
  const [gridViewFromURL] = useState<GridInitialState>(
    decodeGridStateFromUrl(dataGridStateFromURL),
  );

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    Number(searchParams.get(ACTIVE_TAB_KEY) ?? STARTING_TAB),
  );
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);
  const [serializedGridState, setSerializedGridState] = useState('');

  const warehouseStatusTabs = useMemo(
    () =>
      getWarehouseStatusTabs({
        isUserAmendAllowed: !!facilitySettings?.allow_user_amend,
        areLabelsShown: !!facilitySettings.show_location_labels,
      }),
    [facilitySettings?.allow_user_amend, facilitySettings.show_location_labels],
  );
  const activeTab = useMemo(
    () => warehouseStatusTabs.filter((tab) => tab.show)[activeTabIndex],
    [activeTabIndex, warehouseStatusTabs],
  );

  useEffect(() => {
    setSearchParams(
      (previousSearchParams) => {
        previousSearchParams.set(ACTIVE_TAB_KEY, String(activeTabIndex));
        return previousSearchParams;
      },
      { replace: true },
    );
    // Intentionally disabled to only run once when component mounts [TC]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preFilteredLocationDataList = useMemo(
    () =>
      getFilteredLocationData(
        locationData,
        activeTab.issueTypeFilters,
        activeTab.issueStateFilters,
        activeTab.slotSettingFilter,
        activeTab?.filter,
      ),
    [locationData, activeTab],
  );

  const locationLabelOptions = useMemo(
    () => getLabelOptionsFromData(preFilteredLocationDataList),
    [preFilteredLocationDataList],
  );

  const issueValueOptions = useMemo(
    () => getIssueValueOptionsFromData(preFilteredLocationDataList, activeTabIndex),
    [activeTabIndex, preFilteredLocationDataList],
  );

  const issueStatusOptions = useMemo(
    () => getIssueStateValueOptionsFromData(preFilteredLocationDataList, activeTabIndex),
    [activeTabIndex, preFilteredLocationDataList],
  );

  // necessary as "By default, columns are ordered according to the order they are included in the columns array."
  // https://mui.com/x/react-data-grid/column-ordering/
  // currently open issue to handle through MUI - https://github.com/mui/mui-x/issues/5704
  // sfarkas - 2024.12.18
  const sortColumnsByActiveTab = useCallback(
    (columns: GridColDef[]): GridColDef[] => {
      const tabColumnOrder = activeTab?.gridState?.columns?.orderedFields;
      return [...columns].sort(
        (a, b) => (tabColumnOrder?.indexOf(a.field) || 0) - (tabColumnOrder?.indexOf(b.field) || 0),
      );
    },
    [activeTab?.gridState?.columns?.orderedFields],
  );

  const columns = useMemo(
    () =>
      sortColumnsByActiveTab(
        getColumns(
          facilitySettings,
          locationLabelOptions,
          issueValueOptions,
          issueStatusOptions,
          isLoadingBarcodes,
          barcodeDataMap,
        ),
      ),
    [
      sortColumnsByActiveTab,
      facilitySettings,
      locationLabelOptions,
      issueValueOptions,
      issueStatusOptions,
      isLoadingBarcodes,
      barcodeDataMap,
    ],
  );

  const onSnooze = useCallback(() => {
    setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
    setIsSnoozeModalOpen(true);
  }, []);

  const onWake = useCallback(() => {
    setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
    setIsSnoozeModalOpen(true);
  }, []);

  const tableToolbar = useCallback(
    () => (
      <WarehouseStatusToolbar
        allowPrint={activeTab.label === 'LABELED'}
        checkboxSelection={checkboxSelection}
        onSnooze={onSnooze}
        onUnSnooze={onWake}
        onSelectChange={setCheckboxSelection}
      />
    ),
    [activeTab, checkboxSelection, onSnooze, onWake],
  );

  const handleGridStateChange = () => {
    const currentGridState = cleanGridState(gridApiRef.current.exportState());
    const currentSerializedGridState = JSON.stringify(currentGridState);

    if (serializedGridState !== currentSerializedGridState) {
      setSerializedGridState(currentSerializedGridState);
    }
  };

  useEffect(() => {
    if (serializedGridState === serializedInitialGridState || serializedGridState === '') {
      return;
    }
    const urlState = searchParams.get(DATA_GRID_STATE_KEY);
    const urlStateDecompressed = urlState
      ? LZString.decompressFromEncodedURIComponent(urlState)
      : null;

    if (serializedGridState !== urlStateDecompressed) {
      const compressedState = LZString.compressToEncodedURIComponent(serializedGridState);
      searchParams.set(DATA_GRID_STATE_KEY, compressedState);
      setSearchParams(searchParams);
    }
  }, [searchParams, serializedGridState, setSearchParams]);

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([_id, value]) => value) as ILocationDataST[];

  const closeLocationModal = useCallback(
    () =>
      setSearchParams((previousSearchParams) => {
        previousSearchParams.delete(LOCATION_KEY);
        return previousSearchParams;
      }),
    [setSearchParams],
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      setSearchParams((previousSearchParams) => {
        previousSearchParams.set(LOCATION_KEY, params.row.slot_label);
        return previousSearchParams;
      }),
    [setSearchParams],
  );

  const locationModalLocations = useMemo(() => {
    if (!currentLocationName) {
      return [];
    }
    const gridFilteredLocationDataList = getFilteredLocationDataListFromGrid(gridApiRef);

    const hasCurrentLocationInFiltered = gridFilteredLocationDataList?.some(
      (location) => location.slot_label === currentLocationName,
    );

    return getSelectedLocationRows(
      currentLocationName,
      gridFilteredLocationDataList && hasCurrentLocationInFiltered
        ? gridFilteredLocationDataList
        : preFilteredLocationDataList,
      locationData,
    );
  }, [currentLocationName, preFilteredLocationDataList, gridApiRef, locationData]);

  const isCurrentLocationInLocationModalLocations = useMemo(
    () => locationModalLocations.some((l) => l.location === currentLocationName),
    [locationModalLocations, currentLocationName],
  );

  return (
    <>
      <Card>
        <WarehouseStatusTableHeader
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          gridApiRef={gridApiRef}
          gridState={gridState}
          warehouseStatusTabs={warehouseStatusTabs}
        />

        <div className={classes.cardBody}>
          <DataGridPremium
            sx={premiumGridStyles}
            columns={columns}
            apiRef={gridApiRef}
            // NOTE: we need to disable virtualization in order to be able to print
            // see this issue: https://github.com/mui/mui-x/issues/9933#issuecomment-1671245332
            // the underlying assumption is that the 'LABELED' tab shouldn't contain that many
            // locations at once, otherwise the performance of the table would be negatively affected.
            // eno: 2024-11-27
            disableVirtualization={activeTab.label === 'LABELED'}
            rows={preFilteredLocationDataList}
            getRowId={(row) => row.slot_label}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
            }
            getRowHeight={getGridRowHeight}
            columnHeaderHeight={45}
            slots={{
              toolbar: tableToolbar,
              loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
            }}
            loading={isLoadingLocations}
            onRowClick={handleRowClick}
            localeText={{
              // @ts-expect-error customising types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
              headerFilterOperatorNotContains: 'not contain',
              headerFilterOperatorIsNotAnyOf: 'is not any of',
              headerFilterOperatorIsNot: 'is not',
            }}
            checkboxSelection={checkboxSelection}
            rowGroupingColumnMode="multiple"
            disableRowSelectionOnClick
            disableAggregation
            headerFilters
            headerFilterHeight={60}
            onStateChange={handleGridStateChange}
            initialState={gridViewFromURL || activeTab.gridState}
          />
        </div>
      </Card>

      {isSnoozeModalOpen && (
        <SnoozeIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationData}
        />
      )}

      {currentLocationName && isCurrentLocationInLocationModalLocations ? (
        <LocationModal
          closeModal={closeLocationModal}
          refreshTableData={() => invalidateLocationQuery(currentLocationName)}
          refreshTableDataChunk={() => invalidateLocationQuery(currentLocationName)}
          filteredLocationList={locationModalLocations}
          parentPage="WarehouseStatus"
          reportId={undefined}
          isLoadingBarcodes={isLoadingBarcodes}
          barcodeDataMap={barcodeDataMap}
        />
      ) : null}
    </>
  );
}
