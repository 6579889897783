import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';

export const IssueRateInput = ({
  stepRef,
  issueRate,
  setIssueRate,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepRef: any;
  issueRate: number;
  setIssueRate: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    stepRef.current.step = '0.1';
    stepRef.current.focus();
  }, [stepRef]);

  return (
    <TextField
      data-testid="c-dev-tools-issue-rate"
      id="issueRate"
      style={{ width: '120px' }}
      type="number"
      size="small"
      inputRef={stepRef}
      InputProps={{
        inputProps: {
          max: 1,
          min: 0,
        },
      }}
      label="Issue rate"
      value={issueRate}
      onChange={(e) => setIssueRate(Number(e.target.value))}
    />
  );
};
