import { useParams } from 'react-router-dom';
import { YesNoModal } from 'components/ModalsAndPopups/YesNoModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { QueryNames } from 'ts-types/QueryNames';
import { User } from '../../model/user.model';
import { deleteUser } from './api/deleteUser.api';

export const DeleteUserModal = ({ user, onClose }: { user: User; onClose: () => void }) => {
  const { systemId = '' } = useParams();
  const queryClient = useQueryClient();

  const { mutate: deleteUserMutation, isPending } = useMutation({
    mutationKey: ['deleteUser', systemId, user.id],
    mutationFn: () => deleteUser({ systemId, userId: user.id }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryNames.FETCH_USERS, systemId],
      });
      toast('User deleted.', { type: 'success' });
      onClose();
    },
    onError: () => {
      toast('User could not be deleted.', { type: 'error' });
      onClose();
    },
  });

  return (
    <YesNoModal
      closeButton={false}
      opened={true}
      title="Delete user?"
      subtitle={`You are about to delete user ${user.email}`}
      bodyText="When you delete a user, you are also removing this user’s access to all the facilities the user is associated with. This operation cannot be undone, are you sure you want to proceed?"
      yesLabel="Delete user"
      noLabel="Cancel"
      loading={isPending}
      onYesFunction={deleteUserMutation}
      onNoFunction={onClose}
      yesCTA={true}
    />
  );
};
