import { Modal } from '@mui/material';
import { Box } from 'components/common/Box';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import ImageIcon from '@mui/icons-material/Image';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useState } from 'react';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { fullSizeImageModalStyles } from './fullsizeImageModal.styles';
import { FullSizeImageModalDiagnostics } from './features/full-size-image-modal-diagnostics/FullSizeImageModalDiagnostics';

export const FullSizeImageModal = ({
  imageUrls,
  locationData,
  systemId,
  isModalOpen,
  selectedImageIndex,
  handleCloseModal,
}: {
  imageUrls: string[];
  locationData: ILocationData;
  systemId: string;
  isModalOpen: boolean;
  selectedImageIndex: number;
  handleCloseModal: () => void;
}) => {
  const { classes } = fullSizeImageModalStyles();
  const imageURL = imageUrls[selectedImageIndex];
  const [content, setContent] = useState<'image' | 'debug'>('image');
  const showDiagnostics = userHasPermission(PERMISSION.VIEW_LOCATION_DIAGNOSTICS);

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal} className={classes.modal}>
      <Box className={classes.modalContainer} data-testid="modal-container">
        {content === 'image' && (
          <TransformWrapper>
            <TransformComponent>
              <img
                src={imageURL}
                alt={`fullsize - ${selectedImageIndex}`}
                style={{
                  width: '100%',
                  maxHeight: '90vh',
                  objectFit: 'contain',
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        )}
        {content === 'debug' && (
          <FullSizeImageModalDiagnostics
            imageURL={imageURL}
            locationData={locationData}
            systemId={systemId}
          />
        )}
        {showDiagnostics && (
          <div className={classes.switchIcon}>
            {content === 'image' && (
              <TooltipedIcon
                icon={<ImageIcon onClick={() => setContent('debug')} />}
                tooltip="Show image diagnostics"
              />
            )}
            {content === 'debug' && (
              <TooltipedIcon
                icon={<TroubleshootIcon onClick={() => setContent('image')} />}
                tooltip="Show images"
              />
            )}
          </div>
        )}
        <div className={classes.dotsContainer}>
          {imageUrls.map((imageUrl, index) => (
            <div
              key={imageUrl}
              className={`${classes.dot} ${selectedImageIndex === index ? classes.activeDot : ''}`}
              data-testid="dot"
            />
          ))}
        </div>
      </Box>
    </Modal>
  );
};
