import { Tooltip } from '@mui/material';
import { ILocationDataST } from 'codegen/warehouse_status';
import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';
import { getWasFoundAt } from '../../utils/getWasFoundAt';

export const ContentWasFoundAtCell = ({
  row,
  isLoadingBarcodes,
  barcodeDataMap,
}: {
  row: ILocationDataST;
  isLoadingBarcodes: boolean;
  barcodeDataMap?: BarcodeDataMap;
}) => {
  const wasFoundAt = getWasFoundAt(row, barcodeDataMap);

  return (
    <Tooltip
      title={isLoadingBarcodes ? 'Loading...' : `The expected content was found at ${wasFoundAt}`}
      className="c-data-grid-cell c-data-grid-cell-content-at"
    >
      {isLoadingBarcodes ? <span>Loading...</span> : <span>{wasFoundAt}</span>}
    </Tooltip>
  );
};
