import { useMemo } from 'react';
import { matchBarcode } from 'common/functions/barcodes/matchBarcode.util';
import { ItemData } from 'udb/inventory/models/ItemView.model';
import { getItemsFromLocation } from 'common/functions/items/itemsFunctions';
import { useReportData } from './useReportData';

export const useReportItemData = ({
  systemId,
  reportId,
}: {
  systemId: string;
  reportId: string;
}) => {
  const { locationData, isLoadingReportLocations } = useReportData({ systemId, reportId });

  const itemData = useMemo(() => {
    let items: {
      [item: string]: ItemData;
    } = {};

    locationData.forEach((l) => {
      items = { ...items, ...getItemsFromLocation(l.slotStatus) };
    });

    Object.keys(items).forEach((key) => {
      const item = items[key];

      item.barcodeStatus = matchBarcode(
        item.expected_at ? item.barcode : '',
        item.found_at ? item.barcode : '',
      );
    });

    return items;
  }, [locationData]);

  return useMemo(
    () => ({ itemData, isLoading: isLoadingReportLocations }),
    [itemData, isLoadingReportLocations],
  );
};
