import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';
import { BarcodeRow } from '../models/BarcodeRow.model';

export const getCommaSeparatedListOfFoundAt = (
  row: BarcodeRow,
  isLoadingBarcodes: boolean,
  barcodeDataMap?: BarcodeDataMap,
) => {
  const foundAtLocations = [
    ...(barcodeDataMap?.[row.contentFound]?.found_at || []),
    ...(barcodeDataMap?.[row.expectedContent]?.found_at || []),
  ];

  return isLoadingBarcodes
    ? 'Loading...'
    : foundAtLocations.filter((location) => location !== row.locationData.location).join(', ') ||
        '';
};
