import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { Spinner } from 'components/common/Spinner';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import {
  Button,
  ButtonGroup,
  Card,
  Paper,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useMemo, useRef } from 'react';
import { ZoomControls } from 'shared/map-3d/zoom-controls/ZoomControls';
import { useZoomToggle } from 'shared/map-3d/hooks/useZoomToggle';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationDataST } from 'codegen/warehouse_status';
import { LocationDetailsDrawer } from 'shared/map-3d/location-details-drawer/LocationDetailsDrawer';
import { useLocationDetails } from 'shared/map-3d/hooks/useLocationDetails';
import { useActiveBinSelection } from 'shared/map-3d/hooks/useActiveBinSelection';
import { BinHighlight } from 'shared/map-3d/bin-highlight/BinHighlight';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { Bin3DContainer } from 'shared/map-3d/bin3D/Bin3D';
import { INVENTORY_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { AisleViewScene } from '../../../../shared/map-3d/aisle-view/features/AisleViewScene';
import { TopViewMap } from './features/top-view-map/TopViewMap';
import { useLocationData } from '../warehouse-status/hooks/useLocationData';
import { useWarehouseStatus3DStyle } from './styles/warehouseStatus3D.style';
import { TopViewScene } from './features/top-view-map/features/top-view-scene/TopViewScene';
import { useAisleNavigation } from './hooks/useAisleNavigation';
import { useSearchParamViewState } from './hooks/useSearchParamViewState';

export const WarehouseStatus3D = () => {
  const { classes } = useWarehouseStatus3DStyle();
  const { systemId = '', tabIndex = '0' } = useParams<{ systemId: string; tabIndex: string }>();
  const tabIndexNumber = Number(tabIndex);
  const { isLoadingLocations, locationDataMap, locationData } = useLocationData(systemId);
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');

  const { view, selectedAisle, set3DViewSearchParams } = useSearchParamViewState({
    facilityMap,
    tabIndexNumber,
  });

  const { aisleBins, summary = [] } = useAisleView({
    aisleSideNode: selectedAisle,
    locationDataMap,
    currentLocationName: locationData[0] ? locationData[0].slot_label : '',
  });

  const handleViewToggle = useCallback(
    (_: React.MouseEvent<HTMLElement>, view: 'aisle' | 'top') => set3DViewSearchParams({ view }),
    [set3DViewSearchParams],
  );

  const { activeBinName, aisleBinsWithHandlers, resetActiveBin } = useActiveBinSelection(aisleBins);

  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;
  const location = locationDataMap.get(activeBinName);

  const selected3dBin = useMemo(
    () => aisleBins.find((bin) => bin.name && bin.name === activeBinName),
    [activeBinName, aisleBins],
  );

  const issueLogic = useMemo(() => {
    if (!location) {
      return undefined;
    }
    const selectedLocation = getRowForFullReportTable(
      location?.slot_label ?? '',
      location,
      location?.issues ?? [],
    );

    const { issueLogic } = location
      ? getIssueAndBarcodeMatchingLogic(
          selectedLocation as unknown as ILocationDataST,
          facilitySettings,
        )
      : { issueLogic: undefined };
    return issueLogic;
  }, [location, facilitySettings]);

  const details = useLocationDetails({
    activeBinName,
    issueLogic,
    locationDataMap,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const { zoomType, handleZoomChange, setZoomType } = useZoomToggle({ type: 'width' });

  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (_: PointerEvent, newValue: number) => {
      navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_MAP}/${newValue}`);
    },
    [navigate, systemId],
  );

  const handleSelected = useCallback(
    (aisleId: string) => {
      set3DViewSearchParams({ aisleId, view: 'aisle' });
      setZoomType({ type: 'width' });
    },
    [set3DViewSearchParams, setZoomType],
  );

  const domainSize = useMemo(
    () => facilityMap?.nodes?.[tabIndexNumber]?.box ?? ([0, 0, 0, 0, 0, 0] as Vec6),
    [facilityMap?.nodes, tabIndexNumber],
  );

  const setAisleId = useCallback(
    (aisleId: string) => set3DViewSearchParams({ aisleId }),
    [set3DViewSearchParams],
  );

  const { handlePreviousAisle, handleNextAisle } = useAisleNavigation({
    facilityMap,
    currentFlightDomainIndex: tabIndexNumber,
    selectedAisleId: selectedAisle?.id,
    setSelectedAisle: setAisleId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (facilityMap && !facilityMap.nodes?.[tabIndexNumber]) {
    return <Navigate to={WARNING_PAGES_URLS.NOT_FOUND} replace />;
  }

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        <Tabs value={tabIndexNumber} onChange={handleTabChange}>
          {facilityMap?.nodes?.map(({ name }, index) => (
            <Tab key={name} label={name} value={index} />
          ))}
        </Tabs>
      </div>

      <ErrorBoundary
        fallback={
          <Typography
            color="textSecondary"
            textAlign="center"
            variant="h6"
            component="p"
            gutterBottom
          >
            Sorry an error occured loading the map
          </Typography>
        }
      >
        <div className={classes.wrapper} ref={containerRef}>
          {isLoadingLocations ? (
            <Spinner />
          ) : (
            <>
              <ToggleButtonGroup
                value={view}
                onChange={handleViewToggle}
                aria-label="View"
                exclusive
                className={classes.viewToggle}
              >
                <ToggleButton value="aisle">Aisle</ToggleButton>
                <ToggleButton value="top">Top</ToggleButton>
              </ToggleButtonGroup>
              <Paper elevation={1} className={classes.navigationButtons}>
                <ButtonGroup variant="text" size="medium">
                  <Button onClick={handlePreviousAisle}>Previous</Button>
                  <Button onClick={handleNextAisle}>Next</Button>
                </ButtonGroup>
              </Paper>

              <Map3DCanvas className={classes.canvas}>
                {view === 'aisle' ? <Bin3DContainer bins={aisleBinsWithHandlers} /> : null}

                {selected3dBin?.position && selected3dBin?.normal ? (
                  <BinHighlight
                    normal={selected3dBin.normal}
                    position={selected3dBin.position}
                    scale={selected3dBin.scale}
                  />
                ) : null}

                {view === 'top' ? (
                  <TopViewMap
                    selectedFlightDomain={facilityMap?.nodes?.[tabIndexNumber]?.name ?? ''}
                    handleSelected={handleSelected}
                    selectedAisleId={selectedAisle?.id}
                  />
                ) : null}

                {view === 'aisle' ? (
                  <AisleViewScene
                    aisleSize={selectedAisle?.box ?? [0, 0, 0, 0, 0, 0]}
                    normal={vectorLike2Vector(selectedAisle?.normal ?? [0, 0, 0])}
                    fit={zoomType}
                    targetPosition={selected3dBin?.position}
                  />
                ) : (
                  <TopViewScene
                    selectedFlightDomain={facilityMap?.nodes?.[tabIndexNumber]?.name ?? ''}
                    domainSize={domainSize}
                  />
                )}
              </Map3DCanvas>

              <AisleSummary
                label={
                  selectedAisle?.display_name ? `Aisle side: ${selectedAisle.display_name}` : ''
                }
                className={classes.aisleSummary}
                issues={summary}
              />

              {view === 'aisle' ? (
                <>
                  <ZoomControls className={classes.zoomControls} onZoom={handleZoomChange} />
                  <LocationDetailsDrawer
                    open={!!activeBinName && !!selected3dBin}
                    containerRef={containerRef}
                    onClose={resetActiveBin}
                    locationName={activeBinName}
                    details={details}
                  />
                </>
              ) : null}
            </>
          )}
        </div>
      </ErrorBoundary>
    </Card>
  );
};
