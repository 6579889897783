import { Grid } from '@mui/material';

export const Coordinate = ({
  name,
  value,
  posValue,
}: {
  name: string;
  value?: number;
  posValue: JSX.Element | null;
}) => {
  if (value) {
    return (
      <>
        <Grid item md={2}>
          {name}:
        </Grid>
        <Grid item md={10} sx={{ marginTop: '-2px' }}>
          {posValue}
        </Grid>
      </>
    );
  }
  return null;
};
