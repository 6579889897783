import moment from 'moment-timezone';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { LocalStore } from 'common/functions/storageFunctions';

export const dateFormatFleet = (date?: string | null) => {
  if (!date) {
    return '';
  }
  return moment(date).tz(LocalStore.getTimezone()).format(DATETIME_FORMAT);
};
