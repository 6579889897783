import { ILocationDataST } from 'codegen/report';
import { BarcodeData } from 'udb/inventory/models/BarcodeView.model';
import { getFoundAt } from './getFoundAt';
import { getExpectedAt } from './getExpectedAt';

export const getBarcodesFromLocation = (
  location: ILocationDataST,
  barcodes: {
    [barcode: string]: BarcodeData;
  },
) => {
  const barcodesWithExpectedAt = getExpectedAt(location, barcodes);
  return getFoundAt(location, barcodesWithExpectedAt);
};
