import { NodeST } from 'shared/map-container/MapContainer.model';
import { Object3D } from 'three';

export const flattenNodes = (node: NodeST): NodeST[] => {
  let result: NodeST[] = [node];

  if (node.nodes) {
    node.nodes.forEach((childNode) => {
      result = result.concat(flattenNodes(childNode));
    });
  }

  return result;
};

function hasDescendantWithName(node: NodeST, name: string): boolean {
  if (node.name === name) {
    return true;
  }
  if (node.nodes) {
    return node.nodes.some((child) => hasDescendantWithName(child, name));
  }
  return false;
}

export function findNodesByChildName(
  root: NodeST,
  targetType: NodeST['type'],
  childName: string,
): NodeST | null {
  let result = null;

  function dfs(node: NodeST): void {
    if (node.type === targetType && hasDescendantWithName(node, childName)) {
      result = node;
      // exit early, we don't need to search
      // the children of this one is a match
      return;
    }
    if (node.nodes) {
      node.nodes.forEach(dfs);
    }
  }

  dfs(root);
  return result;
}

export function findNodesByType(root: NodeST, targetType: NodeST['type']): NodeST[] {
  const result: NodeST[] = [];

  function depthFirstSearch(node: NodeST): void {
    if (node.type === targetType) {
      result.push(node);
      // exit early, we don't need to search
      // the children of this node if type matches
      return;
    }
    if (node.nodes) {
      node.nodes.forEach(depthFirstSearch);
    }
  }

  depthFirstSearch(root);
  return result;
}
export function traverseThreeObject(
  object: Object3D,
  direction: 'parent' | 'children',
  predicate: (object: Object3D) => boolean,
): Object3D | null {
  if (predicate(object)) {
    return object;
  }
  if (direction === 'parent' && object.parent) {
    return traverseThreeObject(object.parent, 'parent', predicate);
  }
  if (direction === 'children' && object.children.length > 0) {
    return (
      object.children.find((child) => traverseThreeObject(child, 'children', predicate)) || null
    );
  }
  return null;
}
