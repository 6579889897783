import { Box } from '@mui/material';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { InfoCard } from 'components/Diagnostics/InfoCard';
import { Metric } from 'components/Diagnostics/Metric';
import { useImageActivityGroup } from '../../../hooks/useImageActivityGroup';
import { fullSizeImageModalDiagnosticsStyles } from './FullSizeImageModalDiagnostics.styles';
import { PositionMetric } from './PositionMetric';
import { PictureInfo } from './PictureInfo';

export const FullSizeImageModalDiagnostics = ({
  imageURL,
  locationData,
  systemId,
}: {
  imageURL: string;
  locationData: ILocationData;
  systemId: string;
}) => {
  const { classes } = fullSizeImageModalDiagnosticsStyles();

  const activityGroup = useImageActivityGroup({ locationData, imageURL, systemId });
  const activityIdList = activityGroup.barcodesMetadata?.activity_id_list || ['i-am-not-a-real-id'];
  const activityId = activityIdList[0];
  const activityMetadata = activityGroup.barcodesMetadata?.act_id_to_act_metadata[activityId];

  if (!activityMetadata) {
    return <Box className={`${classes.noData} ${classes.root}`}>No Diagnostics Available</Box>;
  }

  const position = activityMetadata.vehicleState?.position;
  const velocity = activityMetadata.vehicleState?.velocity;
  const targetPosition = activityMetadata.vehicleTargetPosition;
  const posError = {
    x: position?.x && targetPosition?.x ? position.x - targetPosition.x : undefined,
    y: position?.y && targetPosition?.y ? position.y - targetPosition.y : undefined,
    z: position?.z && targetPosition?.z ? position.z - targetPosition.z : undefined,
  };
  const attitude = activityMetadata.vehicleState?.attitude;
  const pictureInfo = activityMetadata.picturePayload?.picturesInfo
    ? activityMetadata.picturePayload?.picturesInfo[0]
    : undefined;

  return (
    <Box className={`c-page-content ${classes.cardGrid} ${classes.root}`}>
      <Box sx={{ gridColumn: '1 / 3' }}>
        <InfoCard
          title="Activity ID"
          content={
            <Box className={classes.cardContent}>
              <Metric
                tooltip="ID of the activity the picture is associated with"
                value={activityId}
                unit=""
                unitLong=""
              />
            </Box>
          }
        />
      </Box>
      <InfoCard
        title="Vehicle ID"
        content={
          <Box className={classes.cardContent}>
            <Metric
              tooltip="ID of the drone which took the picture"
              value={activityMetadata.vehicleId || ''}
            />
          </Box>
        }
      />
      <InfoCard
        title="Board Serial Number"
        content={
          <Box className={classes.cardContent}>
            <Metric value={activityMetadata.boardSerialNumber || ''} />
          </Box>
        }
      />
      <InfoCard
        title="Software Version"
        content={
          <Box className={classes.cardContent}>
            <Metric
              tooltip="Version of the Software"
              value={activityMetadata.softwareVersion || ''}
            />
          </Box>
        }
      />
      <InfoCard
        title="Position"
        content={
          <Box className={classes.cardContent}>
            <PositionMetric z={position?.z} x={position?.x} y={position?.y} />
          </Box>
        }
      />
      <InfoCard
        title="Velocity"
        content={
          <Box className={classes.cardContent}>
            <PositionMetric
              x={velocity?.x}
              y={velocity?.y}
              z={velocity?.z}
              unit="m/s"
              unitLong="meters per second"
            />
          </Box>
        }
      />
      <InfoCard
        title="Attitude"
        content={
          <Box className={classes.cardContent}>
            <PositionMetric
              x={attitude?.x}
              y={attitude?.y}
              z={attitude?.z}
              w={attitude?.w}
              unit="rad"
              unitLong="radians"
            />
          </Box>
        }
      />
      <InfoCard
        title="Waypoint"
        content={
          <Box className={classes.cardContent}>
            <PositionMetric
              x={targetPosition?.x}
              y={targetPosition?.y}
              z={targetPosition?.z}
              yaw={targetPosition?.yaw}
            />
          </Box>
        }
      />
      <InfoCard
        title="Position Error"
        content={
          <Box className={classes.cardContent}>
            <PositionMetric x={posError.x} y={posError.y} z={posError.z} />
          </Box>
        }
      />
      <Box sx={{ gridColumn: '3 / 5' }}>
        {' '}
        <InfoCard title="Picture Info" content={<PictureInfo pictureInfo={pictureInfo} />} />
      </Box>
    </Box>
  );
};
