import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box } from 'components/common/Box';

export const InfoCard = ({
  title,
  content,
  direction,
}: {
  title: string;
  content: string | JSX.Element;
  direction?: 'row' | 'column';
}) => (
  <Paper elevation={2}>
    <div style={{ padding: '16px' }}>
      <Box textAlign="left" p={0.5} mb={1}>
        <Typography
          data-testid="c-click-one-of-the-item-title"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          variant="subtitle1"
        >
          {title}
        </Typography>
      </Box>
      <Box
        textAlign="left"
        p={0.5}
        display="flex"
        sx={{
          cursor: 'pointer',
        }}
        flexDirection={direction || 'row'}
        gap={2}
        component="div"
      >
        {content}
      </Box>
    </div>
  </Paper>
);
