import { Typography, TextField } from '@mui/material';
import { IWmsSimulatorPostRequestParamsInnerST } from 'codegen/dev_tools';
import { Dispatch, SetStateAction } from 'react';
import { WmsSimulatorEntry } from './types/WmsSimulatorEntry';

export const WmsSimulatorClientInput = ({
  item,
  index,
  wmsData,
  setWmsData,
}: {
  item: IWmsSimulatorPostRequestParamsInnerST;
  index: number;
  wmsData: WmsSimulatorEntry[];
  setWmsData: Dispatch<SetStateAction<WmsSimulatorEntry[]>>;
}) => {
  const handleClientChange = (index: number, value: string) => {
    const updatedData = [...wmsData];
    updatedData[index].client = value;
    setWmsData(updatedData);
  };

  return (
    <>
      <Typography variant="subtitle2">Client</Typography>
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        value={item.client || ''}
        onChange={(e) => handleClientChange(index, e.target.value)}
        placeholder="Enter client name"
      />
    </>
  );
};
