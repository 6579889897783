import { Tooltip } from '@mui/material';
import { ILocationDataST } from 'codegen/warehouse_status';
import { BarcodeDataMap } from '../../../../models/BarcodeView.model';
import { getExpectedAt } from '../../utils/getExpectedAt';

export const ContentShouldBeAtCell = ({
  row,
  isLoadingBarcodes,
  barcodeDataMap,
}: {
  row: ILocationDataST;
  isLoadingBarcodes: boolean;
  barcodeDataMap?: BarcodeDataMap;
}) => {
  const expectedAt = getExpectedAt(row, barcodeDataMap);

  return (
    <Tooltip
      title={isLoadingBarcodes ? 'Loading...' : `Found content expected at ${expectedAt}`}
      className="c-data-grid-cell c-data-grid-cell-content-at"
    >
      {isLoadingBarcodes ? <span>Loading...</span> : <span>{expectedAt}</span>}
    </Tooltip>
  );
};
