import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Spinner } from 'components/common/Spinner';

import { useLocation, useSearchParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { getMostRecent } from 'common/functions/dateTimeFunctions';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';
import { LocationModalHeader } from './features/header/LocationModalHeader';
import { ImageGridCard } from './features/image-grid/ImageGridCard';
import { FeedbackDrawer } from './features/feedback-drawer/FeedbackDrawer';
import { FeedbackDrawerToggler } from './features/feedback-drawer/FeedbackDrawerToggler';
import { LocationModalSubHeader } from './features/header/LocationModalSubHeader';
import { BarcodesInformation } from './features/barcodes/BarcodesInformation';
import { LocationHistory } from './features/location-history/LocationHistory';
import { WMSRawData } from './features/wms-raw-data/WMSRawData';
import { useLocationModal } from './hooks/useLocationModal';
import { Map3DCard } from './features/map-3d/Map3DCard';
import { useLocationImagesState } from './hooks/useLocationImagesState';
import { useLocationModalStyle } from './LocationModal.styles';
import { LabelsCard } from './features/labels-card/LabelsCard';
import { useLocationHistory } from './features/location-history/hooks/useLocationHistory';
import { OutdatedLocationWarning } from './features/outdated-location-warning/OutdatedLocationWarning';

export const LocationModal = ({
  closeModal,
  refreshTableData,
  refreshTableDataChunk,
  filteredLocationList,
  parentPage,
  reportId,
  isLoadingBarcodes,
  barcodeDataMap,
}: {
  closeModal: () => void;
  // we can remove this property after old WHS and Report tables are removed
  // and refresh only data chunks. With the old components it is not possible
  // sfarkas - 2024.11.28
  refreshTableData: () => void;
  refreshTableDataChunk: () => void;
  filteredLocationList: ILocationData[];
  parentPage: 'WarehouseStatus' | 'Report';
  reportId: string | null | undefined;
  isLoadingBarcodes: boolean;
  barcodeDataMap?: BarcodeDataMap;
}) => {
  const { classes } = useLocationModalStyle();

  const [searchParams, setSearchParams] = useSearchParams();
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);
  const [didUserOverwrite, setDidUserOverwrite] = useState(false);
  const [highlightedBarcode, setHighlightedBarcode] = useState('');

  const { currentSystemId: systemId = '', facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const {
    sendLocationInfo,
    refreshLocationData,
    spinnerLoading,
    currentLocationData,
    setCurrentLocationIndex,
  } = useLocationModal(systemId, filteredLocationList, parentPage, reportId);
  const { historyData } = useLocationHistory(systemId as string, currentLocationData.location);

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    currentLocationData,
    facilitySettings,
  );

  const locationImagesState = useLocationImagesState(systemId ?? '', currentLocationData);

  const isSnoozeEnabled = parentPage === 'WarehouseStatus';

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshTableData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const historyLimitTimePoint =
    parentPage === 'Report'
      ? getMostRecent(
          currentLocationData.rowData.wmsDate,
          currentLocationData.rowData.contentFoundDate,
        )
      : undefined;
  const isLastHistoryTimePoint =
    historyLimitTimePoint && historyData.items.at(0)?.timestamp === historyLimitTimePoint;

  const location = useLocation();
  const is3DMapVisible = location.pathname.includes(INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X);

  // hard to tell what type of data we have in currentLocationData
  // requires clean up
  // sfarkas - 2025-01-14
  const dates = currentLocationData as unknown as {
    verityDate: string;
    wmsDate: string;
  };

  return (
    <Dialog
      open
      className={classes.dialog}
      maxWidth={false}
      fullScreen
      data-testid="c-location-modal"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseModal();
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <LocationModalHeader
          enableSnooze={isSnoozeEnabled}
          locationData={currentLocationData}
          filteredLocationList={filteredLocationList}
          refreshLocationData={refreshLocationData}
          setCurrentLocationIndex={setCurrentLocationIndex}
          facilitySettings={facilitySettings}
          locationImagesState={locationImagesState}
          issueLogic={issueLogic}
          handleCloseModal={handleCloseModal}
        />
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          item
          lg={feedbackDrawerOpen ? 9 : 12}
          sm={feedbackDrawerOpen ? 8 : 12}
          rowSpacing={3}
          className={classes.container}
        >
          {historyLimitTimePoint && !isLastHistoryTimePoint && (
            <Grid item sm={12}>
              <OutdatedLocationWarning
                systemId={systemId as string}
                slotLabel={currentLocationData.location}
                historyDataItems={historyData?.items}
                historyLimitTimePoint={historyLimitTimePoint}
                closeModal={closeModal}
              />
            </Grid>
          )}

          <Grid item>
            {spinnerLoading && <Spinner />}

            {currentLocationData && (
              <LocationModalSubHeader
                locationData={currentLocationData}
                facilitySettings={facilitySettings}
                parentPage={parentPage}
              />
            )}
          </Grid>

          <Grid item container className={classes.barcodesContainer}>
            <Grid sm={12} item className={classes.barcodesContainerItem}>
              <BarcodesInformation
                locationData={currentLocationData}
                facilitySettings={facilitySettings}
                showBarcodeHighlighting={locationImagesState.showBarcodeHighlighting}
                highlightedBarcode={highlightedBarcode}
                setHighlightedBarcode={setHighlightedBarcode}
                isLoadingBarcodes={isLoadingBarcodes}
                barcodeDataMap={barcodeDataMap}
              />
            </Grid>
          </Grid>

          <Grid sm={12} item>
            <ImageGridCard
              systemId={systemId ?? ''}
              locationData={currentLocationData}
              locationImagesState={locationImagesState}
              highlightedBarcode={highlightedBarcode}
              barcodeMatchLogic={barcodeMatchLogic}
              issueLogic={issueLogic}
              setHighlightedBarcode={setHighlightedBarcode}
            />
          </Grid>
          {is3DMapVisible ? (
            <Grid xs={12} item paddingTop={3} position="relative">
              <ErrorBoundary
                fallback={
                  <BaseCard
                    cardFor="neighboring locations"
                    title="Neighboring locations"
                    subtitle="See surrounding issues and navigate to other locations"
                  >
                    <Typography
                      color="textSecondary"
                      textAlign="center"
                      variant="h6"
                      component="p"
                      gutterBottom
                    >
                      Sorry an error occurred loading the map
                    </Typography>
                  </BaseCard>
                }
              >
                <Map3DCard
                  systemId={systemId ?? ''}
                  currentLocationName={currentLocationData.location}
                  issueLogic={issueLogic}
                />
              </ErrorBoundary>
            </Grid>
          ) : null}

          <Grid container item spacing={2}>
            <Grid lg={6} sm={12} item>
              {currentLocationData.location && systemId && (
                <WMSRawData
                  slotLabel={currentLocationData.location}
                  systemId={systemId}
                  key={currentLocationData.location}
                  wmsVersion={currentLocationData.rowData.wmsSlotStatusVersion}
                  // This will be gone with the feature flag removal
                  // Went with this approach because of the "magic number" as a height
                  // sfarkas - 2024-10-16
                  height={facilitySettings.show_location_labels ? '700px' : '424px'}
                />
              )}
            </Grid>

            <Grid lg={6} sm={12} item>
              {facilitySettings.show_location_labels ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <LabelsCard
                    locationId={currentLocationData.location}
                    wmsDateTime={dates.wmsDate}
                    verityDatetime={dates.verityDate}
                    closeModal={closeModal}
                    refreshTableData={refreshTableDataChunk}
                  />

                  {currentLocationData.location && systemId && (
                    <LocationHistory
                      slotLabel={currentLocationData.location}
                      systemId={systemId}
                      timePoint={historyLimitTimePoint}
                    />
                  )}
                </Box>
              ) : (
                currentLocationData.location &&
                systemId && (
                  <LocationHistory
                    slotLabel={currentLocationData.location}
                    systemId={systemId}
                    timePoint={historyLimitTimePoint}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        {feedbackDrawerOpen && (
          <Grid item>
            <FeedbackDrawer
              isOpen={feedbackDrawerOpen}
              locationData={currentLocationData}
              enableSnooze={isSnoozeEnabled}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
        <FeedbackDrawerToggler
          feedbackDrawerOpen={feedbackDrawerOpen}
          setFeedbackDrawerOpen={setFeedbackDrawerOpen}
        />
      </DialogContent>
    </Dialog>
  );
};
