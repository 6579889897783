import { useQuery } from '@tanstack/react-query';
import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';
import { getBarcodesFromLocation } from 'common/functions/barcodes/barcodeFunctions';
import { QueryNames } from 'ts-types/QueryNames';
import { useLocationData } from './useLocationData';

export const useBarcodeData = (systemId: string) => {
  const { locationData, isLoadingLocations } = useLocationData(systemId);

  const getBarcodes = () =>
    new Promise<BarcodeDataMap>((resolve, _reject) => {
      let barcodes: BarcodeDataMap = {};

      locationData.forEach((l) => {
        barcodes = getBarcodesFromLocation(l, barcodes);
      });

      resolve(barcodes);
    });

  const { data: barcodeDataMap, isLoading } = useQuery({
    queryKey: [QueryNames.BARCODES_WAREHOUSE, systemId],
    queryFn: () => getBarcodes(),
    enabled: !isLoadingLocations && !!locationData,
  });

  return { barcodeDataMap, isLoadingBarcodes: isLoading || isLoadingLocations };
};
