import { Typography, TextField, MenuItem } from '@mui/material';
import { IWmsSimulatorPostRequestParamsInnerSTExpectedStateEnum } from 'codegen/dev_tools';
import { Dispatch, SetStateAction } from 'react';
import { WmsSimulatorEntry } from './types/WmsSimulatorEntry';

const expectedStates = Object.values(IWmsSimulatorPostRequestParamsInnerSTExpectedStateEnum);

export const WmsSimulatorExpectedStateSelector = ({
  expectedState,
  index,
  wmsData,
  setWmsData,
}: {
  expectedState: IWmsSimulatorPostRequestParamsInnerSTExpectedStateEnum;
  index: number;
  wmsData: WmsSimulatorEntry[];
  setWmsData: Dispatch<SetStateAction<WmsSimulatorEntry[]>>;
}) => {
  const handleExpectedStateChange = (
    index: number,
    value: IWmsSimulatorPostRequestParamsInnerSTExpectedStateEnum,
  ) => {
    const updatedData = [...wmsData];
    updatedData[index].expected_state = value;

    if (value === 'BARCODE') {
      updatedData[index].number_of_barcodes = 0;
      updatedData[index].barcodes = [];
    }

    setWmsData(updatedData);
  };

  return (
    <>
      <Typography variant="subtitle2">Expected State</Typography>
      <TextField
        select
        fullWidth
        variant="outlined"
        size="small"
        value={expectedState}
        onChange={(e) =>
          handleExpectedStateChange(
            index,
            e.target.value as IWmsSimulatorPostRequestParamsInnerSTExpectedStateEnum,
          )
        }
      >
        {expectedStates.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
