import { IVeritySlotStatusST } from 'codegen/report';
import { BarcodeData } from 'udb/inventory/models/BarcodeView.model';

export const getBarcodeFromVerityStatus = (
  verityStatus: IVeritySlotStatusST,
  barcodes: {
    [barcode: string]: BarcodeData;
  } = {},
) => {
  if (verityStatus.barcodes && verityStatus.barcodes.length) {
    verityStatus.barcodes.forEach((id) => {
      if (id !== null) {
        if (barcodes[id]) {
          barcodes[id] = {
            ...barcodes[id],
            found_at: [...barcodes[id].found_at, verityStatus.slot_label],
          };
        } else {
          barcodes[id] = {
            id,
            expected_at: [],
            found_at: [verityStatus.slot_label],
          };
        }
      }
    });
  }

  return barcodes;
};
