import { makeStyles } from 'tss-react/mui';

export const useSelectTimeIntervalStyles = makeStyles()(() => ({
  formControl: {
    marginLeft: '0.5rem',
    flexDirection: 'column',
  },
  selectInput: {
    marginTop: '0px !important',
  },
}));
