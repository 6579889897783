export enum QueryNames {
  ALL_LOCATIONS_DATA = 'ALL_LOCATIONS_DATA',
  BARCODES_REPORT = 'BARCODES_REPORT',
  BARCODES_WAREHOUSE = 'BARCODES_WAREHOUSE',
  FETCH_FACILITIES = 'FETCH_FACILITIES',
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USER_FACILITIES = 'FETCH_USER_FACILITIES',
  FETCH_USER_GROUPS = 'FETCH_USER_GROUPS',
  GET_FACILITY_MAP_3D = 'GET_FACILITY_MAP_3D',
  GET_FACILITY_SLOTS = 'GET_FACILITY_SLOTS',
  LABELS_DATA = 'LABELS_DATA',
  LABELS_ADD = 'LABELS_ADD',
  LABELS_ARCHIVE = 'LABELS_ARCHIVE',
  LOCATION_HISTORY_GET_REPORT_FROM_LOCATION = 'LOCATION_HISTORY_GET_REPORT_FROM_LOCATION',
  LOCATION_MODAL_GET_IMAGES = 'LOCATION_MODAL_GET_IMAGES',
  LOCATION_MODAL_GET_LOCATION_HISTORY = 'LOCATION_MODAL_GET_LOCATION_HISTORY',
  LOCATION_MODAL_GET_LOCATION_METADATA = 'LOCATION_MODAL_GET_LOCATION_METADATA',
  LOCATION_MODAL_GET_RAW_WMS_DATA = 'LOCATION_MODAL_GET_RAW_WMS_DATA',
  REPORT_SUMMARY = 'REPORT_SUMMARY',
  REPORT_LOCATIONS_DATA = 'REPORT_LOCATIONS_DATA',
  SSO_SIGN_IN = 'SSO_SIGN_IN',
}
