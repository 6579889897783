import { useThree } from '@react-three/fiber';
import CameraControlsLib from 'camera-controls';
import { useEffect } from 'react';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { Box3, Vector3 } from 'three';

const calculationBox = new Box3();

function getAzimuthAngle(box: Box3) {
  const size = box.getSize(new Vector3());
  const direction = size.x > size.y ? 'x' : 'y';
  const rotationAngle = direction === 'y' ? Math.PI / 2 : 0;

  return rotationAngle;
}

export function useFitInLandscape({
  selectedFlightDomain,
  domainSize,
}: {
  selectedFlightDomain: string;
  domainSize: Vec6;
}) {
  const controls = useThree((state) => state.controls);

  useEffect(() => {
    if (controls instanceof CameraControlsLib) {
      const box = calculationBox.setFromArray(domainSize);
      controls.setLookAt(0, 0, 50, 0, 0, 0, false);
      controls.fitToBox(box, false);

      const rotationAngle = getAzimuthAngle(box);
      controls.minAzimuthAngle = rotationAngle;
      controls.maxAzimuthAngle = rotationAngle;

      controls.rotateAzimuthTo(rotationAngle);
    }
  }, [controls, selectedFlightDomain, domainSize]);
}
