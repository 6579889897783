import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, isArray, findIndex, find, inRange, includes } from 'lodash';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';

import { getSanitizedInputValues } from 'common/functions/otherFunctions';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { singleRequestHandler } from 'common/requestHelpers';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { ImageCarouselContainer } from 'components/common/ImageCarousel/ImageCarouselContainer';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { IVeritySlotStatusStateST, IWMSSlotStatusSTStateEnum } from 'codegen/location_information';
import { issuesStore } from '../../../store/IssuesStore';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useRequestController } from '../../../hooks';

import { issueStyles } from './styles';

import { ModalBase } from '../ModalBase';
import { IOverwriteData } from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';

const initialOverwriteData: IOverwriteData = {
  userOverride: undefined,
  location: '',
  verityValue: [],
  wmsValue: '',
  isReview: false,
  userOverrideValue: [],
  verityAmended: '',
  originalVerityValue: '-',
  showContentSetByUser: false,
  imageIds: [],
  reportId: '',
  verityDate: '',
  length: 0,
  selected: false,
  userOverrideUserName: '',
};

interface IOverwriteDataModalProps {
  overwriteData: IOverwriteData[];
  opened: boolean;
  closeModal: () => void;
}

export const OverwriteDataModal = (props: IOverwriteDataModalProps) => {
  const { overwriteData, opened, closeModal } = props;

  const { classes } = issueStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [locationsData, setLocationsData] = useState(overwriteData);
  const [currentLocationData, setCurrentLocationData] = useState<IOverwriteData>(
    find(overwriteData, (issue) => issue.location === searchParams.get('amendLocation')) ||
      (overwriteData as unknown as IOverwriteData),
  );

  const [currentLocationIndex, setCurrentLocationIndex] = useState<number>(
    isArray(overwriteData)
      ? findIndex(
          overwriteData,
          (issue) => issue.location === searchParams.get('amendLocation') || false,
        )
      : 0,
  );
  const [spinner, setSpinner] = useState(0);
  const [carouselSpinner, setCarouselSpinner] = useState(false);
  const [verityCorrect, setVerityCorrect] = useState(true);
  const [shouldUseExpectedValue, setShouldUseExpectedValue] = useState('manually');
  const [amendingContent, setAmendingContent] = useState('');
  const [amendingValue, setAmendingValue] = useState('');
  const [issueImageURLs, setIssueImageURLs] = useState([]);
  const [imageAvailabilityMessage, setImageAvailabilityMessage] = useState('');
  const [issueData, setIssueData] = useState(initialOverwriteData);
  const [comment, setComment] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  // component variables
  const overwriteDataVersion = currentLocationData.version;

  // Context
  const { stateUserLevel } = useUserLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  const { requestController } = useRequestController('OverwriteDataModal');

  // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function
  // const { name: facilityName } = state.facilityData;

  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  // #########################################
  // Rendering and populating inputs according
  // to wms and verity states and values
  // ----------------------------------------
  // NOTE: SS-2021-01-21
  // Working on custom hook for this function
  // #########################################
  const setDefaultSelect = useCallback(() => {
    if (issueData.wmsState === IWMSSlotStatusSTStateEnum.Invalid) {
      setShouldUseExpectedValue('manually');
    }

    if (shouldUseExpectedValue === 'manually' || shouldUseExpectedValue === 'original') {
      setAmendingValue('');
    }

    if (shouldUseExpectedValue === 'expected') {
      setAmendingContent(issueData.wmsState || '');
      setAmendingValue(
        issueData.wmsState === IWMSSlotStatusSTStateEnum.Barcode ? issueData.wmsValue : '',
      );
    }

    if (shouldUseExpectedValue === 'original') {
      setAmendingContent(issueData.verityState || '');
      setAmendingValue(
        issueData.verityState === IVeritySlotStatusStateST.Barcode
          ? (issueData.verityValue as IVeritySlotStatusStateST)
          : (issueData.verityState as IVeritySlotStatusStateST),
      );
    }
  }, [shouldUseExpectedValue, issueData]);

  const updateLocationsData = (locationsData: IOverwriteData[]) => {
    const newLocationsData = [...locationsData];

    // Handle multiple barcode values
    const amendingValues = getSanitizedInputValues(amendingValue);

    let newVerityState: IVeritySlotStatusStateST | undefined;
    let newVerityValue;

    if (issueData.isReview && verityCorrect) {
      newVerityState = locationsData[currentLocationIndex].verityState;
      newVerityValue = locationsData[currentLocationIndex].verityValue;
    } else {
      newVerityState = amendingContent as IVeritySlotStatusStateST;
      newVerityValue =
        amendingContent === IVeritySlotStatusStateST.Barcode
          ? amendingValues.join(', ')
          : newVerityState;
    }

    newLocationsData[currentLocationIndex] = {
      ...newLocationsData[currentLocationIndex],
      verityState: newVerityState,
      verityValue: newVerityValue as string[],
      verityAmended: ' *',
      userOverrideValue: [newVerityValue as string],
      userOverrideUserName: stateUserLevel.username,
      showContentSetByUser: !issueData.isReview,
    };

    return newLocationsData;
  };

  const amendVerityValue = ({
    overwritingItem,
    location,
    version,
    isReview,
    goToNextLocation,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    overwritingItem: any;
    location: string;
    version?: number;
    isReview: boolean;
    goToNextLocation: boolean;
  }) =>
    requestController.doRequest({
      request: issuesStore.userOverride,
      requestParams: [
        {
          systemId,
          data: overwritingItem,
          slot_label: location,
          version,
          isReview,
        },
      ],
      messageErrorFallback: 'Failed to override the request.',
      callbackSuccess: (r) => {
        toast(`Successfully ${isReview ? 'reviewed!' : 'amended!'}`, {
          type: 'success',
        });
        if (r.status === 205 && !goToNextLocation) {
          Object.keys(facilityModalsState.refreshData).forEach((func) =>
            facilityModalsState.refreshData[func](),
          );
        }
        return r;
      },
      callbackFinally: () => {
        if (!goToNextLocation) {
          dispatchFacilityModals({
            type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
            payload: {},
          });
        }
      },
    });

  const clearStateVariables = () => {
    setVerityCorrect(true);
    setAmendingContent('');
    setAmendingValue('');
    setComment('');
    setDefaultSelect();
  };

  const goToNextLocation = (locationsData: IOverwriteData[], currentLocationIndex: number) => {
    // if user is at the last location to review, refresh Verity data
    // displayed in the modal with the new, amended value,
    // otherwise go to the next location to review
    if (currentLocationIndex === locationsData.length - 1) {
      setCurrentLocationData(locationsData[currentLocationIndex]);
      setCurrentLocationIndex(currentLocationIndex);
      searchParams.set('amendLocation', locationsData[currentLocationIndex].location);
      setSearchParams(searchParams);
    } else {
      setCurrentLocationData(locationsData[currentLocationIndex + 1]);
      setCurrentLocationIndex(currentLocationIndex + 1);
      searchParams.set('amendLocation', locationsData[currentLocationIndex + 1].location);
      setSearchParams(searchParams);
    }

    // clear state variables after transitioning to the next location
    // it can be invoked at any point inside this function, as state
    // variables do not depend on current location
    clearStateVariables();
  };

  const handleSubmit = (goToNextLocationClicked: boolean, isVerityValueCorrect: boolean) => {
    setSpinner((repliesPending) => repliesPending + 1);

    // Handle multiple barcode values
    const amendingValues = getSanitizedInputValues(amendingValue);

    interface IOverwriteItemData {
      barcodes: string[];
      verity_correct: boolean;
      comment: string;
      set_to_expected: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: any;
    }

    let overwritingItem: IOverwriteItemData = {
      verity_correct: issueData.userOverride ? false : verityCorrect,
      set_to_expected: shouldUseExpectedValue === 'expected',
      barcodes: amendingContent === IVeritySlotStatusStateST.Barcode ? amendingValues : [],
      state: amendingContent || issueData.verityState,
      comment,
    };

    if (isVerityValueCorrect && issueData.isReview) {
      overwritingItem = {
        ...overwritingItem,
        barcodes:
          issueData.verityState === IVeritySlotStatusStateST.Barcode
            ? (issueData.verityValue as string[])
            : [],
      };
    }

    amendVerityValue({
      overwritingItem,
      location: issueData.location,
      version: issueData.version,
      isReview: issueData.isReview,
      goToNextLocation: goToNextLocationClicked,
    });

    // Update locations data so that by navigating back and forth
    // through locations, users see new data for the locations they
    // already amended
    if (goToNextLocationClicked) {
      const updatedLocationsData = updateLocationsData(locationsData);

      setLocationsData(updatedLocationsData);

      setSpinner((repliesPending) => repliesPending - 1);
      goToNextLocation(updatedLocationsData, currentLocationIndex);
    }
  };

  const goToPreviousLocation = (locationsData: IOverwriteData[], currentLocationIndex: number) => {
    // prevent navigation to previous location if user
    // is at first location to review
    if (currentLocationIndex === 0) return;

    setCurrentLocationData(locationsData[currentLocationIndex - 1]);
    setCurrentLocationIndex(currentLocationIndex - 1);

    searchParams.set('amendLocation', locationsData[currentLocationIndex - 1].location);
    setSearchParams(searchParams);

    // clear state variables after transitioning to the previous location
    // it can be invoked at any point inside this function, as state
    // variables do not depend on current location
    clearStateVariables();
  };

  const handleAmendVerityData = (
    currentLocationData: IOverwriteData,
    locationsData: IOverwriteData[],
  ) => {
    const {
      barcode_min_length: barcodeMinLength,
      barcode_max_length: barcodeMaxLength,
      barcode_invalid_lengths: barcodeInvalidLengths,
    } = stateFacilityLevel.facilitySettings;

    // Check if barcode(s) are of length specified in settings
    if (
      (amendingContent === IVeritySlotStatusStateST.Barcode && !verityCorrect) ||
      (amendingContent === IVeritySlotStatusStateST.Barcode && !currentLocationData.isReview)
    ) {
      const barcodeValid = getSanitizedInputValues(amendingValue).reduce(
        (acc, barcode) =>
          acc &&
          !includes(barcodeInvalidLengths, barcode.length) &&
          barcodeMinLength &&
          barcodeMaxLength
            ? inRange(barcode.length, barcodeMinLength, barcodeMaxLength + 1)
            : false,
        true,
      );

      if (!barcodeValid) {
        const invalidLenghtWarning = !isEmpty(barcodeInvalidLengths)
          ? `Barcodes of length ${barcodeInvalidLengths} are not allowed.`
          : '';
        let snackbarMessage = '';

        if (barcodeMinLength === barcodeMaxLength) {
          snackbarMessage = `The entered barcodes must be ${barcodeMinLength} digits long. ${invalidLenghtWarning}`;
        } else {
          snackbarMessage = `The entered barcodes must be between ${barcodeMinLength} and ${barcodeMaxLength} digits long. ${invalidLenghtWarning}`;
        }

        toast(snackbarMessage, { type: 'error', toastId: 'overwriteDataModalError' });

        return;
      }
    }

    // In amending modal of the Issue page we are only passing
    // data (object) for a single issue. In that case jumping to the
    // next location is not possible, therefore first argument to the
    // handleSubmit function (goToNextLocation) should be false
    if (isArray(locationsData)) {
      handleSubmit(true, verityCorrect);
    } else {
      handleSubmit(false, verityCorrect);
    }
  };

  const handleCloseModal = () => {
    if (locationsData !== overwriteData) {
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    }
    searchParams.delete('amendLocation');
    setSearchParams(searchParams);
    closeModal();
  };

  const saveButtonLabel = (
    issueData: IOverwriteData,
    currentLocationIndex: number,
    locationsData: IOverwriteData[],
  ) => {
    let label = '';

    if (!isArray(locationsData)) {
      label = 'Save amendment';
    } else if (issueData.isReview && currentLocationIndex !== locationsData.length - 1) {
      label = 'Save review and go to next location';
    } else if (!issueData.isReview && currentLocationIndex !== locationsData.length - 1) {
      label = 'Save amendment and go to next location';
    } else if (issueData.isReview && currentLocationIndex === locationsData.length - 1) {
      label = 'Save review';
    } else if (!issueData.isReview && currentLocationIndex === locationsData.length - 1) {
      label = 'Save amendment';
    }

    return label;
  };

  const renderImageCarousel = (issueData: IOverwriteData, issueImageURLs: string[]) => {
    const { location, wmsState, verityState, slotStatusVersion, isReview } = issueData;

    if (!isEmpty(issueImageURLs)) {
      return (
        <ImageCarouselContainer
          systemId={systemId ?? ''}
          location={location}
          isLoading={carouselSpinner}
          imageURLs={issueImageURLs}
          slotStatusVersion={slotStatusVersion as number}
          isReview={isReview}
          imageNavigationOnTop={true}
          keyboardNavigation={undefined}
        />
      );
    }
    if (
      wmsState === IWMSSlotStatusSTStateEnum.Invalid ||
      verityState === IVeritySlotStatusStateST.NoResult
    ) {
      return (
        <Typography align="center" color="textSecondary" variant="h6">
          No images are available for content of this type.
        </Typography>
      );
    }
    return (
      <Typography align="center" color="textSecondary" variant="h6">
        {imageAvailabilityMessage}
      </Typography>
    );
  };

  const renderAmendingBarcodeInput = (
    amendingContent: IWMSSlotStatusSTStateEnum | string,
    amendingValue: string,
    shouldUseExpectedValue: string,
  ) => {
    if (amendingContent === IWMSSlotStatusSTStateEnum.Barcode) {
      return (
        <FormControl className={classes.formControl} variant="outlined">
          <TextField
            disabled={shouldUseExpectedValue !== 'manually'}
            label={`Enter barcode value for location ${issueData.location}`}
            variant="outlined"
            value={amendingContent === IWMSSlotStatusSTStateEnum.Barcode ? amendingValue : ''}
            onChange={(e) => setAmendingValue(e.target.value)}
          />
        </FormControl>
      );
    }
    return undefined;
  };

  const renderDialogTitle = () => {
    let userOrVerity = 'Verity';
    if (
      currentLocationData.userOverrideUserName &&
      currentLocationData.userOverrideUserName !== '-'
    ) {
      userOrVerity = currentLocationData.userOverrideUserName;
    }

    return `Review the content found by ${userOrVerity}`;
  };

  const renderNavigationButtons = () => (
    <Typography color="textSecondary" variant="h6" className={classes.navigationButtons}>
      <NavigateBeforeIcon
        className={clsx(classes.navigationButton, {
          [classes.disabledLink]: currentLocationIndex === 0,
        })}
        onClick={() => goToPreviousLocation(locationsData, currentLocationIndex)}
      />
      <Typography>{`${currentLocationIndex + 1} of ${locationsData.length}`}</Typography>
      <NavigateNextIcon
        className={clsx(classes.navigationButton, {
          [classes.disabledLink]: currentLocationIndex === locationsData.length - 1,
        })}
        onClick={() => goToNextLocation(locationsData, currentLocationIndex)}
      />
    </Typography>
  );

  useEffect(() => {
    setSaveButtonDisabled(() => {
      if (issueData.isReview && verityCorrect) {
        return false;
      }
      return (amendingContent === 'BARCODE' && !amendingValue) || !amendingContent;
    });
  }, [amendingContent, amendingValue, issueData, verityCorrect]);

  useEffect(() => {
    setDefaultSelect();
  }, [issueData, amendingContent, shouldUseExpectedValue, setDefaultSelect]);

  const getSlotImages = useCallback(() => {
    if (currentLocationData.version) {
      singleRequestHandler({
        request: issuesStore.getImages,
        requestParams: [
          systemId,
          currentLocationData.location,
          currentLocationData.version,
          currentLocationData.isReview,
        ],
        callbackBeforeSend: () => setCarouselSpinner(true),
        callbackSuccess: (r) => {
          setIssueImageURLs(() => (r.data?.urls ? r.data?.urls : []));
          const numPendingImages = r.data?.num_requested ? r.data?.num_requested : 0;
          const numCurrentlyAvailableImages = r.data?.urls ? r.data?.urls.length : 0;

          if (numCurrentlyAvailableImages === 0 && numPendingImages === 0) {
            setImageAvailabilityMessage('There are no images available for this location.');
          } else if (numPendingImages > 0) {
            // We don't explicity tell the reviewer how many pictures will be uploaded, because pending image requests may fail.
            // The reviewer does not need to know that some requests failed, as long as they don't all fail.
            // Exposing this number could result in a reviewer expecting 3 pictures, and seeing the counter go down as their
            // requests fail, which would cause a lot of confusion.
            setImageAvailabilityMessage('Waiting for images to be uploaded.');
          } else {
            setImageAvailabilityMessage('');
          }
        },
        callbackFinally: () => setCarouselSpinner(false),
      });
    } else {
      setIssueImageURLs(() => []);
    }
  }, [
    currentLocationData.location,
    currentLocationData.version,
    currentLocationData.isReview,
    systemId,
  ]);

  useEffect(() => {
    // Get images for this location
    // Note: slotStatusVersion does not exist when there
    // is no verity_slot_status for a given location
    // This should never be the case in a review, but we cover it anyway
    getSlotImages();

    const overwriteData: IOverwriteData = {
      isReview: currentLocationData.isReview,
      slotStatusVersion: currentLocationData.version,
      location: currentLocationData.location,
      wmsValue: currentLocationData.wmsValue,
      wmsState: currentLocationData.wmsState,
      verityValue: currentLocationData.verityValue,
      verityState: currentLocationData.verityState,
      verityAmended: currentLocationData.isReview ? currentLocationData.verityAmended : '',
      originalVerityState: currentLocationData.originalVerityState,
      originalVerityValue: currentLocationData.originalVerityValue,
      userOverride: currentLocationData.userOverride,
      userOverrideValue: currentLocationData.userOverrideValue,
      userOverrideUserName: currentLocationData.userOverrideUserName,
      showContentSetByUser: currentLocationData.showContentSetByUser,
      imageIds: currentLocationData.imageIds,
      version: currentLocationData.latestSlotVersion || currentLocationData.version,
      reportId: currentLocationData.reportId,
      verityDate: currentLocationData.verityDate,
    };

    setIssueData(() => overwriteData);
  }, [currentLocationData, getSlotImages, overwriteDataVersion]);

  useEffect(() => {
    if (
      stateFacilityLevel.inventory.imageAvailable?.data?.image_id &&
      currentLocationData.imageIds.includes(
        stateFacilityLevel.inventory.imageAvailable.data.image_id,
      )
    ) {
      getSlotImages();
    }
  }, [stateFacilityLevel.inventory.imageAvailable, currentLocationData.imageIds, getSlotImages]);

  return (
    <ModalBase
      maxWidth="lg"
      testId=""
      opened={opened}
      disableBackdropClick={true}
      title={
        <>
          <Box textAlign="left" p={3}>
            <Typography className={classes.bold} color="secondary" variant="h5">
              {renderDialogTitle()}
            </Typography>
            <Box display="flex" className={classes.subtitleAndNavigation}>
              <Typography style={{ fontWeight: 400 }} color="textSecondary" variant="h6">
                Let us know why are you modifying the value of location {issueData.location}
              </Typography>
              {isArray(locationsData) && renderNavigationButtons()}
            </Box>
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </>
      }
      handleClose={handleCloseModal}
    >
      <Grid alignItems="flex-start">
        <Box pb={2} textAlign={!issueData.isReview ? 'right' : 'left'}>
          <Box>
            <Typography
              className={`${classes.bold} ${classes.lineHeight11}`}
              variant="body1"
              color="secondary"
            >
              {currentLocationData.userOverrideUserName &&
              currentLocationData.userOverrideUserName !== '-'
                ? `Content set by ${currentLocationData.userOverrideUserName}`
                : 'Content found by Verity'}
            </Typography>
            <Typography color="secondary" variant="h5">
              {(currentLocationData.showContentSetByUser
                ? currentLocationData.userOverrideValue
                : currentLocationData.verityValue) + (currentLocationData.verityAmended || '')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid spacing={2} container>
        <Grid md={6} xs={12} className={classes.imageGrid} item>
          {renderImageCarousel(issueData, issueImageURLs)}
        </Grid>
        <Grid className={classes.formGrid} md={6} xs={12} item>
          <Box display="flex" flexDirection="column">
            {!issueData.userOverride && (
              <FormControl className={classes.formControl} variant="outlined">
                <CustomSelect
                  id=""
                  name=""
                  error={false}
                  errorMessage=""
                  testId=""
                  disabled={false}
                  variant="outlined"
                  label="Is the content found by Verity correct?"
                  defaultValue="true"
                  value={verityCorrect ? 'true' : 'false'}
                  onChange={(e) => setVerityCorrect(e.target.value === 'true')}
                  valueOptions={[
                    { value: 'false', label: 'No' },
                    { value: 'true', label: 'Yes' },
                  ]}
                />
              </FormControl>
            )}
            <FormControl className={classes.formControl} variant="outlined">
              <CustomSelect
                id=""
                name=""
                error={false}
                errorMessage=""
                defaultValue=""
                testId=""
                variant="outlined"
                disabled={
                  (issueData.isReview && verityCorrect) || shouldUseExpectedValue !== 'manually'
                }
                label={`Enter the type for the content for location ${issueData.location}`}
                value={amendingContent}
                onChange={(e) => setAmendingContent(e.target.value as IVeritySlotStatusStateST)}
                valueOptions={[
                  {
                    value: IVeritySlotStatusStateST.Barcode,
                    label: DISPLAY_VERITY_STATES.BARCODE,
                  },
                  {
                    value: IVeritySlotStatusStateST.Empty,
                    label: DISPLAY_VERITY_STATES.EMPTY,
                  },
                  {
                    value: IVeritySlotStatusStateST.NotEmpty,
                    label: DISPLAY_VERITY_STATES.NOT_EMPTY,
                  },
                ]}
              />
            </FormControl>

            {renderAmendingBarcodeInput(amendingContent, amendingValue, shouldUseExpectedValue)}

            <FormControl className={classes.formControl} variant="outlined">
              <TextField
                label="Comment"
                multiline
                rows={amendingContent === IVeritySlotStatusStateST.Barcode ? 7 : 10}
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={1} justifyContent="space-beetween" display="flex">
            <Button
              className={classes.actionButton}
              onClick={() => handleAmendVerityData(currentLocationData, locationsData)}
              disabled={saveButtonDisabled}
              variant="contained"
              color="primary"
            >
              {saveButtonLabel(issueData, currentLocationIndex, locationsData)}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {!!spinner && <Spinner />}
    </ModalBase>
  );
};
