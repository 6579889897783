import { CameraControls } from '@react-three/drei';
import CameraControlsLib from 'camera-controls';
import { useZoomClamp } from 'shared/map-3d/aisle-view/hooks/useZoomClamp';
import { useZoomFit } from 'shared/map-3d/aisle-view/hooks/useZoomFit';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { Vector3 } from 'three';

const LOCK_ANGLE = Math.PI / 2;

export const AisleViewScene = ({
  fit = { type: 'width' },
  normal,
  targetPosition,
  aisleSize,
}: {
  aisleSize: Vec6;
  fit?: { type: 'height' | 'width' };
  normal?: Vector3;
  targetPosition?: Vector3;
}) => {
  useZoomFit({
    aisleSize,
    normal,
    targetPosition,
    fit,
  });
  useZoomClamp(aisleSize);
  return (
    <CameraControls
      makeDefault
      verticalDragToForward
      boundaryEnclosesCamera
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.NONE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.NONE,
      }}
      minPolarAngle={LOCK_ANGLE}
      maxPolarAngle={LOCK_ANGLE}
    />
  );
};
