import { ILocationDataST, IVeritySlotStatusST } from 'codegen/report';
import { BarcodeData } from 'udb/inventory/models/BarcodeView.model';
import { getBarcodeFromVerityStatus } from './getBarcodeFromVerityStatus';

export const getFoundAt = (
  location: ILocationDataST,
  barcodes: {
    [barcode: string]: BarcodeData;
  },
) => {
  if (!location.verity_status || typeof location.verity_status === 'string') {
    return barcodes;
  }

  const verityStatus = location.verity_status as IVeritySlotStatusST;
  return getBarcodeFromVerityStatus(verityStatus, barcodes);
};
