import { Dispatch, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AUTH_PAGES_URLS } from 'common/pages';
import { ClientLevelAction } from 'store/ClientLevelStore/ClientLevelStore.model';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useClientModalsStore, useFacilityModalsStore } from '../../store/Modals';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';

import { resetStoreState } from './utils/resetStoreState.util';

export const SignOut = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const domainSwitch = searchParams.get('domain_switch');
  const arrivedFromOldDomain = searchParams.get('arrived_from_old_domain');
  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { dispatchClientLevel } = useClientLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { dispatchClientModals } = useClientModalsStore();

  useEffect(() => {
    toast(location.state?.logoutMessage || 'You are logged out!', {
      type: location.state?.variant || 'success',
      toastId: 'signOutSuccess',
    });
  });

  useEffect(() => {
    resetStoreState(
      dispatchFacilityLevel,
      dispatchClientLevel as Dispatch<ClientLevelAction>,
      dispatchUserLevelStore,
      dispatchFacilityModals,
      dispatchClientModals,
    );
    navigate(AUTH_PAGES_URLS.SIGNIN, {
      state: { domainSwitch, arrivedFromOldDomain },
    });
  });

  sessionStorage.clear();

  return null;
};
