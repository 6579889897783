import React, { useState, useEffect, useCallback } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import isEmpty from 'lodash/isEmpty';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { Box } from 'components/common/Box';
import { groundControlServices } from '../../services/GroundControlServices';
import { IFleeSimulationData } from '../../interfaces/groundControlInterfaces';
import { IFlightDomainData } from '../../store/GroundControl/groundControlLevelStore.model';
import { DeveloperDrawerButton } from './DeveloperDrawerButton';

export const FleetSimulationForm = ({
  systemId,
  flightDomainsList,
  errors,
  setErrors,
  addToOutput,
}: {
  systemId: string;
  errors: { [key: string]: string };
  setErrors: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  flightDomainsList: IFlightDomainData[];
  addToOutput: (value: string[]) => void;
}) => {
  const initialData = {
    duration: 30,
    period: 1,
    num_drones_flying: 10,
    optimal: false,
    sub_optimal_num_drones_no_wifi: 0,
  };
  const [flightDomainId, setFlightDomainId] = useState('');
  const [flightDomainData, setFlightDomainData] = useState<IFlightDomainData>();
  const [data, setData] = useState<IFleeSimulationData>(initialData);

  const optionsValue = flightDomainsList.map((flightDomain) => ({
    value: flightDomain.flight_domain_id,
    label: flightDomain.flight_domain_name,
  }));

  const handleDataChange = (name: string, value: number | boolean) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (flightDomainId) {
      const selectedFlightDomainData = flightDomainsList.find(
        (fd) => fd.flight_domain_id === flightDomainId,
      );
      setFlightDomainData(selectedFlightDomainData);
    }
  }, [flightDomainId, flightDomainsList]);

  const maxDrones = (flightDomainData: IFlightDomainData) => {
    if (flightDomainData) {
      return (
        (flightDomainData.fleet_status_summary?.num_drones_flying || 0) +
        (flightDomainData.fleet_status_summary?.num_drones_on_charger || 0) +
        (flightDomainData.fleet_status_summary?.num_drones_landed_in_error || 0)
      );
    }
    return 0;
  };

  const handleErrors = useCallback(() => {
    if (flightDomainData) {
      if (data.num_drones_flying > maxDrones(flightDomainData)) {
        setErrors((err: { [key: string]: string }) => ({
          ...err,
          nFlying: `Max drones flying are ${maxDrones(flightDomainData)}`,
        }));
        return;
      }
      if (data.sub_optimal_num_drones_no_wifi > data.num_drones_flying) {
        setErrors((err: { [key: string]: string }) => ({
          ...err,
          nNoWifi: "Can't be higher then number of flying drones",
        }));
        return;
      }
      setErrors({});
    }
  }, [data.num_drones_flying, data.sub_optimal_num_drones_no_wifi, flightDomainData, setErrors]);

  useEffect(() => {
    handleErrors();
  }, [handleErrors]);

  return (
    <Box>
      <DeveloperDrawerButton
        testId="c-dev-tools-flight-domain-unlock"
        buttonLabel="Start flight simulation"
        disabled={!flightDomainId || !isEmpty(errors)}
        clickHandler={() => {
          addToOutput(['::: Flight simulation request has been sent!']);
          groundControlServices.requestFlightSimulation(systemId, flightDomainId, data).then(() => {
            addToOutput(['::: Flight simulation has ben started!']);
          });
        }}
        content={
          <TooltipedIcon
            tooltip="Start flight simulation"
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        }
      />
      <Box width="100%" display="flex" flexDirection="column" my={2}>
        <CustomSelect
          id="flightDomainId"
          variant="outlined"
          name="Flight Domain"
          value={flightDomainId}
          valueOptions={optionsValue}
          size="small"
          style={{ marginBottom: '16px' }}
          onChange={(e) => {
            setFlightDomainId(e.target.value);
            setData(initialData);
          }}
          label="Select Flight Domain"
          error={false}
          errorMessage=""
          defaultValue=""
          disabled={isEmpty(flightDomainsList)}
          testId="flightDomainId"
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-duration"
          type="number"
          size="small"
          name="duration"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Duration"
          value={data.duration}
          disabled={!flightDomainId}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-period"
          type="number"
          size="small"
          name="period"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Period"
          value={data.period}
          disabled={!flightDomainId}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-drones-flying"
          type="number"
          size="small"
          name="num_drones_flying"
          error={Boolean(errors.nFlying)}
          helperText={errors.nFlying}
          disabled={!flightDomainId}
          InputProps={{
            inputProps: {
              min: 0,
              max: flightDomainData ? maxDrones(flightDomainData) : 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Drones Flying"
          value={data.num_drones_flying}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />

        <TextField
          data-testid="c-dev-tools-fleet-simulation-drones-no-wifi"
          type="number"
          size="small"
          name="sub_optimal_num_drones_no_wifi"
          disabled={!flightDomainId}
          error={Boolean(errors.nNoWifi)}
          helperText={errors.nNoWifi}
          InputProps={{
            inputProps: {
              min: 0,
              max: flightDomainData ? maxDrones(flightDomainData) : 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="No Wifi Drones"
          value={data.sub_optimal_num_drones_no_wifi}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />

        <FormControlLabel
          control={
            <Switch
              data-testid="c-dev-tools-fleet-simulation-drones-optimal"
              name="optimal"
              value={data.optimal}
              onChange={(e) => handleDataChange(e.target.name, e.target.checked)}
            />
          }
          label="Optimal"
          labelPlacement="end"
        />
      </Box>
    </Box>
  );
};
