import { useQueries } from '@tanstack/react-query';
import { QueryNames } from 'ts-types/QueryNames';
import { getReportService } from 'services/services';
import { useMemo } from 'react';
import { IReportBarcodeGetResponseItemST } from 'codegen/report';
import { useReportData } from './useReportData';
import { transformBarcodeDataMap } from '../utils/transformBarcodeDataMap';

export const useBarcodeData = ({ systemId, reportId }: { systemId: string; reportId: string }) => {
  const { reportSummaryData, isLoadingSummary } = useReportData({ systemId, reportId });

  const allQueries = useQueries({
    queries: Array.from({ length: reportSummaryData.numChunksBarcodes ?? 0 }, (_, pageIndex) => ({
      queryKey: [QueryNames.BARCODES_REPORT, systemId, reportId, pageIndex],
      queryFn: () => getReportService().getReportBarcodes(systemId, reportId, pageIndex.toString()),
      staleTime: Infinity,
      enabled: !isLoadingSummary && !!reportSummaryData,
    })),
  });

  const isLoading = allQueries.some((q) => q.isLoading);

  const barcodeDataMap = useMemo(() => {
    const reducedQueries: IReportBarcodeGetResponseItemST = allQueries.reduce((acc, result) => {
      if (result?.data?.data.item) {
        return { ...acc, ...result.data.data.item };
      }
      return acc;
    }, {});

    return transformBarcodeDataMap(reducedQueries);
  }, [allQueries]);

  return { barcodeDataMap, isLoadingBarcodes: isLoading || isLoadingSummary };
};
