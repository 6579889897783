import { Typography } from '@mui/material';

export const PageHeaderSectionTitle = ({
  title,
  currentFacilityName,
  isMultiFacility,
}: {
  title: string;
  currentFacilityName: string;
  isMultiFacility: boolean;
}) => (
  <Typography
    data-testid="c-page-header-title"
    color="secondary"
    variant="h1"
    lineHeight="inherit"
    fontSize="2.125rem"
    fontWeight="bold"
  >
    {isMultiFacility && `${currentFacilityName} - `}
    {title}
  </Typography>
);
