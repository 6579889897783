import { useSearchParams } from 'react-router-dom';
import { getAmendingRowsData, sortRows, getLocationListData } from 'common/Tables/tableFunctions';
import { REPORT_STATES } from 'common/reportStates';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { EnhancedTable } from 'components/EnhancedTable';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { LocationReportData } from 'udb/inventory/models/LocationReportData';
import { useFacilityModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { useEffect, useMemo, useState } from 'react';
import { LocationModal } from 'components/ModalsAndPopups/LocationModal/LocationModal';
import { CurrentTab } from '../../utils/GetTabData';

export const ReportLocationsGrid = ({
  reportId,
  refreshData,
  reportState,
  reportNeedsReview,
  getReportData,
  tabs,
  isLoading,
  currentActiveSpinner,
  currentTabData,
  activeTab,
  reviewLocationsTab,
  changeTab,
}: {
  activeTab: number;
  reviewLocationsTab: number;
  currentTabData: CurrentTab;
  changeTab: (tabValue: number) => void;
  reportId: string;
  tabs: {
    icon?: JSX.Element;
    label: string;
    show: boolean;
  }[];
  isLoading: number | boolean;
  currentActiveSpinner: Record<string, boolean>;
  reportState: string;
  reportNeedsReview: boolean;
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  refreshData?: { refreshData: () => void };
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('searchTerm') || '';
  const orderBy = (searchParams.get('orderBy') as keyof LocationReportData) || '';
  const order = (searchParams.get('order') as OrderValue) || '';

  const [openedLocationName, setOpenedLocationName] = useState<string>('');

  const { dispatchFacilityModals } = useFacilityModalsStore();

  const onRowClick = (e: KeyboardEvent, row: LocationReportData) => {
    if (reportNeedsReview && activeTab === reviewLocationsTab) {
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
        payload: getAmendingRowsData(sortRows(currentTabData.rows, order, orderBy), row, reportId),
        refreshData: {
          refreshData: () =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
        },
        tableAmending: true,
      });
      searchParams.set('amendLocation', row.location);
      setSearchParams(searchParams);
    } else if (reportState !== REPORT_STATES.DELETED) {
      searchParams.set('location', row.location);
      setSearchParams(searchParams);
    }
  };

  const { tableFor, tableTitle, tableSubtitle, headCells, rows } = currentTabData;

  const filteredLocationList = useMemo(
    () =>
      getLocationListData(
        filterOrderTableRows(
          currentTabData.rows,
          searchTerm,
          orderBy,
          order,
          currentTabData.headCells,
        ),
        openedLocationName,
      ),
    [currentTabData.rows, searchTerm, orderBy, order, currentTabData.headCells, openedLocationName],
  );

  useEffect(() => {
    const location = searchParams.get('location') || '';
    if (location && filteredLocationList?.length) {
      setOpenedLocationName(location);
    }
  }, [searchParams, filteredLocationList]);

  const isCurrentLocationInFilteredLocationList = useMemo(
    () => filteredLocationList.some((l) => l.location === openedLocationName),
    [filteredLocationList, openedLocationName],
  );

  return (
    <>
      <EnhancedTable
        reportId={reportId}
        tableFor={tableFor}
        tableTitle={tableTitle}
        tableSubtitle={tableSubtitle}
        headCells={headCells}
        refreshData={refreshData}
        rows={rows}
        tabs={tabs}
        tabStatus={true}
        changeTab={changeTab}
        startingTab={activeTab}
        isLoading={isLoading}
        currentActiveSpinner={currentActiveSpinner}
        onRowClick={onRowClick}
      />
      {openedLocationName && isCurrentLocationInFilteredLocationList && (
        <LocationModal
          closeModal={() => setOpenedLocationName('')}
          refreshTableData={() =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB)
          }
          refreshTableDataChunk={() => ({})}
          filteredLocationList={filteredLocationList}
          parentPage="Report"
          reportId={reportId}
          isLoadingBarcodes={false}
        />
      )}
    </>
  );
};
