import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const fullSizeImageModalDiagnosticsStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '1200px',
    minHeight: '680px',
  },
  noData: {
    textAlign: 'center',
    paddingTop: '320px',
  },
  cardHeader: {
    padding: theme.spacing(2),
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(clamp(0px, 250px, 100%), 1fr))',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  cardFullWidth: {
    gridColumn: '1 / -1',
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
  },
}));
