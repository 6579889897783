import { FormControl } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { getTimeIntervalDates, loadSinceInterval } from 'common/functions/dateTimeFunctions';
import { FilterDatesCommissioning } from 'delivery/features/commissioning/model/commissioningDateFilter.model';
import { LocalStore } from 'common/functions/storageFunctions';
import { intervalSelectorType } from './selectTimeInterval.model';
import { useSelectTimeIntervalStyles } from './SelectTimeInterval.style';
import { selectTimeIntervalOptions } from './selectTimeIntervalOptions.default';
import { DateFromSelector } from './dates-selector/DateFromSelector';
import { DateFromAndToSelector } from './dates-selector/DateFromAndToSelector';

// make week starts from "Monday"
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export const SelectTimeInterval = ({
  enableIntervalSelectors,
  defaultTimeInterval = 'all',
  getDataFrom,
}: {
  enableIntervalSelectors: boolean;
  defaultTimeInterval: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDataFrom?: (...args: any) => void;
}) => {
  const timezone = LocalStore.getTimezone();

  moment.tz.setDefault(timezone);

  const { classes } = useSelectTimeIntervalStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const [loadSince, setLoadSince] = useState<loadSinceInterval | intervalSelectorType>(
    (searchParams.get('loadSince') as loadSinceInterval) || defaultTimeInterval,
  );

  const storeChosenInterval = useCallback(
    ({ loadSince, from, until }: { loadSince: string; from: string; until: string }) => {
      searchParams.set('from', from);
      searchParams.set('until', until);
      searchParams.set('loadSince', loadSince);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleLoadSince = useCallback(
    (loadSince: loadSinceInterval | intervalSelectorType) => {
      setLoadSince(loadSince);

      // Prevent request for 'interval' and 'date' options
      if (loadSince !== 'by interval' && loadSince !== 'by date') {
        const dates = getTimeIntervalDates(loadSince) as FilterDatesCommissioning;
        getDataFrom?.(dates);
        storeChosenInterval({
          loadSince,
          from: dates?.from?.toString(),
          until: dates?.until?.toString(),
        });
      }
    },
    [getDataFrom, storeChosenInterval],
  );

  return (
    <>
      <FormControl className={classes.formControl}>
        <CustomSelect
          variant="standard"
          id="select-load-since-interval"
          customClasses={classes.selectInput}
          value={loadSince}
          disabled={!enableIntervalSelectors}
          onChange={(e) =>
            handleLoadSince(e.target.value as loadSinceInterval | intervalSelectorType)
          }
          valueOptions={selectTimeIntervalOptions}
          name=""
          error={false}
          errorMessage=""
          defaultValue=""
          testId="select-load-since-interval"
        />
      </FormControl>
      {loadSince === 'by interval' && (
        <DateFromAndToSelector
          enableIntervalSelectors={enableIntervalSelectors}
          getDataFrom={getDataFrom}
        />
      )}
      {loadSince === 'by date' && (
        <DateFromSelector
          enableIntervalSelectors={enableIntervalSelectors}
          getDataFrom={getDataFrom}
        />
      )}
    </>
  );
};
