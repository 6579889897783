import { Bin3DProps } from '../../bin3D/model/bin3DProps.model';

export const aisleSummaryDefaults: Record<
  Bin3DProps['status'],
  {
    label: string;
    borderColor: string;
    color: string;
  }
> = {
  ISSUE: {
    label: 'Issue',
    borderColor: 'hsla(4, 90%, 72%, 1)',
    color: 'hsla(4, 90%, 58%, 0.08)',
  },
  POTENTIAL_ISSUE: {
    label: 'Potential Issue',
    borderColor: 'hsla(36, 100%, 64%, 1)',
    color: 'hsla(27, 98%, 47%, 0.08)',
  },
  MATCH: {
    label: 'Match',
    borderColor: 'hsla(122, 39%, 63%, 1)',
    color: 'hsla(122, 39%, 49%, 0.08)',
  },
  EMPTY: {
    label: 'Empty',
    borderColor: 'hsla(0, 0%, 62%, 1)',
    color: 'hsla(0, 0%, 100%, 1)',
  },
  EXCLUDED: {
    label: 'Excluded',
    borderColor: 'hsla(0, 0%, 62%, 1)',
    color: 'hsla(0, 0%, 100%, 1)',
  },
  NOT_SCANNED: {
    label: 'Not Scanned',
    borderColor: 'hsla(0, 0%, 62%, 1)',
    color: 'hsla(0, 0%, 100%, 1)',
  },
  SNOOZED: {
    label: 'Snoozed',
    borderColor: 'hsla(207, 90%, 68%, 1)',
    color: 'hsla(207, 90%, 54%, 0.08)',
  },
};
