import { useMemo, useCallback } from 'react';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { NodeST } from 'shared/map-container/MapContainer.model';

export function useAisleNavigation({
  facilityMap,
  currentFlightDomainIndex,
  selectedAisleId,
  setSelectedAisle,
}: {
  facilityMap?: NodeST;
  currentFlightDomainIndex: number;
  selectedAisleId?: string;
  setSelectedAisle: (aisleId: string) => void;
}) {
  const currentFlightDomainNode = useMemo(
    () => facilityMap?.nodes?.[currentFlightDomainIndex] as NodeST,
    [facilityMap, currentFlightDomainIndex],
  );
  const aisleSideNodes = useMemo(() => {
    if (!currentFlightDomainNode) {
      return [];
    }
    return findNodesByType(currentFlightDomainNode, 'AISLE_SIDE').sort((a, b) => {
      const aName = a.display_name ?? '';
      const bName = b.display_name ?? '';
      return aName.localeCompare(bName, undefined, { numeric: true, sensitivity: 'base' });
    });
  }, [currentFlightDomainNode]);

  const navigateAisle = useCallback(
    (updateIndex: (currentIndex: number, total: number) => number) => {
      const currentIndex = aisleSideNodes.findIndex(
        (aisleSide) => aisleSide.id === selectedAisleId,
      );
      const total = aisleSideNodes.length;
      const updatedIndex = updateIndex(currentIndex, total);
      setSelectedAisle(aisleSideNodes[updatedIndex].id);
    },
    [aisleSideNodes, selectedAisleId, setSelectedAisle],
  );

  const handlePreviousAisle = useCallback(() => {
    navigateAisle((currentIndex, total) => (currentIndex - 1 + total) % total);
  }, [navigateAisle]);

  const handleNextAisle = useCallback(() => {
    navigateAisle((currentIndex, total) => (currentIndex + 1) % total);
  }, [navigateAisle]);

  return useMemo(
    () => ({
      handleNextAisle,
      handlePreviousAisle,
    }),
    [handleNextAisle, handlePreviousAisle],
  );
}
