import { useSearchParams } from 'react-router-dom';
import { ThreeEvent } from '@react-three/fiber';
import { useMemo, useCallback } from 'react';
import { Bin3DProps } from 'shared/map-3d/bin3D/model/bin3DProps.model';
import { traverseThreeObject } from '../map-node.util';

const ACTIVE_BIN_KEY = 'activeBinName';
export function useActiveBinSelection(aisleBins: Bin3DProps[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const aisleBinsWithHandlers = useMemo(
    () =>
      aisleBins.map((bin) => ({
        ...bin,
        onClick: (event: ThreeEvent<MouseEvent>) => {
          setSearchParams((previousParams) => {
            const params = new URLSearchParams(previousParams);
            const wrapper = traverseThreeObject(event.object, 'parent', (obj) => !!obj?.name);
            params.set(ACTIVE_BIN_KEY, wrapper?.name ?? '');
            return params;
          });
        },
      })),
    [aisleBins, setSearchParams],
  );

  const resetActiveBin = useCallback(() => {
    setSearchParams((previousParams) => {
      const params = new URLSearchParams(previousParams);
      params.delete(ACTIVE_BIN_KEY);
      return params;
    });
  }, [setSearchParams]);

  return useMemo(
    () => ({
      activeBinName: searchParams.get(ACTIVE_BIN_KEY) ?? '',
      aisleBinsWithHandlers,
      resetActiveBin,
    }),
    [aisleBinsWithHandlers, resetActiveBin, searchParams],
  );
}
