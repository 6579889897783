import { Typography, TextField, Grid, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dispatch, SetStateAction, useState } from 'react';
import Alert from '@mui/material/Alert';
import { WmsSimulatorEntry } from './types/WmsSimulatorEntry';

export const WmsSimulatorBarcodeSelector = ({
  index,
  wmsData,
  setWmsData,
}: {
  index: number;
  wmsData: WmsSimulatorEntry[];
  setWmsData: Dispatch<SetStateAction<WmsSimulatorEntry[]>>;
}) => {
  const [localBarcodes, setLocalBarcodes] = useState(wmsData[index].barcodes || []);

  const handleBarcodeChange = (barcodeIndex: number, value: string) => {
    const updatedBarcodes = [...localBarcodes];
    updatedBarcodes[barcodeIndex] = value;

    setLocalBarcodes(updatedBarcodes);

    const filteredBarcodes = updatedBarcodes.filter((barcode) => barcode.trim().length > 0);
    const updatedData = [...wmsData];
    updatedData[index].barcodes = filteredBarcodes;
    updatedData[index].number_of_barcodes = wmsData[index]?.number_of_barcodes;
    setWmsData(updatedData);
  };

  const handleBarcodeCountChange = (value: number) => {
    const newCount = Math.max(0, value);
    const updatedBarcodes = [...localBarcodes];

    if (newCount > updatedBarcodes.length) {
      for (let i = updatedBarcodes.length; i < newCount; i += 1) {
        updatedBarcodes.push('');
      }
    } else if (newCount < updatedBarcodes.length) {
      updatedBarcodes.splice(newCount);
    }

    setLocalBarcodes(updatedBarcodes);

    const filteredBarcodes = updatedBarcodes.filter((barcode) => barcode.trim().length > 0);
    const updatedData = [...wmsData];
    updatedData[index].number_of_barcodes = newCount;
    updatedData[index].barcodes = filteredBarcodes;
    setWmsData(updatedData);
  };

  const handleRemoveBarcode = (barcodeIndex: number) => {
    const updatedBarcodes = localBarcodes.filter((_, i) => i !== barcodeIndex);

    setLocalBarcodes(updatedBarcodes);

    const filteredBarcodes = updatedBarcodes.filter((barcode) => barcode.trim().length > 0);
    const updatedData = [...wmsData];
    updatedData[index].barcodes = filteredBarcodes;
    updatedData[index].number_of_barcodes = updatedBarcodes.length;
    setWmsData(updatedData);
  };

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={12} sm={6} container direction="column" alignItems="flex-start">
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Number of Barcodes
        </Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={wmsData[index]?.number_of_barcodes ?? 0}
          onChange={(e) => handleBarcodeCountChange(parseInt(e.target.value, 10) || 0)}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} container alignItems="center">
        <Alert
          severity={(wmsData[index]?.number_of_barcodes ?? 0) > 0 ? 'info' : 'warning'}
          sx={{ width: '100%', alignSelf: 'center' }}
        >
          <Typography variant="body2" color="textSecondary">
            {(wmsData[index]?.number_of_barcodes ?? 0) > 0
              ? 'If you leave a barcode empty, it will be automatically set by the backend.'
              : 'Adjust the Number of Barcodes field to add barcodes.'}
          </Typography>
        </Alert>
      </Grid>
      {(wmsData[index]?.number_of_barcodes ?? 0) > 0 ? (
        <Grid item xs={12} gap={20}>
          <Typography variant="subtitle2">Barcodes</Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {Array.from({ length: wmsData[index]?.number_of_barcodes || 0 }).map((_, i) => (
              <Box
                key={wmsData[index].id}
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: '8px',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: 'calc(50% - 16px)',
                    lg: 'calc(33% - 16px)',
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={localBarcodes[i] || ''}
                  onChange={(e) => handleBarcodeChange(i, e.target.value)}
                  placeholder="Barcode"
                />
                <IconButton
                  color="error"
                  onClick={() => handleRemoveBarcode(i)}
                  style={{ marginLeft: '0px', marginRight: '10px' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};
