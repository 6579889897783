import { useMemo } from 'react';
import { NodeST, Vec6 } from 'shared/map-container/MapContainer.model';
import { ILocationData1ST } from 'codegen/report';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { AisleSummaryProps } from 'shared/map-3d/aisle-summary/model/aisleSummaryProps.model';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { BinStatus } from '../../bin3D/model/bin3DProps.model';
import { getBinStatus } from '../utils/getBinStatus.util';

const SCALE_FACTOR = 0.95;

export function useAisleView({
  aisleSideNode,
  locationDataMap,
  currentLocationName,
}: {
  locationDataMap?: Map<string, ILocationData1ST>;
  aisleSideNode: NodeST | null;
  currentLocationName?: string;
}) {
  return useMemo(() => {
    if (!aisleSideNode) {
      return { aisleBins: [] };
    }

    if (locationDataMap?.size) {
      const bins = findNodesByType(aisleSideNode, 'BIN').map((bin) => ({
        ...bin,
        scale: vectorLike2Vector(bin.scale).multiplyScalar(SCALE_FACTOR),
        position: vectorLike2Vector(bin.position),
        normal: vectorLike2Vector(bin.normal),
        status: getBinStatus(locationDataMap.get(bin.name)),
        current: currentLocationName === bin.name,
      }));

      const summary = Object.entries(
        bins.reduce<Record<BinStatus, number>>(
          (acc, bin) => ({
            ...acc,
            [bin.status]: acc[bin.status] + 1,
          }),
          {
            ISSUE: 0,
            POTENTIAL_ISSUE: 0,
            SNOOZED: 0,
            MATCH: 0,
            EMPTY: 0,
            EXCLUDED: 0,
            NOT_SCANNED: 0,
          },
        ),
      ).map(([status, count]) => ({ status, count })) as AisleSummaryProps['issues'];

      return {
        aisleBox: aisleSideNode.box,
        aisleBins: bins,
        current: bins.find((bin) => bin.current),
        summary,
      };
    }
    return { aisleBins: [], aisleBox: [0, 0, 0, 0, 0, 0] as Vec6 };
    // locationDataMap is a Map, so we need to check its size to trigger the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aisleSideNode, locationDataMap, currentLocationName, locationDataMap?.size]);
}
