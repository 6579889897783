import { Metric } from '../../../../../components/Diagnostics/Metric';

type BatteryCyclesProps = {
  cycles?: number | null;
  maxRecommendedCycles?: number | null;
};

export const BatteryCycles = ({ cycles, maxRecommendedCycles }: BatteryCyclesProps) => {
  if (cycles && maxRecommendedCycles) {
    return (
      <Metric
        tooltip={`${cycles} / ${maxRecommendedCycles} cycles (${Math.round(
          (cycles / maxRecommendedCycles) * 100,
        )}%)`}
        value={`${cycles} / ${maxRecommendedCycles}`}
        unit="cycles"
      />
    );
  }

  return <>'battery cycles: n/a'</>;
};
