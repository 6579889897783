import { useState } from 'react';
import { IWmsSimulatorPostRequestST } from 'codegen/dev_tools';
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Delete from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import { useMutation } from '@tanstack/react-query';
import { MutationNames } from 'ts-types/MutationNames';
import { sendWmsSimulatorUpdate } from '../api/sendWmsSimulatorUpdate';
import { useWmsDataSimulatorStyles } from '../styles/WmsDataSimulator.styles';
import { WmsSimulatorDateSelector } from './WmsSimulatorDateSelector';
import { WmsSimulatorBarcodeSelector } from './WmsSimulatorBarcodeSelector';
import { WmsSimulatorExpectedStateSelector } from './WmsSimulatorExpectedStateSelector';
import { WmsSimulatorLocationLabelInput } from './WmsSimulatorLocationLabelInput';
import { WmsSimulatorClientInput } from './WmsSimulatorClientInput';
import { WmsSimulatorEntry } from './types/WmsSimulatorEntry';

interface WmsDataSimulatorProps {
  systemId: string;
  addToOutput: (messages: string[]) => void;
}

export const WmsDataSimulator = (props: WmsDataSimulatorProps) => {
  const { systemId, addToOutput } = props;
  const { classes } = useWmsDataSimulatorStyles();

  const [wmsData, setWmsData] = useState<WmsSimulatorEntry[]>([]);

  const { mutate: sendWmsSimulatorUpdateMutate } = useMutation({
    mutationKey: [MutationNames.SEND_WMS_SIMULATOR_UPDATE],
    mutationFn: ({
      wmsSimulatorPostRequest,
    }: {
      wmsSimulatorPostRequest: IWmsSimulatorPostRequestST;
    }) => sendWmsSimulatorUpdate(systemId, wmsSimulatorPostRequest),
    onSuccess: ({ data }) => {
      addToOutput(['::: WMS updated following locations:']);
      data.locations?.forEach((location) => {
        if (!data.skipped_locations?.includes(location.location ?? '')) {
          addToOutput([`   ${location.location}`]);
        }
      });
      addToOutput([':::']);
      if (data.skipped_locations && data.skipped_locations.length > 0) {
        addToOutput(['::: Skipped following locations:']);
        data.skipped_locations.forEach((location_label) => {
          addToOutput([`   ${location_label}`]);
        });
        addToOutput([':::']);
      }
      setWmsData([]);
    },
    onError: (error: Error) => {
      addToOutput(['::ERROR: WMS update failed', error.message]);
    },
  });

  const applyWmsEditsOnClick = () => {
    const wmsSimulatorPostRequest: IWmsSimulatorPostRequestST = {
      params: wmsData
        .filter((item) => item.location_label)
        .map(({ expanded, id, ...rest }) => rest),
    };

    sendWmsSimulatorUpdateMutate({ wmsSimulatorPostRequest });
  };

  const addWmsLocation = () => {
    setWmsData([
      ...wmsData.map((item) => ({ ...item, expanded: false })),
      {
        location_label: '',
        expected_state: 'BARCODE',
        number_of_barcodes: 1,
        barcodes: [],
        id: crypto.randomUUID(),
        expanded: true,
      },
    ]);
  };

  const removeWmsLocation = (index: number) => {
    const updatedData = wmsData.filter((_, i) => i !== index);
    setWmsData(updatedData);
  };

  const toggleCollapse = (index: number) => {
    const updatedData = wmsData.map((item, i) =>
      i === index ? { ...item, expanded: !item.expanded } : item,
    );
    setWmsData(updatedData);
  };

  const duplicateWmsLocation = (index: number) => {
    const entryToDuplicate = wmsData[index];
    const duplicatedEntry = {
      ...entryToDuplicate,
      location_label: '',
      id: crypto.randomUUID(),
      expanded: true,
    };

    setWmsData([...wmsData.map((item) => ({ ...item, expanded: false })), duplicatedEntry]);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Button
          variant="outlined"
          color="primary"
          onClick={addWmsLocation}
          className={classes.applyEditsButton}
          startIcon={<AddIcon />}
        >
          Add WMS Data
        </Button>
        <Button
          size="medium"
          variant="contained"
          disabled={wmsData.length === 0 || wmsData.some((item) => !item.location_label)}
          onClick={applyWmsEditsOnClick}
          className={classes.applyEditsButton}
          endIcon={<SendIcon />}
        >
          Apply Edits
        </Button>
      </div>
      <div className={classes.cardsContainer}>
        {wmsData.map((item, index) => (
          <div key={item.id}>
            <Accordion expanded={item.expanded}>
              <div className={classes.wmsDataAccordion}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => toggleCollapse(index)}
                >
                  {!item.expanded && !item.location_label && <ErrorIcon color="error" />}
                  <Typography>
                    {item.location_label || 'New Slot'}{' '}
                    {!item.expanded && (
                      <>
                        {' '}
                        - {item.expected_state} - {item.number_of_barcodes} Barcodes -{' '}
                        {item.wms_date}
                      </>
                    )}
                  </Typography>
                </AccordionSummary>
                <div>
                  <IconButton
                    className={classes.cardCloseIcon}
                    onClick={() => removeWmsLocation(index)}
                    size="small"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                  <IconButton
                    className={classes.contentCopyIcon}
                    onClick={() => duplicateWmsLocation(index)}
                    size="small"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
              <AccordionDetails>
                <Card className={classes.card}>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <WmsSimulatorLocationLabelInput
                          systemId={systemId}
                          locationlabel={item.location_label ?? ''}
                          index={index}
                          wmsData={wmsData}
                          setWmsData={setWmsData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <WmsSimulatorDateSelector
                          item={item}
                          index={index}
                          wmsData={wmsData}
                          setWmsData={setWmsData}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <WmsSimulatorExpectedStateSelector
                          index={index}
                          wmsData={wmsData}
                          setWmsData={setWmsData}
                          expectedState={item.expected_state ?? 'BARCODE'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <WmsSimulatorClientInput
                          item={item}
                          index={index}
                          wmsData={wmsData}
                          setWmsData={setWmsData}
                        />
                      </Grid>
                      {item.expected_state === 'BARCODE' && (
                        <Grid item xs={12}>
                          <WmsSimulatorBarcodeSelector
                            index={index}
                            wmsData={wmsData}
                            setWmsData={setWmsData}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};
