import { ILocationDataST } from 'codegen/warehouse_status';
import { BarcodeDataMap } from '../../../models/BarcodeView.model';

export const getWasFoundAt = (locationData: ILocationDataST, barcodeDataMap?: BarcodeDataMap) => {
  const verityBarcodes =
    (typeof locationData.verity_status !== 'string' && locationData.verity_status?.barcodes) || [];
  const wmsBarcodes =
    (typeof locationData.wms_status !== 'string' && locationData.wms_status?.barcodes) || [];

  return barcodeDataMap
    ? [
        ...verityBarcodes.flatMap((bc) => barcodeDataMap[bc]?.found_at),
        ...wmsBarcodes.flatMap((bc) => barcodeDataMap[bc]?.found_at),
      ]
        .filter(Boolean)
        .filter((slotLabel) => slotLabel !== locationData.slot_label)
        .join(', ')
    : '';
};
