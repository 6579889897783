import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { HEADER_HEIGHT } from 'components/Header/defaults/Header.defaults';

export const useStyles = makeStyles()((theme: Theme) => ({
  consoleWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content minmax(0, 1fr)',
    gap: '16px',
  },
  consoleWrapperTitle: {
    margin: 0,
  },
  consoleOutput: {
    overflow: 'auto',
    padding: 16,
    fontSize: 12,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  consoleOutputEntry: {
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  drawer: {
    padding: '16px',
    marginTop: HEADER_HEIGHT,
  },
  drawerContent: {
    flexGrow: 1,
    bgcolor: 'background.paper',
    display: 'flex',
    marginTop: 2,
  },
  drawerMenu: {
    flex: '0 0 auto',
    width: '250px',
  },
  tabList: {
    borderRight: 1,
    borderColor: 'divider',
    overflow: 'visible',
  },
  tabPanelWrapper: {
    flex: '1 1 auto',
    minWidth: 0,
  },
  tabPanel: {
    padding: '0px 16px 16px 0px',
  },
  clearBtn: {
    float: 'right',
  },
}));
