import React, { ReactNode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FacilityLevelStoreProvider } from './store/FacilityLevelStore/facilityLevelStore';
import { UserLevelStoreProvider } from './store/UserLevelStore/userLevelStore';
import { ClientLevelStoreProvider } from './store/ClientLevelStore/clientLevelStore';
import { ClientModalsStoreProvider } from './store/Modals/clientModals/clientModalsStore';
import { FacilityMenuStoreProvider } from './store/FacilityMenuStore/facilityMenuStore';
import { FacilityModalsStoreProvider } from './store/Modals/facilityModals/facilityModalsStore';
import { GroundControlStoreProvider } from './store/GroundControl/groundControlLevelStore';
import { GroundControlModalsStoreProvider } from './store/Modals/groundControlModals/groundControlModalsStore';
import { SettingsContextProvider } from './store/Settings/settingsStore';
import { MapStoreProvider } from './shared/map-container/reducer/3DmapStore';
import { customTheme } from './customTheme';
import './ReactToastifyCustom.css';
import 'react-toastify/dist/ReactToastify.css';

const AUTO_CLOSE_TIMEOUT = 1000 * 5;

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools').then((module) => ({
    default: module.ReactQueryDevtools,
  })),
);

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const Providers = ({ children }: { children: ReactNode }) => {
  const isDevelopmentEnvironment = import.meta.env.DEV && import.meta.env.NODE_ENV !== 'test';

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={AUTO_CLOSE_TIMEOUT}
        theme="colored"
        hideProgressBar
        className="toastBody"
      />
      <BrowserRouter>
        <CacheProvider value={muiCache}>
          <MuiThemeProvider theme={customTheme}>
            <QueryClientProvider client={queryClient}>
              {isDevelopmentEnvironment && (
                <Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction
                    initialIsOpen={false}
                    buttonPosition="bottom-left"
                  />
                </Suspense>
              )}

              <UserLevelStoreProvider>
                <ClientLevelStoreProvider>
                  <FacilityLevelStoreProvider>
                    <SettingsContextProvider>
                      <ClientModalsStoreProvider>
                        <FacilityMenuStoreProvider>
                          <FacilityModalsStoreProvider>
                            <MapStoreProvider>
                              <GroundControlStoreProvider>
                                <GroundControlModalsStoreProvider>
                                  {children}
                                </GroundControlModalsStoreProvider>
                              </GroundControlStoreProvider>
                            </MapStoreProvider>
                          </FacilityModalsStoreProvider>
                        </FacilityMenuStoreProvider>
                      </ClientModalsStoreProvider>
                    </SettingsContextProvider>
                  </FacilityLevelStoreProvider>
                </ClientLevelStoreProvider>
              </UserLevelStoreProvider>
            </QueryClientProvider>
          </MuiThemeProvider>
        </CacheProvider>
      </BrowserRouter>
    </>
  );
};
