import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import isNumber from 'lodash/isNumber';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import {
  DELIVERY_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
  INVENTORY_PAGES_URLS,
  REMOTE_PICKING_LOCATIONS,
  WARNING_PAGES_URLS,
} from 'common/pages';
import { Reports } from 'udb/inventory/features/reports/Reports';
import { ReportContainerLegacy } from 'udb/inventory/features/reports/features/report/ReportContainerLegacy';
import { ReportScheduler } from 'udb/inventory/features/reports/features/report-scheduler/ReportScheduler';
import { DroneZonePageContext } from 'udb/ground-control/drone-zones/reducer/DroneZonePageContext';
import { DroneZonesPage as DeliveryDroneZonesPage } from 'delivery/features/drone-zones/DroneZonePage';
import { Locations } from 'udb/inventory/features/locations-management/Locations';
import { WarehouseStatus3D } from 'udb/inventory/features/warehouse-status-3d/WarehouseStatus3D';
import { ItemView } from 'udb/inventory/features/warehouse-status/features/itemView/ItemView';
import { ReportContainer } from 'udb/inventory/features/reports/features/report/ReportContainer';
import { NoFlyZonePageContext } from 'udb/ground-control/no-fly-zones/reducer/NoFlyZonePageContext';
import { NoFlyZonesPage } from 'udb/ground-control/no-fly-zones/NoFlyZonesPage';
import { WarehouseStatus } from 'udb/inventory/features/warehouse-status/WarehouseStatus';
import { WarehouseStatus3DLayout } from 'udb/inventory/features/warehouse-status-3d/WarehouseStatus3DLayout';
import { ReportItemView } from 'udb/inventory/features/reports/features/report/features/report-item-view/ReportItemView';
import { Home } from '../../pages/Home/Home';
import { WarehouseStatusLegacy } from '../../udb/inventory/features/warehouse-status-legacy/WarehouseStatusLegacy';
import { WarehouseDataUploads } from '../../pages/WarehouseDataUploads/WarehouseDataUploads';
import { SetMfaPreference } from '../../pages/Auth/SetMfaPreference';
import { Admin } from '../Admin/Admin';
import { NoFacilityWarning } from '../../pages/NoFacilityWarning/NoFacilityWarning';
import { NotFound } from '../../pages/Error/NotFound';
import { GroundControlPage } from '../../pages/GroundControl/GroundControlPage';
import { Overview } from '../../pages/GroundControl/pages/FlightDomainOverview/FlightDomainOverview';
import { FleetPage } from '../../pages/GroundControl/pages/FleetOverview/FleetPage';
import { DroneZonesPage } from '../../udb/ground-control/drone-zones/DroneZonesPage';
import { Schedule } from '../../udb/ground-control/schedule/Schedule';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { Analytics } from '../../pages/Analytics/Analytics';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../features/permissions/userHasPermission';
import {
  overviewPagePermissions,
  PERMISSION,
  reportsPermissions,
} from '../../features/permissions/permissions.model';
import { CommissioningPage } from '../../delivery/features/commissioning/CommissioningPage';
import { Fleet } from '../../delivery/features/fleet/Fleet';
import { RemotePickingLocationsRoute } from './remote-picking-locations-route/RemotePickingLocationsRoute';
import { RemotePickingLocationsOutletProvider } from './remote-picking-locations-route/features/remote-picking-locations-outlet-provider/RemotePickingLocationsOutletProvider';
import { RedirectReport } from './redirect-report/RedirectReport';

const RootWarehouseStatus3D = () => <Navigate to="0" replace />;

export const FacilityRoutes = () => {
  const { systemId } = useParams();
  const location = useLocation();
  const logLocationPrefix = getLogPrefixForType('PAGE', location.pathname);
  const logPrefix = getLogPrefixForType('COMPONENT', 'FacilityRoutes', logLocationPrefix);

  const { stateFacilityLevel } = useFacilityLevelStore();

  const isSystemIdInvalid = systemId && !isNumber(parseInt(systemId, 10));

  if (isSystemIdInvalid) {
    console.warn(logPrefix, 'page invoked with invalid system ID, redirect to Not Found');
    return <Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />;
  }

  const showDeliveryRoutes =
    stateFacilityLevel.facilitySettings?.enable_csfa_task_request_result &&
    userHasPermission(PERMISSION.DELIVERY_MANAGEMENT);

  const areReportsRoutesAccessible = userHasSomePermissions(reportsPermissions);

  return (
    <Routes>
      {showDeliveryRoutes && (
        <>
          <Route
            path={`${DELIVERY_PAGES_URLS().INDEX}/:flightDomainId/fleet/*`}
            element={<Fleet />}
          />

          <Route
            path={`${DELIVERY_PAGES_URLS().INDEX}/:flightDomainId/commissioning/*`}
            element={<CommissioningPage />}
          />

          <Route
            path={`${DELIVERY_PAGES_URLS().INDEX}/:flightDomainId/drone-zones/*`}
            element={
              <ProtectedRoute
                isAccessible={
                  !!stateFacilityLevel.facilitySettings?.show_coexistence_map &&
                  userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT)
                }
              >
                <DeliveryDroneZonesPage />
              </ProtectedRoute>
            }
          />
        </>
      )}
      {/* INVENTORY PAGES */}
      <Route path={WARNING_PAGES_URLS.NO_FACILITY} element={<NoFacilityWarning />} />
      <Route
        path={INVENTORY_PAGES_URLS.OVERVIEW}
        element={
          <ProtectedRoute isAccessible={userHasSomePermissions(overviewPagePermissions)}>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS)} />
        }
      >
        <Route element={<WarehouseStatus3DLayout />}>
          <Route
            path={INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_MAP}
            element={<RootWarehouseStatus3D />}
          />
          <Route
            path={`${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_MAP}/:tabIndex`}
            element={<WarehouseStatus3D />}
          />
        </Route>
        <Route path={INVENTORY_PAGES_URLS.WAREHOUSE_STATUS} element={<WarehouseStatusLegacy />} />
        <Route path={INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X} element={<WarehouseStatus />} />
        <Route path={INVENTORY_PAGES_URLS.ITEM_VIEW} element={<ItemView />} />
      </Route>

      <Route path={INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE} element={<SetMfaPreference />} />
      <Route
        path={INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS}
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.WMS_MANAGEMENT)}>
            <WarehouseDataUploads />
          </ProtectedRoute>
        }
      />
      <Route
        path={INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT}
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.VIEW_LOCATION_STATUS)}>
            <Locations />
          </ProtectedRoute>
        }
      />
      <Route
        path={INVENTORY_PAGES_URLS.ANALYTICS}
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.VIEW_ANALYTICS)}>
            <Analytics />
          </ProtectedRoute>
        }
      />

      <Route
        path={INVENTORY_PAGES_URLS.REPORTS}
        element={
          <ProtectedRoute isAccessible={areReportsRoutesAccessible}>
            <Reports />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${INVENTORY_PAGES_URLS.REPORTS}/:reportId`}
        element={
          <ProtectedRoute isAccessible={areReportsRoutesAccessible}>
            {stateFacilityLevel?.facilitySettings?.enable_experimental_report_grid ? (
              <ReportContainer />
            ) : (
              <ReportContainerLegacy />
            )}
          </ProtectedRoute>
        }
      />

      <Route
        path={`${INVENTORY_PAGES_URLS.REPORTS_ITEM_VIEW}/:reportId`}
        element={
          <ProtectedRoute isAccessible={areReportsRoutesAccessible}>
            <ReportItemView />
          </ProtectedRoute>
        }
      />

      {/* Deprecated route as reports are now a continuation of reports url. */}
      <Route path="/inventory/report/:reportId" element={<RedirectReport />} />

      <Route element={<RemotePickingLocationsOutletProvider />}>
        <Route path={`${REMOTE_PICKING_LOCATIONS}/*`} element={<RemotePickingLocationsRoute />} />
      </Route>

      <Route
        path={INVENTORY_PAGES_URLS.SCHEDULING}
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT)}>
            <ReportScheduler />
          </ProtectedRoute>
        }
      />

      <Route path={`${FACILITY_SETTINGS_PAGES_URLS.ROOT}/*`} element={<Admin />} />

      {/* GROUND CONTROL PAGES */}
      {stateFacilityLevel.facilitySettings?.show_ground_control_app && (
        <Route
          path={`${GROUND_CONTROL_PAGES_URLS().INDEX}/:flightDomainId/*`}
          element={<GroundControlPage />}
        >
          <Route index element={<Overview />} />
          <Route path="fleet" element={<FleetPage />} />
          {stateFacilityLevel.facilitySettings.show_coexistence_map ? (
            <Route
              path="drone-zones"
              element={
                <ProtectedRoute isAccessible={userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT)}>
                  <DroneZonePageContext>
                    <DroneZonesPage />
                  </DroneZonePageContext>
                </ProtectedRoute>
              }
            />
          ) : (
            <Route
              path="no-fly-zones"
              element={
                <ProtectedRoute isAccessible={userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT)}>
                  <NoFlyZonePageContext>
                    <NoFlyZonesPage />
                  </NoFlyZonePageContext>
                </ProtectedRoute>
              }
            />
          )}

          <Route
            path={
              stateFacilityLevel.facilitySettings?.show_coexistence_map
                ? 'drone-zones'
                : 'no-fly-zones'
            }
            element={
              <ProtectedRoute
                isAccessible={
                  stateFacilityLevel.facilitySettings?.show_coexistence_map
                    ? userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT)
                    : userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT)
                }
              >
                <DroneZonePageContext>
                  <DroneZonesPage />
                </DroneZonePageContext>
              </ProtectedRoute>
            }
          />

          <Route path="building-schedule" element={<Schedule />} />
          <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
        </Route>
      )}
      {/* NOT FOUND PAGES */}
      <Route path={WARNING_PAGES_URLS.NOT_FOUND} element={<NotFound />} />
      <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
    </Routes>
  );
};
