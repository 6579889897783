import { IReportBarcodeGetResponseItemST, ISlotIDTypeDefST } from 'codegen/report';
import { BarcodeDataMap } from 'udb/inventory/models/BarcodeView.model';

export const transformBarcodeDataMap = (reportBarcodeResponse: IReportBarcodeGetResponseItemST) => {
  const barcodeDataMap: BarcodeDataMap = {};

  Object.keys(reportBarcodeResponse).forEach((key) => {
    const value: ISlotIDTypeDefST[] = reportBarcodeResponse[key];

    barcodeDataMap[key] = {
      id: key,
      found_at: value.filter((v) => v.type === 'VERITY').map((v) => v.label),
      expected_at: value.filter((v) => v.type === 'WMS').map((v) => v.label),
    };
  });

  return barcodeDataMap;
};
