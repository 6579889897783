import BlockIcon from '@mui/icons-material/Block';
import { Tooltip } from '@mui/material';
import {
  GridColDef,
  GridTreeNode,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridStringOperators,
  getGridNumericOperators,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { ILocationDataST } from 'codegen/warehouse_status';
import { AllIssueTypes } from 'common/functions/issues/issueColorFunctions';
import {
  ContentExpectedCell,
  ContentFoundCell,
  ExclusionStatusCell,
  ISSUE_STATE,
  IssueCell,
  IssueStateCell,
} from 'udb/inventory/features/premium-grid/features/cell';
import { ContentWasFoundAtCell } from 'udb/inventory/features/premium-grid/features/cell/ContentWasFoundAtCell';

import { WmsQuantityCell } from 'udb/inventory/features/premium-grid/features/cell/WmsQuantityCell';
import { ContentShouldBeAtCell } from 'udb/inventory/features/premium-grid/features/cell/ContentShouldBeAtCell';
import { isNotAnyOfOperator } from 'udb/inventory/features/premium-grid/utils/custom-filters/isNotAnyOfOperator';
import { notContainsOperator } from 'udb/inventory/features/premium-grid/utils/custom-filters/notContainsOperator';
import {
  collectionDelayValueGetter,
  contentFoundValueGetter,
  customerValueGetter,
  dateTimeValueFormatter,
  exclusionStatusValueGetter,
  expectedBarcodesValueGetter,
  expectedContentValueGetter,
  firstFoundOnGroupingValueGetter,
  firstFoundOnValueGetter,
  foundBarcodesValueGetter,
  foundDateGroupingValueGetter,
  foundDateValueGetter,
  issueStatusValueGetter,
  issueValueGetter,
  matchingBarcodesPercentageValueGetter,
  matchingBarcodesValueGetter,
  missingBarcodesPercentageValueGetter,
  missingBarcodesValueGetter,
  shouldBeAtValueGetter,
  unknownBarcodesPercentageValueGetter,
  unknownBarcodesValueGetter,
  wasFoundAtValueGetter,
  wmsArticleNumberValueGetter,
  wmsDateGroupingValueGetter,
  wmsDateValueGetter,
  wmsQuantityValueGetter,
} from 'udb/inventory/features/premium-grid/utils/cell-value-getters/WHSCellValueGetters';
import { amendedValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/amendedValueGetter';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { TooltipedCell } from '../../premium-grid/features/cell/TooltipedCell';
import { IsNotSelectedFilterOperator } from '../../premium-grid/utils/custom-filters/IsNotSelectedFilterOperator';
import { IsSelectedFilterOperator } from '../../premium-grid/utils/custom-filters/IsSelectedFilterOperator';
import { labelValueGetter } from '../../premium-grid/utils/cell-value-getters/labelValueGetter';
import { BarcodeDataMap } from '../../../models/BarcodeView.model';

const MINIMUM_COLUMN_WIDTH = 170;

// The render cell function should only return true when its a leaf node and not a group node
// or when its the autogenerated expandable group column
const isNotGroupCell = (field: string, rowNode: GridTreeNode): boolean =>
  !(rowNode.type === 'group' && !field.startsWith('__row_group'));

const customGridStringOperators = getGridStringOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const customGridDateOperators = getGridDateOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const specColumns = (
  facilitySettings: IFacilitySettingsST,
  locationLabelOptions: ValueOptions[],
  issueValueOptions: ValueOptions[],
  issueStatusOptions: ValueOptions[],
  isLoadingBarcodes: boolean,
  barcodeDataMap?: BarcodeDataMap,
): GridColDef<ILocationDataST>[] => [
  {
    headerName: 'Location',
    field: 'slot_label',
    groupable: false,
    valueGetter: (value, row) => row.slot_label || '-',
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <TooltipedCell value={params.value} />,
  },
  ...(facilitySettings.show_location_labels
    ? ([
        {
          headerName: 'Label',
          field: 'location_label',
          type: 'singleSelect',
          valueOptions: locationLabelOptions,
          valueGetter: (_value, row) => labelValueGetter(row),
          renderCell: (params) => <TooltipedCell value={params.value} />,
          filterOperators: [
            IsSelectedFilterOperator,
            IsNotSelectedFilterOperator,
            ...customGridStringOperators,
            notContainsOperator,
            ...getGridStringOperators(),
          ],
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    renderHeader: () => (
      <Tooltip title="Excluded locations will not be visited by drones">
        <BlockIcon />
      </Tooltip>
    ),
    headerAlign: 'center',
    headerName: 'Exclusion status',
    field: 'exclusion_status',
    minWidth: 70,
    groupable: true,
    type: 'boolean',
    valueGetter: (_value, row) => exclusionStatusValueGetter(row),
    groupingValueGetter: (_value, row) => exclusionStatusValueGetter(row),
    renderCell: (params) => <ExclusionStatusCell row={params.row} />,
    filterOperators: getGridBooleanOperators(),
  },
  {
    headerName: 'Expected content',
    field: 'wms_status.state',
    groupable: false,
    filterOperators: [...getGridStringOperators(), notContainsOperator, isNotAnyOfOperator],
    valueGetter: (_value, row) => expectedContentValueGetter(row),
    renderCell: (params) => (
      <ContentExpectedCell facilitySettings={facilitySettings} locationData={params.row} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Was found at',
          field: 'wasFoundAt',
          groupable: false,
          valueGetter: (_value, row) => wasFoundAtValueGetter(row, barcodeDataMap),
          renderCell: (params) => (
            <ContentWasFoundAtCell
              row={params.row}
              isLoadingBarcodes={isLoadingBarcodes}
              barcodeDataMap={barcodeDataMap}
            />
          ),
        },
      ] as GridColDef<ILocationDataST>[])),
  {
    headerName: 'WMS article nr.',
    field: 'wms_status.article_nos.0',
    groupable: false,
    valueGetter: (_value, row) => wmsArticleNumberValueGetter(row),
    groupingValueGetter: (_value, row) => wmsArticleNumberValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
  },
  {
    headerName: 'WMS quantity',
    field: 'wms_status.qtys.0',
    groupable: false,
    valueGetter: (_value, row) => wmsQuantityValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <WmsQuantityCell row={params.row} />,
  },
  ...(facilitySettings.show_customer
    ? ([
        {
          headerName: 'Customer',
          field: 'wms_status.customers.0',
          groupable: true,
          width: 50,
          valueGetter: (_value, row) => customerValueGetter(row),
          groupingValueGetter: (_value, row) => customerValueGetter(row),
          renderCell: (params) =>
            isNotGroupCell(params.field, params.rowNode) && (
              <TooltipedCell value={params.formattedValue} />
            ),
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    headerName: 'WMS date',
    field: 'wms_status.changed_at',
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => wmsDateValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => wmsDateGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Content found',
    field: 'verity_status.state',
    groupable: false,
    filterOperators: [...getGridStringOperators(), notContainsOperator, isNotAnyOfOperator],
    valueGetter: (_value, row) => contentFoundValueGetter(row),
    renderCell: (params) => (
      <ContentFoundCell locationData={params.row} facilitySettings={facilitySettings} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Should be at',
          field: 'shouldBeAt',
          groupable: false,
          valueGetter: (_value, row) => shouldBeAtValueGetter(row, barcodeDataMap),
          renderCell: (params) => (
            <ContentShouldBeAtCell
              row={params.row}
              isLoadingBarcodes={isLoadingBarcodes}
              barcodeDataMap={barcodeDataMap}
            />
          ),
        },
      ] as GridColDef<ILocationDataST>[])),
  ...(userHasPermission(PERMISSION.AMEND_REPORT) && facilitySettings?.allow_user_amend
    ? ([
        {
          headerName: 'Amended',
          field: 'content_found_amended',
          groupable: true,
          valueGetter: (_value, row) => amendedValueGetter(row),
          filterOperators: getGridBooleanOperators(),
          renderCell: (params) => (params.value ? 'yes' : ''),
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    headerName: 'Expected Barcodes',
    field: 'expected_barcodes',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => expectedBarcodesValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Found Barcodes',
    field: 'found_barcodes',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => foundBarcodesValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Missing Barcodes',
    field: 'missing_barcodes',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => missingBarcodesValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Missing Barcodes rate',
    field: 'missing_barcodes_rate',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => missingBarcodesPercentageValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Unexpected Barcodes',
    field: 'unexpected_barcodes',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => unknownBarcodesValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Unexpected Barcodes rate',
    field: 'unexpected_barcodes_rate',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => unknownBarcodesPercentageValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Matching Barcodes',
    field: 'matching_barcodes',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => matchingBarcodesValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Matching Barcodes rate',
    field: 'matching_barcodes_rate',
    groupable: false,
    type: 'number',
    filterOperators: getGridNumericOperators(),
    valueGetter: (_value, row) => matchingBarcodesPercentageValueGetter(row),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'Found date',
    field: 'verity_status.collected_at',
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => foundDateValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => foundDateGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: getGridDateOperators(),
  },
  {
    headerName: 'Collection Delay',
    field: 'collection_delay',
    type: 'number',
    groupable: false,
    filterable: true,
    valueGetter: (_value, row) => collectionDelayValueGetter(row),
    description: 'time elapsed between the latest WMS update and the (latest) drone visit',
    filterOperators: getGridNumericOperators(),
    renderCell: (params) => <TooltipedCell value={params.value} />,
  },
  {
    headerName: 'First found on',
    field: 'issues.0.first_found_on',
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => firstFoundOnValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => firstFoundOnGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Issue',
    field: 'issues.0.type',
    align: 'left',
    type: 'singleSelect',
    valueOptions: issueValueOptions,
    valueGetter: (value, row) => issueValueGetter(value, row),
    groupingValueGetter: (value, row) => issueValueGetter(value, row),
    filterOperators: [
      IsSelectedFilterOperator,
      IsNotSelectedFilterOperator,
      ...customGridStringOperators,
      notContainsOperator,
    ],
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueCell issueType={(params.value as AllIssueTypes) || '-'} />
      ),
  },
  {
    headerName: 'Issue status',
    field: 'issues.0.state',
    type: 'singleSelect',
    valueOptions: issueStatusOptions,
    align: 'left',
    valueGetter: (_value, row) => issueStatusValueGetter(row),
    groupingValueGetter: (_value, row) => issueStatusValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueStateCell state={params.value as ISSUE_STATE} />
      ),
    filterOperators: [
      IsSelectedFilterOperator,
      IsNotSelectedFilterOperator,
      ...customGridStringOperators,
      notContainsOperator,
    ],
  },
];

export const getColumns = (
  facilitySettings: IFacilitySettingsST,
  locationLabelOptions: ValueOptions[],
  issueValueOptions: ValueOptions[],
  issueStatusOptions: ValueOptions[],
  isLoadingBarcodes: boolean,
  barcodeDataMap?: BarcodeDataMap,
): GridColDef[] =>
  specColumns(
    facilitySettings,
    locationLabelOptions,
    issueValueOptions,
    issueStatusOptions,
    isLoadingBarcodes,
    barcodeDataMap,
  ).map((col) => ({
    align: 'left',
    ...col,
    aggregable: false,
    headerClassName: 'datagrid-header',
    flex: 1,
    filterOperators: col.filterOperators ?? customGridStringOperators,
    minWidth: col.minWidth ?? MINIMUM_COLUMN_WIDTH,
  }));
