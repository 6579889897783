import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useDateFromAndToSelectorStyles = makeStyles()((theme: Theme) => ({
  datepicker: {
    marginLeft: '10px',
    width: '140px',
    '& .MuiInputBase-formControl': {
      borderRadius: '0 !important',
    },
    '& .MuiInputBase-formControl input': {
      padding: '0 0 7px',
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiOutlinedInput-root > .MuiInputAdornment-root > button': {
      marginTop: '-8px',
    },
  },
  intervalSubmitButton: {
    padding: '2px !important',
    marginLeft: theme.spacing(2),
  },
}));
