import { Box, TextField, Button } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DATETIME_FORMAT, PICKER_SHORT_DATETIME_FORMAT } from 'common/datetimeFormats';
import { useDateFromAndToSelectorStyles } from './DateSelector.styles';
import { isValidInterval } from './utils/isValidInterval';

export const DateFromAndToSelector = ({
  enableIntervalSelectors,
  getDataFrom,
}: {
  enableIntervalSelectors: boolean;
  getDataFrom?: (dates: { from: string; until: string }) => void;
}) => {
  const { classes } = useDateFromAndToSelectorStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [chosenIntervalFrom, setChosenIntervalFrom] = useState(
    searchParams.get('from') || moment().startOf('day').format(DATETIME_FORMAT),
  );
  const [chosenIntervalUntil, setChosenIntervalUntil] = useState(
    searchParams.get('until') || moment().endOf('day').format(DATETIME_FORMAT),
  );

  const submitInterval = useCallback(() => {
    const dates = {
      from: moment.utc(chosenIntervalFrom).format(DATETIME_FORMAT),
      until: moment.utc(chosenIntervalUntil).format(DATETIME_FORMAT),
    };
    getDataFrom?.(dates);
    searchParams.set('from', dates.from);
    searchParams.set('until', dates.until);
    searchParams.set('loadSince', 'by interval');
    setSearchParams(searchParams);
  }, [chosenIntervalFrom, chosenIntervalUntil, getDataFrom, searchParams, setSearchParams]);

  return (
    <Box component="div">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className={classes.datepicker}
          inputFormat={PICKER_SHORT_DATETIME_FORMAT}
          mask="____/__/__"
          disableFuture={true}
          value={chosenIntervalFrom}
          onChange={(e) => setChosenIntervalFrom(e as string)}
          renderInput={(params) => <TextField className={classes.datepicker} {...params} />}
          disabled={!enableIntervalSelectors}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className={classes.datepicker}
          inputFormat={PICKER_SHORT_DATETIME_FORMAT}
          mask="____/__/__"
          disableFuture={true}
          value={chosenIntervalUntil}
          onChange={(e) => setChosenIntervalUntil(e as string)}
          renderInput={(params) => <TextField className={classes.datepicker} {...params} />}
          disabled={!enableIntervalSelectors}
        />
      </LocalizationProvider>
      <Button
        className={classes.intervalSubmitButton}
        variant="outlined"
        color="primary"
        onClick={() => submitInterval()}
        disabled={
          !isValidInterval(chosenIntervalFrom, chosenIntervalUntil) || !enableIntervalSelectors
        }
      >
        Go
      </Button>
    </Box>
  );
};
