import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import { IClientLevelStateFacility } from 'store/ClientLevelStore/ClientLevelStore.model';
import { InventoryActionNames } from '../../store/FacilityLevelStore/facilityLevelActions';
import { INVENTORY_PAGES_URLS } from '../pages';
import { REPORT_STATES } from '../reportStates';

type dispatchAction = {
  type: InventoryActionNames;
  payload: Record<string, string>;
};

type reportData = {
  report_id: string;
  report_name: string;
  report_state: REPORT_STATES;
  message: string;
};

interface IReportNotifications {
  systemId: string;
  data: reportData;
  facility?: IClientLevelStateFacility;
  dispatch: (params: dispatchAction) => void;
}

export const reportNotifications = ({
  systemId,
  data,
  facility,
  dispatch,
}: IReportNotifications) => {
  const reportId = data?.report_id;
  const reportName = data?.report_name;
  const state = data?.report_state;
  const message = data?.message;
  const { pathname } = window.location;

  if (!isEmpty(facility)) {
    const reportRoute = `/${systemId}${INVENTORY_PAGES_URLS.REPORTS}/${reportId}`;
    const locationsMatches = [
      INVENTORY_PAGES_URLS.OVERVIEW,
      INVENTORY_PAGES_URLS.REPORTS,
      reportRoute,
    ].includes(pathname);

    const reportLink = (
      <div
        className="notification-link"
        role="link"
        tabIndex={0}
        onClick={() => window.location.assign(reportRoute)}
      >
        View report
      </div>
    );

    const refreshLink = (
      <div
        className="notification-link"
        role="button"
        tabIndex={0}
        onClick={() => window.location.reload()}
      >
        Refresh the page.
      </div>
    );

    const facilityName = facility?.name ? ` in ${facility?.name}` : '';

    if (state === REPORT_STATES.ERROR) {
      toast(message, { type: 'error', toastId: 'reportsError' });
    }

    if (state === REPORT_STATES.SCHEDULED) {
      const msg = (
        <div>
          The new report "{reportName}" has started{facilityName}.{' '}
          {reportRoute !== pathname && reportLink}
        </div>
      );

      toast(msg, {
        type: 'info',
        toastId: 'reportsInfo',
      });
    }

    if (state === REPORT_STATES.FINISHED) {
      const msg = (
        <div>
          The report "{reportName}" has been completed{facilityName}.{' '}
          <div style={{ display: 'flex' }}>
            {reportRoute !== pathname && reportLink} {locationsMatches && refreshLink}
          </div>
        </div>
      );

      toast(msg, {
        type: 'success',
        toastId: 'reportsSuccess',
      });
    }
    dispatch({
      type: InventoryActionNames.WS_REPORT_STATUS_UPDATE,
      payload: {},
    });
  }
};
