import { Bin3DContainer } from 'shared/map-3d/bin3D/Bin3D';

import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { findNodesByType, traverseThreeObject } from 'shared/map-3d/map-node.util';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { ThreeEvent } from '@react-three/fiber';
import { ActiveAisle } from '../active-aisle/ActiveAisle';
import { useRackTops } from './hooks/useRackTops';
import { TopViewMapProps, HitTargetNodeST } from './TopViewMap.model';

const HIT_TARGET_HEIGHT = 0.1;

function useRackHitTargets({
  topZ,
  selectedFlightDomain,
}: {
  topZ: number;
  selectedFlightDomain: string;
}): HitTargetNodeST[] {
  const { systemId = '' } = useParams();
  const { data: facilityMap, isLoading: isLoadingMap } = useFacilityMap(systemId);

  if (isLoadingMap || !facilityMap) {
    return [];
  }

  const selectedFlightDomainNode = facilityMap.nodes?.find(
    (node) => node.name === selectedFlightDomain,
  );

  if (!selectedFlightDomainNode) {
    return [];
  }

  const aisleSides = findNodesByType(selectedFlightDomainNode, 'AISLE_SIDE');
  return aisleSides.map(({ position, scale, normal, ...side }) => ({
    ...side,
    position: vectorLike2Vector(position).setZ(topZ + HIT_TARGET_HEIGHT),
    scale: vectorLike2Vector(scale).setZ(0.1),
    normal: vectorLike2Vector(normal),
  }));
}

export const TopViewMap = ({
  handleSelected,
  selectedAisleId,
  selectedFlightDomain,
}: TopViewMapProps) => {
  const rackTopView = useRackTops(selectedFlightDomain);
  const hitTargets = useRackHitTargets({ topZ: rackTopView.maxHeightZ, selectedFlightDomain });
  const [hoveredId, setHovered] = useState<string>();

  const handleHover = useCallback((event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    const aisleId = event.object.parent?.userData.id;
    setHovered((currentHoveredId) => (currentHoveredId === aisleId ? undefined : aisleId));
  }, []);

  const handleClick = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation();
      if (!event.object.parent) {
        return;
      }
      const aisleId = traverseThreeObject(
        event.object.parent,
        'parent',
        (node) => !!node.userData.id,
      )?.userData.id;

      if (!aisleId) {
        return;
      }

      handleSelected(aisleId);
    },
    [handleSelected],
  );

  return rackTopView.isLoading ? null : (
    <>
      {hitTargets.map((aisle) => (
        <group
          key={aisle.position.toArray().join(',')}
          visible={selectedAisleId === aisle.id || aisle.id === hoveredId}
          userData={aisle}
          onPointerOver={handleHover}
          onPointerLeave={handleHover}
          onClick={handleClick}
        >
          <ActiveAisle
            dashed={aisle.id === hoveredId && selectedAisleId !== aisle.id}
            position={aisle.position}
            scale={aisle.scale}
            opacity={0.08}
          />
        </group>
      ))}
      <Bin3DContainer bins={rackTopView.rackTops} />
    </>
  );
};
