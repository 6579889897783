import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  icon: {
    display: 'inline-block',
    verticalAlign: 'center',
    fontSize: '1em',

    svg: {
      display: 'block',
      fontSize: 'inherit',
    },
  },
}));

export const Metric = ({
  tooltip,
  value,
  unit,
  unitLong,
}: {
  tooltip?: string;
  value: number | string;
  unit?: string;
  unitLong?: string;
}) => {
  const { classes } = useStyles();

  const t = tooltip || `${value} ${unitLong || unit || ''}`;

  return (
    <Tooltip title={t}>
      <div data-testid="metric-container">
        <span>
          {value}
          {unit ? ` ${unit}` : null}
        </span>{' '}
        <IconButton
          aria-label="Copy value to clipboard"
          className={classes.icon}
          onClick={() => {
            navigator.clipboard.writeText(t);
            toast('Copied to clipboard', { type: 'success' });
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
