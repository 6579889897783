/* tslint:disable */
/* eslint-disable */
/**
 * Location Information
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBarcodeDiagnosticST
 */
export interface IBarcodeDiagnosticST {
  /**
   *
   * @type {Array<string>}
   * @memberof IBarcodeDiagnosticST
   */
  activityGroupId: Array<string>;
  /**
   *
   * @type {{ [key: string]: IBarcodeInfoST; }}
   * @memberof IBarcodeDiagnosticST
   */
  activityToBarcodeInfo: { [key: string]: IBarcodeInfoST };
  /**
   *
   * @type {object}
   * @memberof IBarcodeDiagnosticST
   */
  slotInfoGroup: object;
  /**
   *
   * @type {boolean}
   * @memberof IBarcodeDiagnosticST
   */
  barcodeRecognitionWasSkipped: boolean;
}
/**
 *
 * @export
 * @interface IBarcodeInfoST
 */
export interface IBarcodeInfoST {
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  localizedBarcodes: Array<IBarcodeST>;
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  mismatchedBarcodes: Array<IBarcodeST>;
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  recognizedBarcodes: Array<IBarcodeST>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IBarcodeMatchLogicST = {
  IntersectionNotEmpty: 'INTERSECTION_NOT_EMPTY',
  ExactMatch: 'EXACT_MATCH',
  VeritySuperset: 'VERITY_SUPERSET',
  WmsSuperset: 'WMS_SUPERSET',
} as const;

export type IBarcodeMatchLogicST = typeof IBarcodeMatchLogicST[keyof typeof IBarcodeMatchLogicST];

/**
 *
 * @export
 * @interface IBarcodeST
 */
export interface IBarcodeST {
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  btype: string;
  /**
   *
   * @type {ILocationST}
   * @memberof IBarcodeST
   */
  location: ILocationST;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  udb_value: string;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  status: IBarcodeSTStatusEnum;
}

export const IBarcodeSTStatusEnum = {
  Recognized: 'BARCODE_RECOGNIZED',
  PatternMismatch: 'BARCODE_PATTERN_MISMATCH',
  Localized: 'BARCODE_LOCALIZED',
} as const;

export type IBarcodeSTStatusEnum = typeof IBarcodeSTStatusEnum[keyof typeof IBarcodeSTStatusEnum];

/**
 *
 * @export
 * @interface ICoordinateST
 */
export interface ICoordinateST {
  /**
   *
   * @type {Array<number>}
   * @memberof ICoordinateST
   */
  coordinate: Array<number>;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueIdUuidST
 */
export interface IInputDataActIdToActMetadataValueIdUuidST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueIdUuidST
   */
  value?: string;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  resolutionWidth?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  resolutionHeight?: string;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  fps?: number;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  autoExposure?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  autoWhiteBalanceTemperature?: boolean;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  whiteBalanceTemperature?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  gain?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  gamma?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  saturation?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  sharpness?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  contrast?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  brightness?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST
   */
  exposureTime?: string;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST {
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST
   */
  value?: number;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
   */
  pitch?: number;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
   */
  cameraSettings?: IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersCameraSettingsST;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
   */
  pictureDelaySec?: IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST
   */
  torchBrightness?: IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersPictureDelaySecST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST
   */
  fileName?: string;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST
   */
  parameters?: IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerParametersST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadPointCloudInfoST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadPointCloudInfoST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadPointCloudInfoST
   */
  fileName?: string;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValuePicturePayloadST
 */
export interface IInputDataActIdToActMetadataValuePicturePayloadST {
  /**
   *
   * @type {Array<string>}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadST
   */
  pictureFilenames?: Array<string>;
  /**
   *
   * @type {Array<IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST>}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadST
   */
  picturesInfo?: Array<IInputDataActIdToActMetadataValuePicturePayloadPicturesInfoInnerST>;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadST
   */
  pointCloudFilename?: string;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadPointCloudInfoST}
   * @memberof IInputDataActIdToActMetadataValuePicturePayloadST
   */
  pointCloudInfo?: IInputDataActIdToActMetadataValuePicturePayloadPointCloudInfoST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueST
 */
export interface IInputDataActIdToActMetadataValueST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  actionType?: string;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  boardSerialNumber?: string;
  /**
   *
   * @type {object}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  globalNodeFp?: object;
  /**
   *
   * @type {IInputDataActIdToActMetadataValueIdUuidST}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  idUuid?: IInputDataActIdToActMetadataValueIdUuidST;
  /**
   *
   * @type {IInputDataActIdToActMetadataValuePicturePayloadST}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  picturePayload?: IInputDataActIdToActMetadataValuePicturePayloadST;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  softwareVersion?: string;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  vehicleId?: number;
  /**
   *
   * @type {IInputDataActIdToActMetadataValueVehicleStateST}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  vehicleState?: IInputDataActIdToActMetadataValueVehicleStateST;
  /**
   *
   * @type {IInputDataActIdToActMetadataValueVehicleTargetPositionST}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  vehicleTargetPosition?: IInputDataActIdToActMetadataValueVehicleTargetPositionST;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueST
   */
  wallTimeNs?: string;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueVehicleStateST
 */
export interface IInputDataActIdToActMetadataValueVehicleStateST {
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  attitude?: IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  estimateReliable?: boolean;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  maxPositionStd?: number;
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  mcuTimestamp?: string;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  position?: IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST;
  /**
   *
   * @type {IInputDataActIdToActMetadataValueVehicleStateSomTimestampST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  somTimestamp?: IInputDataActIdToActMetadataValueVehicleStateSomTimestampST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateST
   */
  velocity?: IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampST
 */
export interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampST {
  /**
   *
   * @type {IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateSomTimestampST
   */
  timeSinceEpoch?: IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST
 */
export interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST {
  /**
   *
   * @type {string}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST
   */
  count?: string;
  /**
   *
   * @type {IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochTypeST}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochST
   */
  type?: IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochTypeST;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochTypeST
 */
export interface IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochTypeST {
  /**
   *
   * @type {object}
   * @memberof IInputDataActIdToActMetadataValueVehicleStateSomTimestampTimeSinceEpochTypeST
   */
  nanoseconds?: object;
}
/**
 *
 * @export
 * @interface IInputDataActIdToActMetadataValueVehicleTargetPositionST
 */
export interface IInputDataActIdToActMetadataValueVehicleTargetPositionST {
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueVehicleTargetPositionST
   */
  x?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueVehicleTargetPositionST
   */
  y?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueVehicleTargetPositionST
   */
  z?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataActIdToActMetadataValueVehicleTargetPositionST
   */
  yaw?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST
 */
export interface IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST {
  /**
   *
   * @type {boolean}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST
   */
  enableGrayscale?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST
   */
  enableSlotCropping?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST
   */
  enableSlotMasking?: boolean;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersBarcodeRecognitionParametersST
 */
export interface IInputDataInsightParametersBarcodeRecognitionParametersST {
  /**
   *
   * @type {string}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  barcodePattern?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  barcodeTypes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  croppingOverlap?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  croppingSubdivisionDepth?: number;
  /**
   *
   * @type {IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  framePreprocessors?: IInputDataInsightParametersBarcodeRecognitionParametersFramePreprocessorsST;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  frameProcessingStoppingBehavior?: number;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  ignoreRecognizedDuplicates?: boolean;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  maxNumOfMaskingIterations?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersBarcodeRecognitionParametersST
   */
  numOfSoughtBarcodes?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  expectedBeamHeightM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  expectedPalletHeightM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  minBinWidthM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  minPointsPerBinPerCmHeight?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  perceivedFloorThickness?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiBackOffset?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiBottomOffset?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiFrontOffset?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiMinPointsNum?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiOutliersPerc?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  roiTopOffsetM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST
   */
  zBinHeightM?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  blobMajorAxis3dMinThresholdM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  blobMajorAxisMaxThreshold?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  blobMajorAxisMinThreshold?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  cannyThreshold1?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  cannyThreshold2?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  dilationSize?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  edgeDetectionMethod?: number;
  /**
   *
   * @type {boolean}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  erosion1Size?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  erosion2Size?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST
   */
  sobelThreshold?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST
   */
  primaryStrategy?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST
   */
  primaryStrategyConfidenceMax?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST
   */
  primaryStrategyConfidenceMin?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST
   */
  secondaryStrategy?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST {
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST
   */
  combinedHierarchicalParameters?: IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyCombinedHierarchicalParametersST;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST
   */
  strategyInt?: number;
  /**
   *
   * @type {string}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST
   */
  strategyName?: string;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST
   */
  empty?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST
   */
  lowestActivityTolerance?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST
   */
  occupied?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersST {
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersST
   */
  beamEstimationParameters?: IInputDataInsightParametersEmptySlotDetectionParametersBeamEstimationParametersST;
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersST
   */
  blobFilterParameters?: IInputDataInsightParametersEmptySlotDetectionParametersBlobFilterParametersST;
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersST
   */
  esdStrategy?: IInputDataInsightParametersEmptySlotDetectionParametersEsdStrategyST;
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersST
   */
  esdThreshold?: IInputDataInsightParametersEmptySlotDetectionParametersEsdThresholdST;
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersST
   */
  slotInflation?: IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
 */
export interface IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
   */
  backM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
   */
  bottomM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
   */
  frontM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
   */
  sideM?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersEmptySlotDetectionParametersSlotInflationST
   */
  topM?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST
 */
export interface IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST
   */
  w?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST
   */
  x?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST
   */
  y?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST
   */
  z?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersBodyToCameraST
 */
export interface IInputDataInsightParametersMissionCameraParametersBodyToCameraST {
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraST
   */
  rotation?: IInputDataInsightParametersMissionCameraParametersBodyToCameraRotationST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraST
   */
  translation?: IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST
 */
export interface IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST
   */
  x?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST
   */
  y?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersBodyToCameraTranslationST
   */
  z?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST
 */
export interface IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST
   */
  horizontal?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST
   */
  vertical?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST
 */
export interface IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST {
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST
   */
  x?: number;
  /**
   *
   * @type {number}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST
   */
  y?: number;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersIntrinsicsST
 */
export interface IInputDataInsightParametersMissionCameraParametersIntrinsicsST {
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsST
   */
  cameraFovRadians?: IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsST
   */
  cameraResolutionPixel?: IInputDataInsightParametersMissionCameraParametersIntrinsicsCameraFovRadiansST;
  /**
   *
   * @type {Array<string>}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsST
   */
  distortionCoefficients?: Array<string>;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsST
   */
  focalLengthPixel?: IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST}
   * @memberof IInputDataInsightParametersMissionCameraParametersIntrinsicsST
   */
  principalPointPixel?: IInputDataInsightParametersMissionCameraParametersIntrinsicsFocalLengthPixelST;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersMissionCameraParametersST
 */
export interface IInputDataInsightParametersMissionCameraParametersST {
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraST}
   * @memberof IInputDataInsightParametersMissionCameraParametersST
   */
  bodyToCamera?: IInputDataInsightParametersMissionCameraParametersBodyToCameraST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersIntrinsicsST}
   * @memberof IInputDataInsightParametersMissionCameraParametersST
   */
  intrinsics?: IInputDataInsightParametersMissionCameraParametersIntrinsicsST;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersST
 */
export interface IInputDataInsightParametersST {
  /**
   *
   * @type {IInputDataInsightParametersBarcodeRecognitionParametersST}
   * @memberof IInputDataInsightParametersST
   */
  barcodeRecognitionParameters?: IInputDataInsightParametersBarcodeRecognitionParametersST;
  /**
   *
   * @type {IInputDataInsightParametersEmptySlotDetectionParametersST}
   * @memberof IInputDataInsightParametersST
   */
  emptySlotDetectionParameters?: IInputDataInsightParametersEmptySlotDetectionParametersST;
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersST}
   * @memberof IInputDataInsightParametersST
   */
  missionCameraParameters?: IInputDataInsightParametersMissionCameraParametersST;
  /**
   *
   * @type {IInputDataInsightParametersTofCameraParametersST}
   * @memberof IInputDataInsightParametersST
   */
  tofCameraParameters?: IInputDataInsightParametersTofCameraParametersST;
}
/**
 *
 * @export
 * @interface IInputDataInsightParametersTofCameraParametersST
 */
export interface IInputDataInsightParametersTofCameraParametersST {
  /**
   *
   * @type {IInputDataInsightParametersMissionCameraParametersBodyToCameraST}
   * @memberof IInputDataInsightParametersTofCameraParametersST
   */
  bodyToCamera?: IInputDataInsightParametersMissionCameraParametersBodyToCameraST;
  /**
   *
   * @type {string}
   * @memberof IInputDataInsightParametersTofCameraParametersST
   */
  cameraIdentifier?: string;
}
/**
 *
 * @export
 * @interface IInputDataST
 */
export interface IInputDataST {
  /**
   *
   * @type {IBarcodeDiagnosticST}
   * @memberof IInputDataST
   */
  act_group_barcode_diagnostic: IBarcodeDiagnosticST;
  /**
   *
   * @type {string}
   * @memberof IInputDataST
   */
  activity_group_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IInputDataST
   */
  activity_id_list: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof IInputDataST
   */
  act_id_to_udb_image_guid: { [key: string]: string };
  /**
   *
   * @type {IInputDataInsightParametersST}
   * @memberof IInputDataST
   */
  insight_parameters: IInputDataInsightParametersST;
  /**
   *
   * @type {{ [key: string]: IInputDataActIdToActMetadataValueST; }}
   * @memberof IInputDataST
   */
  act_id_to_act_metadata: { [key: string]: IInputDataActIdToActMetadataValueST };
}
/**
 *
 * @export
 * @enum {string}
 */

export const IIssueLogicST = {
  Default: 'DEFAULT',
  DoubleDepth21: 'DOUBLE_DEPTH_21',
  DoubleDepth22: 'DOUBLE_DEPTH_22',
  DoubleDepthMapBased: 'DOUBLE_DEPTH_MAP_BASED',
  NaBarcode: 'NA_BARCODE',
  PictureOnly: 'PICTURE_ONLY',
  C001CMissingBc: 'C001C_MISSING_BC',
} as const;

export type IIssueLogicST = typeof IIssueLogicST[keyof typeof IIssueLogicST];

/**
 * @type ILocationHistoryItemDataST
 * @export
 */
export type ILocationHistoryItemDataST = ISlotSettingsST | IVeritySlotStatusST | IWMSSlotStatusST;

/**
 *
 * @export
 * @interface ILocationHistoryItemST
 */
export interface ILocationHistoryItemST {
  /**
   *
   * @type {ILocationHistoryTypeST}
   * @memberof ILocationHistoryItemST
   */
  item_type?: ILocationHistoryTypeST;
  /**
   *
   * @type {ILocationHistoryItemDataST}
   * @memberof ILocationHistoryItemST
   */
  data: ILocationHistoryItemDataST;
}

/**
 *
 * @export
 * @interface ILocationHistoryResponseST
 */
export interface ILocationHistoryResponseST {
  /**
   *
   * @type {string}
   * @memberof ILocationHistoryResponseST
   */
  next_timepoint?: string | null;
  /**
   *
   * @type {Array<ILocationHistoryItemST>}
   * @memberof ILocationHistoryResponseST
   */
  data: Array<ILocationHistoryItemST>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ILocationHistoryTypeST = {
  WmsUpdate: 'WMS_UPDATE',
  DroneReport: 'DRONE_REPORT',
  ExclusionStatusUpdate: 'EXCLUSION_STATUS_UPDATE',
} as const;

export type ILocationHistoryTypeST =
  typeof ILocationHistoryTypeST[keyof typeof ILocationHistoryTypeST];

/**
 *
 * @export
 * @interface ILocationST
 */
export interface ILocationST {
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  bottomLeft: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  bottomRight: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  topLeft: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  topRight: ICoordinateST;
}
/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 *
 * @export
 * @interface IReportLookupResponseST
 */
export interface IReportLookupResponseST {
  /**
   *
   * @type {string}
   * @memberof IReportLookupResponseST
   */
  report_id: string;
}
/**
 *
 * @export
 * @interface IResultMetadataResponseST
 */
export interface IResultMetadataResponseST {
  /**
   *
   * @type {string}
   * @memberof IResultMetadataResponseST
   */
  ag_result_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IResultMetadataResponseST
   */
  activity_group_ids: Array<string>;
  /**
   *
   * @type {{ [key: string]: IInputDataST; }}
   * @memberof IResultMetadataResponseST
   */
  activity_group_id_to_input_data: { [key: string]: IInputDataST };
  /**
   *
   * @type {string}
   * @memberof IResultMetadataResponseST
   */
  location_name: string;
  /**
   *
   * @type {number}
   * @memberof IResultMetadataResponseST
   */
  system_id: number;
}
/**
 * Slot settings.
 * @export
 * @interface ISlotSettingsST
 */
export interface ISlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ISlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_status: ISlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ISlotSettingsST
   */
  updated_at: string | null;
}

export const ISlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ISlotSettingsSTExclusionStatusEnum =
  typeof ISlotSettingsSTExclusionStatusEnum[keyof typeof ISlotSettingsSTExclusionStatusEnum];

/**
 * @type IVeritySlotStatusBarcodeMatchLogicUsedST
 * @export
 */
export type IVeritySlotStatusBarcodeMatchLogicUsedST = IBarcodeMatchLogicST | INullableFieldST;

/**
 * @type IVeritySlotStatusIssueLogicUsedST
 * @export
 */
export type IVeritySlotStatusIssueLogicUsedST = IIssueLogicST | INullableFieldST;

/**
 *
 * @export
 * @interface IVeritySlotStatusST
 */
export interface IVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverrideST>}
   * @memberof IVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverrideST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverrideST
 */
export interface IVeritySlotStatusUserOverrideST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IWMSSlotStatusST
 */
export interface IWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  state: IWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_usage: IWMSSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IWMSSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  exclusion_status: IWMSSlotStatusSTExclusionStatusEnum;
}

export const IWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type IWMSSlotStatusSTStateEnum =
  typeof IWMSSlotStatusSTStateEnum[keyof typeof IWMSSlotStatusSTStateEnum];
export const IWMSSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type IWMSSlotStatusSTSlotUsageEnum =
  typeof IWMSSlotStatusSTSlotUsageEnum[keyof typeof IWMSSlotStatusSTSlotUsageEnum];
export const IWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type IWMSSlotStatusSTExclusionStatusEnum =
  typeof IWMSSlotStatusSTExclusionStatusEnum[keyof typeof IWMSSlotStatusSTExclusionStatusEnum];

/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationHistory: async (
      systemIdStr: string,
      location: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationHistory', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('getLocationHistory', 'location', location);
      const localVarPath = `/{system_id_str}/location/{location}/history`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (timepoint !== undefined) {
        localVarQueryParameter['timepoint'] =
          (timepoint as any) instanceof Date ? (timepoint as any).toISOString() : timepoint;
      }

      if (review !== undefined) {
        localVarQueryParameter['review'] = review;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationMetadata: async (
      systemIdStr: string,
      location: string,
      version?: number,
      review?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationMetadata', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('getLocationMetadata', 'location', location);
      const localVarPath = `/{system_id_str}/location/{location}/metadata`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (review !== undefined) {
        localVarQueryParameter['review'] = review;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFromLocation: async (
      systemIdStr: string,
      location: string,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportFromLocation', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('getReportFromLocation', 'location', location);
      const localVarPath = `/{system_id_str}/location/{location}/report-lookup`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsRawData: async (
      systemIdStr: string,
      location: string,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getWmsRawData', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('getWmsRawData', 'location', location);
      const localVarPath = `/{system_id_str}/location/{location}/wms-data`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationHistory(
      systemIdStr: string,
      location: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILocationHistoryResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationHistory(
        systemIdStr,
        location,
        limit,
        timepoint,
        review,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationMetadata(
      systemIdStr: string,
      location: string,
      version?: number,
      review?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResultMetadataResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationMetadata(
        systemIdStr,
        location,
        version,
        review,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFromLocation(
      systemIdStr: string,
      location: string,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportLookupResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFromLocation(
        systemIdStr,
        location,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWmsRawData(
      systemIdStr: string,
      location: string,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWmsRawData(
        systemIdStr,
        location,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LocationApiFp(configuration);
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationHistory(
      systemIdStr: string,
      location: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options?: any,
    ): AxiosPromise<ILocationHistoryResponseST> {
      return localVarFp
        .getLocationHistory(systemIdStr, location, limit, timepoint, review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationMetadata(
      systemIdStr: string,
      location: string,
      version?: number,
      review?: boolean,
      options?: any,
    ): AxiosPromise<IResultMetadataResponseST> {
      return localVarFp
        .getLocationMetadata(systemIdStr, location, version, review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFromLocation(
      systemIdStr: string,
      location: string,
      version?: number,
      options?: any,
    ): AxiosPromise<IReportLookupResponseST> {
      return localVarFp
        .getReportFromLocation(systemIdStr, location, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} location
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsRawData(
      systemIdStr: string,
      location: string,
      version?: number,
      options?: any,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .getWmsRawData(systemIdStr, location, version, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
  /**
   * Returns the history of slot statuses including verity and WMS data.
   * @summary Return slot history based on time constraints.
   * @param {string} systemIdStr
   * @param {string} location
   * @param {number} [limit]
   * @param {string} [timepoint]
   * @param {boolean} [review]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationHistory(
    systemIdStr: string,
    location: string,
    limit?: number,
    timepoint?: string,
    review?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getLocationHistory(systemIdStr, location, limit, timepoint, review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return slot metadata based on the result_id.
   * @summary Return slot metadata based on the result_id.
   * @param {string} systemIdStr
   * @param {string} location
   * @param {number} [version]
   * @param {boolean} [review]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationMetadata(
    systemIdStr: string,
    location: string,
    version?: number,
    review?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getLocationMetadata(systemIdStr, location, version, review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return report id that generated the result, none if not found
   * @summary Return report id that generated the result
   * @param {string} systemIdStr
   * @param {string} location
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getReportFromLocation(
    systemIdStr: string,
    location: string,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getReportFromLocation(systemIdStr, location, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return slot\'s raw wms data based on version, latest if none is specified
   * @summary Return slot\'s raw wms data
   * @param {string} systemIdStr
   * @param {string} location
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getWmsRawData(
    systemIdStr: string,
    location: string,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getWmsRawData(systemIdStr, location, version, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
