import { ILocationDataST, IWMSSlotStatusST } from 'codegen/report';
import { BarcodeData } from 'udb/inventory/models/BarcodeView.model';
import { getBarcodesFromWmsStatus } from './getBarcodesFromWmsStatus';

export const getExpectedAt = (
  location: ILocationDataST,
  barcodes: {
    [barcode: string]: BarcodeData;
  },
) => {
  if (!location.wms_status || typeof location.wms_status === 'string') {
    return barcodes;
  }

  const wmsStatus = location.wms_status as IWMSSlotStatusST;
  return getBarcodesFromWmsStatus(wmsStatus, barcodes);
};
