import { Grid } from '@mui/material';
import { Metric } from 'components/Diagnostics/Metric';
import { Coordinate } from './Coordinate';

export const PositionMetric = ({
  x,
  y,
  z,
  w,
  yaw,
  unit = 'm',
  unitLong = 'meters',
}: {
  x?: number;
  y?: number;
  z?: number;
  w?: number;
  yaw?: number;
  unit?: string;
  unitLong?: string;
}) => {
  const digits = 3;
  const posX = x ? <Metric value={x.toFixed(digits)} unit={unit} unitLong={unitLong} /> : null;
  const posY = y ? <Metric value={y.toFixed(digits)} unit={unit} unitLong={unitLong} /> : null;
  const posZ = z ? <Metric value={z.toFixed(digits)} unit={unit} unitLong={unitLong} /> : null;
  const posW = w ? <Metric value={w.toFixed(digits)} unit={unit} unitLong={unitLong} /> : null;
  const posYaw = yaw ? <Metric value={yaw.toFixed(digits)} unit="rad" unitLong="radians" /> : null;

  return (
    <Grid alignItems="stretch" container spacing={1.8} sx={{ paddingLeft: '5px' }}>
      <Coordinate name="w" value={w} posValue={posW} />
      <Coordinate name="x" value={x} posValue={posX} />
      <Coordinate name="y" value={y} posValue={posY} />
      <Coordinate name="z" value={z} posValue={posZ} />
      <Coordinate name="yaw" value={yaw} posValue={posYaw} />
    </Grid>
  );
};
