import { IResultMetadataResponseST } from 'codegen/location_information';

export const getActivityGroupIdForImage = ({
  resultMetadataResponse,
  imageGuid,
}: {
  resultMetadataResponse: IResultMetadataResponseST;
  imageGuid: string;
}) => {
  const activityGroupId = resultMetadataResponse.activity_group_ids.find(
    (agid) =>
      Object.keys(
        resultMetadataResponse.activity_group_id_to_input_data[agid].act_id_to_udb_image_guid,
      ).find(
        (key) =>
          resultMetadataResponse.activity_group_id_to_input_data[agid].act_id_to_udb_image_guid[
            key
          ] === imageGuid,
      ) !== undefined,
  );

  return activityGroupId;
};
